<script>
  import { mapActions, mapGetters } from 'vuex'
  import NavLinks from './NavLinks.vue';
  export default {
    name: 'menu-navbar',
    components: {
      NavLinks,
    },
    data () {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'is_logged_in',
        'get_user',
      ]),
      active_route() {
        return this.$router.currentRoute;
      },
      user() {
        return this.get_user;
      },
    },
    methods: {
      ...mapActions([
        'do_logout',
      ]),
    }
  }
</script>
<template>
  <nav class="navbar navbar-light bg-light navbar-expand-lg hide-lt-825">
    <div class="container-fluid" v-if="user">
      <div class="collapse navbar-collapse" id="menuNav">
        <nav-links />
      </div>
    </div>
  </nav>
</template>
