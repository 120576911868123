<style lang="scss">
  @import '../variables.scss';
  .navbar-nav {
    .nav-item {
      .nav-link {
        &.active {
          background-color: $cyan;
        }
      }
    }
  }
</style>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'nav-links',
    props: {
      css_class: {
        default: 'no-print',
      },
    },
    components: {

    },
    computed: {
      ...mapGetters([
        'get_user',
      ]),
      active_route() {
        return this.$route.name;
      },
      route_company_id() {
        return this.$route.params.company_id;
      },
      route_promotion_id() {
        return this.$route.params.promotion_id;
      },
      user() {
        return this.get_user;
      },
      is_admin() {
        return (this.user && this.user.is_admin);
      },
    },
    mounted() {
    },
  }
</script>
<template>

  <ul class="navbar-nav" :class="css_class">
    <li class="nav-item" v-if="is_admin">
      <router-link class="nav-link text-nowrap" :class="{active: active_route == 'admin.dashboard' }" :to="{ name: 'admin.dashboard' }">{{ $t('dashboard.admin_dashboard') }} <i class="fas fa-cogs"></i></router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link text-nowrap" :class="{active: active_route == 'exhibitor.dashboard' }" :to="{ name: 'exhibitor.dashboard', params: { company_id: route_company_id, promotion_id: route_promotion_id } }">{{ $t('exhibitor.dashboard') }} <i class="fas fa-chart-line"></i></router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link text-nowrap" :class="{active: active_route == 'sales.selection' || active_route == 'sales.confirmation' }" :to="{ name: 'sales.selection' }">{{ $t('sales.reservation') }} <i class="fas fa-thumbtack"></i></router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link text-nowrap" :class="{active: active_route == 'sales.management' || active_route == 'sales.reservation' }" :to="{ name: 'sales.management' }">{{ $t('sales.management') }} <i class="fas fa-receipt"></i></router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link text-nowrap" :class="{active: active_route == 'inventory.management' }" :to="{ name: 'inventory.management' }">{{ $t('inventory.management') }} <i class="fas fa-boxes"></i></router-link>
    </li>
  </ul>

</template>
