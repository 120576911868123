<style lang="scss">
  .cej-input-image {
    .preview-container {
      border-radius: 4px;
      background-color: rgba(200, 200, 200, 0.25);
      .picture-inner-text {
        font-size: 1em;
        line-height: 1.2em;
      }
    }
    .is-valid .preview-container {
      background: unset;
    }
    .preview-container {
      .picture-inner {
        border: 2px dashed #ced4da;
        padding: 0;
        border-radius: 4px;
        margin-top: 0px;
        margin-right: auto;
        margin-bottom: -0.5em;
        margin-left: auto;
        width: 95%;
        height: 95%;
        font-size: 12px !important;
      }
    }
    .is-invalid .preview-container {
      border: 1px solid #ced4da;
      background-color: rgba(200, 200, 200, 0.25);
      border-color: #e3342f;
      padding-right: calc(1.6em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e3342f'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.4em + 0.1875rem) center;
      background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
      .picture-preview {
        background: unset !important;
      }
    }
  }
</style>
<script>
import PictureInput from 'vue-picture-input';
import { ValidationProvider } from 'vee-validate';
import BsInputFeedback from './BsInputFeedback.vue';
import i18n from '../i18n.js';
export default {
  components: {
    ValidationProvider,
    BsInputFeedback,
    PictureInput,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    colClass: {
      type: String,
      default: 'mb-3',
    },
    groupClass: {
      type: String,
      default: '',
    },
    // must be included in props
    value: {
      type: null
    },
    label: {
      default: ''
    },
    rules: {
      type: [Object, String],
      default: null
    },
    // Picture Input Settings
    customStrings: {
      default: null,
    },
    width: {
      default: 500,
    },
    height: {
      height: 500,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    crop: {
      type: Boolean,
      default: false,
    },
    size: {
      default: 5, // Max File Size in MB
    },
    accept: {
      type: String,
      default: 'image/jpeg,image/png',
    },
    prefill: {
      type: String,
      default: null,
    },
    max_size_text_key: {
      type: String,
      default: 'image_upload.max_size',
    },
  },
  computed: {
    computed_custom_strings() {
      let ret = this.customStrings;
      if (!ret) {
        ret = {
          upload: i18n.t('image_upload.upload'),
          drag: i18n.t('image_upload.drag'),
          tap: i18n.t('image_upload.tap'),
          change: i18n.t('image_upload.change'),
          remove: i18n.t('image_upload.remove'),
          select: i18n.t('image_upload.select'),
          selected: i18n.t('image_upload.selected'),
          fileSize: i18n.t('image_upload.fileSize'),
          fileType: i18n.t('image_upload.fileType'),
          aspect: i18n.t('image_upload.aspect'),
          max_size: i18n.t(this.max_size_text_key),
        };
      }
      return ret;
    },
    image_type() {
      let regex = new RegExp(/data:image\/(.*);base64,/);
      let matches = this.innerValue.match(regex);
      let type = null;
      if (matches) type = matches[1];
      return type;
    },
  },
  data: () => ({
    innerValue: "",
    error: null,
  }),
  methods: {
    image_changed(image) {
      if (image) {
        this.innerValue = image;
        this.error = null;
        this.$emit('changed', {image: image, type: this.image_type});
      }
    },
    image_removed() {
      this.innerValue = null;
      this.$emit('removed', true);
    },
    image_error(error) {
      console.error('Image Error', error);
      this.error = error.message;
    },
    onResize() {
      this.$refs.picture_input.onResize();
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
<template>
  <ValidationProvider ref="vp" :vid="id" :name="label" :rules="rules" v-slot="{ errors, valid }" :slim="true">
    <div class="cej-input-image" :class="colClass">
      <label :for="id" class="form-label" v-if="label">{{label}}</label>
      <div class="input-group" :class="groupClass">
        <input type="hidden" :value="innerValue" />
        <picture-input
          :class="{ 'is-valid': value !== '' && valid && !errors[0] && !error, 'is-invalid': errors[0] || error }"
          :id="id"
          ref="picture_input"
          :width="width"
          :height="height"
          margin="10"
          radius="4px"
          :removable="removable"
          :crop="crop"
          :accept="accept"
          :size="size"
          button-class="btn btn-secondary hidden d-none"
          remove-button-class="btn btn-danger"
          :prefill="prefill"
          @change="image_changed"
          @remove="image_removed"
          @error="image_error"
          :customStrings="computed_custom_strings"
          :alertOnError="false"
        />
        <div class="form-text">{{ computed_custom_strings.max_size }}</div>
        <bs-input-feedback :valid="false" v-if="error">{{ error }}</bs-input-feedback>
        <bs-input-feedback :valid="valid" v-if="errors[0]">{{ errors[0] }}</bs-input-feedback>
      </div>
    </div>
  </ValidationProvider>
</template>