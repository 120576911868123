<style lang="scss">

</style>
<script>
  // import i18n from '../i18n.js'
  export default {
    name: 'confirm-modal',
    components: {
    },
    props: {
      title: {
        type: String,
        default: "confirmation"
      },
      message: {
        type: String,
        default: "Are you sure about that?",
      },
      save_text: {
        default: 'confirm',
      },
      close_text: {
        default: 'cancel',
      },
      save_class: {
        default: 'btn-success'
      },
    },
    data() {
      return {
        modal: null,
      }
    },
    computed: {

    },
    methods: {
      open() {
        let $this = this;
        this.$nextTick(() => {
          $this.$refs.bs_modal.open();
        });
      },
      close() {
        this.$refs.bs_modal.close();
      },
      confirmed() {
        this.$emit('confirmed', true);
        this.$refs.bs_modal.close();
      },
      rejected() {
        this.$emit('rejected', true);
      },
    },
    watch: {

    },
    mounted() {
      console.log('ConfirmModal Mounted');
    },
  }
</script>
<template>
  <div>
    <bs-modal :title="$t(title)" ref="bs_modal" @save="confirmed" @secondary="rejected" v-bind="$props" v-on="$listeners" :is_static="true">
      {{ $t(message) }}
    </bs-modal>
  </div>
</template>
