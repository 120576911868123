<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'auth',
    title: 'usr.login',
    data(){
      return {
        loading: false,
        forgot_mode: false,
        reset_email_sent: false,
        error_message: null,
        form: {
          email: "",
          password: "",
          remember: true,
        },
        buttons_disabled: false,
        form_loading: false,
      }
    },
    computed: {
      ...mapGetters([
        'is_logged_in',
        'get_auth_status',
        'get_language',
      ]),
      route_action() {
        return this.$route.params.action;
      },
      auth_status() {
        return this.get_auth_status;
      },
      is_invalid() {
        return (this.auth_status == 'server_error' || this.auth_status == 'usr.invalid_credentials' ? true : false);
      },
    },
    methods: {
      ...mapActions([
        'do_login',
        'do_send_reset_password_email',
      ]),
      ...mapMutations([
        'clear_auth_error',
      ]),
      login() {
        console.log('login');
        let request = {
          email: this.form.email,
          password: this.form.password,
          remember: this.form.remember,
        }
        this.do_login(request)
          .then((resp) => {
            if (resp.success) {
              let route = 'exhibitor.dashboard';
              if (resp.user && resp.user.role == 'admin') route = 'admin.dashboard';
              this.$router.push({ name: route });
            }
          }).catch((err) => {
            this.error_message = err.message;
          }).finally(() => {
            if (this.$refs.login_form) {
              this.$refs.login_form.disable_buttons(false);
              this.$refs.login_form.set_form_loading(false);
            }
          });
      },
      send_reset_email() {
        let request = {
          email: this.form.email,
          language: this.get_language,
        }
        this.$refs.login_form.observer.validate()
        .then((valid) => {
          if (!valid) return;
          this.reset_email_sent = false;
          this.$refs.login_form.disable_buttons(true);
          this.do_send_reset_password_email(request)
            .then((resp) => {
              if (resp.success) {
                this.reset_email_sent = true;
              }
            }).catch((err) => {
              this.error_message = err.message;
            }).finally(() => {
              this.$refs.login_form.disable_buttons(false);
            });
        });
      },
      toggle_forgot() {
        if (this.forgot_mode) {
          this.forgot_mode = false;
          this.reset_email_sent = false;
        } else {
          this.forgot_mode = true;
          this.clear_auth_error();
        }
      },
      disable_buttons(disable = true) {
        this.$set(this, 'buttons_disabled', disable);
      },
      set_form_loading(form_loading = true) {
        this.$set(this, 'form_loading', form_loading);
      },
    },
    mounted() {
      console.log('Auth mounted.');
      if (this.is_logged_in) {
        console.log('Already Logged In...');
        this.$router.push({ name: 'exhibitor.dashboard' });
      }
      if (this.route_action == 'reset') {
        this.forgot_mode = true;
      }
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 auth-container mt-sm-5 mb-sm-5">
    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-8 col-xl-6 col-xxl-5">

        <div class="card">
          <div class="card-header">
            <h1>{{ $t('usr.login') }}</h1>
          </div>
          <div class="card-body">
            <div>

              <bs-form ref="login_form" formClass="login" :submit_button="false" :reset_button="false" @submit="login" @buttons_disabled="disable_buttons" @form_loading="set_form_loading">
                <div class="row row-cols">
                  <bs-input-text col-class="col-12 col-sm-6 mb-3" group-class="" id="login_email" type="email" :placeholder="$t('usr.email')" v-model="form.email" rules="required" autocomplete="username">
                    <template v-slot:prepend>
                      <i class="fa fa-at"></i>
                    </template>
                  </bs-input-text>
                  <bs-input-text col-class="col-12 col-sm-6 mb-3" group-class="" id="login_password" type="password" :placeholder="$t('usr.password')" v-model="form.password" rules="required" autocomplete="current-password" v-if="!forgot_mode">
                    <template v-slot:prepend>
                      <i class="fa fa-key"></i>
                    </template>
                  </bs-input-text>
                </div>
                <div class="row row-cols align-items-center" v-if="!forgot_mode">
                  <bs-input-radio-checkboxes col-class="col-12 col-sm-6 mb-3" id="auth_login_remember" type="checkbox" v-model="form.remember" :inline="true" :options="[{ label: $t('usr.remember_me') }]" />
                </div>
                <div class="row row-cols align-items-center">
                  <div class="col-12 mb-3" v-show="is_invalid">
                    <div class="invalid-feedback d-block">
                      {{ $t( error_message ) }}
                    </div>
                  </div>
                  <div class="col-12 mb-3" v-show="reset_email_sent">
                    <div class="valid-feedback d-block">
                      {{ $t('usr.reset_password_email_sent') }}
                    </div>
                  </div>
                </div>

                <template v-slot:buttons>
                  <button class="btn btn-primary" :disabled="buttons_disabled" type="submit" v-if="!forgot_mode">{{ $t('usr.login') }}<i class="fa fa-spinner fa-spin ms-2" v-if="form_loading"></i></button>
                  <a href="javascript://" class="ms-4" @click="toggle_forgot" v-if="!forgot_mode">{{ $t('usr.reset_password') }}</a>
                  <button class="btn btn-primary" :disabled="buttons_disabled" type="button" @click="send_reset_email" v-if="forgot_mode">{{ $t('usr.request_password_reset') }}<i class="fa fa-spinner fa-spin ms-2" v-if="form_loading"></i></button>
                  <a href="javascript://" class="ms-4" @click="toggle_forgot" v-if="forgot_mode">{{ $t('usr.login') }}</a>
                </template>
              </bs-form>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>