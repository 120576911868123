<style lang="scss">

</style>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'company-section',
    computed: {
      ...mapGetters([
        'get_company',
      ]),
      active_route() {
        return this.$route.name;
      },
      company() {
        return this.get_company;
      },
    },
    mounted() {
      console.log('CompanySection mounted.');
    }
  }

</script>
<template>
  <div class="section-wrapper">
    <router-view></router-view>
  </div>
</template>
