<script>

import { ValidationProvider } from 'vee-validate';
import BsInputFeedback from './BsInputFeedback.vue';



export default {
  components: {
    ValidationProvider,
    BsInputFeedback,
  },
  props: {
    id: {
      type: String,
    },
    colClass: {
      type: String,
      default: 'mb-3',
    },
    // must be included in props
    value: {
      type: null
    },
    label: {
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    inline: {
      type: Boolean,
      default: false
    },
  },
  computed: {

  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
<template>
  <ValidationProvider ref="vp" :vid="id" :name="label" :rules="rules" v-slot="{ errors, valid }" :slim="true">
    <div :class="colClass">
      <label :for="id" class="form-label" v-if="label">{{label}}</label>
      <div class="">
        <v-date-picker
          :id="id"
          v-model="innerValue"
          :error-messages="errors"
          :success="valid"
          v-bind="$attrs"
          v-on="$listeners"
          :state="errors[0] ? false : (valid ? true : null)"
          :class="{ 'form-control': inline, 'is-valid': value !== '' && valid && !errors[0], 'is-invalid': errors[0] }"
        >
          <template v-slot="{ inputValue, inputEvents }" v-if="!inline">
            <div class="input-group mb-3">
              <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
              <input
                name="history-date-picker"
                class="form-control"
                :class="{ 'is-valid': value !== '' && valid && !errors[0], 'is-invalid': errors[0] }"
                :value="inputValue"
                v-on="inputEvents"
              />
            </div>
          </template>
        </v-date-picker>
        <bs-input-feedback :valid="valid" v-if="errors[0]">{{ errors[0] }}</bs-input-feedback>
      </div>
    </div>
  </ValidationProvider>
</template>