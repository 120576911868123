<style lang="scss">
  .sales-history-list-container {
    #sales-history-list-table {
      .el-table {
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import i18n from '../i18n.js';
  import { bus } from '../main';
  export default {
    name: 'sales-history-list',
    components: {
      'tbl-cell-status': {
        props: ['row'],
        template: `
          <span>{{ $t('sales.' + row.sales_status)}}</span>
        `,
      },
      'tbl-cell-actions': {
        props: ['row'],
        template: `
          <div class="">
            <router-link type="button" class="btn btn-outline-primary" :to="{ name: 'sales.management', params: { reservation_num: row.reservation_number } }" v-tooltip:bottom="$t('smgmt.view_reservation')">
              <i class="fa fa-receipt"></i>
            </router-link>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        saving: false,
        table_filter_val: '',
        request_timer: null,
        total_records: 0,
        request_params: {},
        sales_history: [],
        pagination_props: {
          background: false,
          pageSizes: [10, 25, 50, 100],
        },
        current_page: 1,
      }
    },
    computed: {
      ...mapGetters([
        'get_promotion',
      ]),
      current_promotion() {
        return this.get_promotion;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'purchased_at',
              order: 'descending'
            },
          },
          fields: [
            // Res #, Time, Status, Action (View, Cancel)
            { label: i18n.t('sales.reservation_number'), prop: 'reservation_number', sortable: true, searchable: true, min_width: '150px' },
            { label: i18n.t('smgmt.purchase_time'), prop: 'purchased_at', sortable: true, searchable: true, min_width: '150px', formatter: this.$options.filters.formatDatemin },
            { label: i18n.t('smgmt.status'), prop: 'sales_status', sortable: false, searchable: false, component: 'tbl-cell-status' },
            { label: i18n.t('actions'), sortable: false, searchable: false, component: 'tbl-cell-actions', align: 'center' },
          ],
          filters: [
            {
              prop: ['reservation_number'],
              value: this.table_filter_val,
            }
          ],
        }
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_sales_history',
      ]),
      fetch_sales_history() {
        this.loading = true;
        this.clear_request_timer();
        let params = this.request_params;
        this.do_fetch_sales_history(params).then(resp => {
          if (resp.sales_list) {
            this.$set(this, 'sales_history', resp.sales_list);
            this.$set(this, 'total_records', resp.total_records);
          } else {
            this.$toast.error(this.$t('server_error'));
          }
        }).finally(() => {
          this.loading = false;
        }).catch(err => {
          console.error('Error', err);
          this.$toast.error(this.$t('server_error'));
        });
      },
      handleQueryChange(tparams) {
        this.loading = true;
        let filter_val = tparams.filters.map(f => f.value)[0];
        let params = {
          query: {
            page: tparams.page,
            limit: tparams.pageSize,
            filter: filter_val,
            sort: tparams.sort,
          }
        };
        let duration = 2500;
        if (tparams.type == 'filter') {
          params.query.page = 1;
          this.current_page = 1;
        } else {
          duration = 500;
        }
        this.request_params = params;
        this.set_request_timer(duration);
      },
      clear_request_timer() {
        if (this.request_timer) {
          clearTimeout(this.request_timer);
          this.request_timer = null;
        }
      },
      set_request_timer(duration = 2500) {
        let $this = this;
        this.clear_request_timer();
        this.request_timer = setTimeout(function(){ $this.fetch_sales_history() }, duration);
      },
    },
    mounted() {
      console.log('SalesHistoryList mounted.');
      bus.$on('sale_purchased', () => {
        this.fetch_sales_history();
      });
    },
    beforeDestroy() {
      console.log('SalesHistoryList Unmounted');
      this.clear_request_timer();
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 sales-history-list-container" :class="{loading: loading}">

    <h3>{{ $t('smgmt.history') }}</h3>

    <div class="row mt-3 mb-3" v-if="sales_history">

      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables-server
        ref="sales-history-list-table"
        id="sales-history-list-table"
        :table-props="table_config.options"
        :columns="table_config.fields"
        :data="sales_history"
        :page-size="10"
        :current-page.sync="current_page"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
        :total="total_records"
        :load-data="fetch_sales_history"
        :loading="loading"
        @query-change="handleQueryChange"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
          :formatter="field.formatter"
          :className="field.className"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else-if="field.formatter && scope.row[field.prop]" :class="field.spanClass">{{ field.formatter(scope.row[field.prop]) }}</span>
            <span v-else :class="field.spanClass">{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </data-tables-server>

    </div>


  </div>
</template>
