<style lang="scss">

</style>
<script>
  import CompanyEditForm from '../components/CompanyEditForm.vue'
  import i18n from '../i18n.js'
  export default {
    name: 'company-edit-modal',
    components: {
      CompanyEditForm,
    },
    props: {
      company: null,
      company_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        modal: null,
        component_key: 0,
        error_message: '',
      }
    },
    computed: {
      company_title() {
        return (this.company && this.company.id ? i18n.t('company.data') + ' ' + this.company.name : i18n.t('company.add_new'));
      },
    },
    methods: {
      open() {
        let $this = this;
        this.$nextTick(() => {
          $this.$refs.bs_modal.open();
        });
      },
      close() {
        this.$refs.bs_modal.close();
      },
      save() {
        this.$refs.company_form.trigger_save();
      },
      saved(company) {
        this.$emit('saved', company);
      },
      failed(err) {
        this.$emit('failed', err);
      },
      disable_buttons(disable = true) {
        this.$refs.bs_modal.disable_buttons(disable);
      },
      set_form_loading(form_loading = true) {
        this.$refs.bs_modal.set_form_loading(form_loading);
      },
      form_is_loading(form_loading = true) {
        this.$refs.company_form.form_is_loading(form_loading);
      },
    },
    watch: {

    },
    mounted() {
      console.log('CompanyEditModal Mounted');
    },
  }
</script>
<template>
  <div>
    <bs-modal :title="company_title" ref="bs_modal" @save="save" @form_loading="form_is_loading">
      <company-edit-form ref="company_form" :company="company" @saved="saved" @failed="failed" :buttons="false" :company_list="company_list" v-if="company" @buttons_disabled="disable_buttons" @form_loading="set_form_loading" />
    </bs-modal>
  </div>
</template>
