<style lang="scss">

</style>
<script>
  import qrcode from 'qrcode-generator';
  export default {
    name: 'qr-code',
    components: {

    },
    props: {
      width: {
        default: 100,
      },
      height: {
        default: 100,
      },
      qrData: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
      }
    },
    computed: {
      img_url() {
        if (!this.qrData) return '';
        var typeNumber = 6;
        var errorCorrectionLevel = 'M';
        var qr = qrcode(typeNumber, errorCorrectionLevel);
        console.log('QR DATA', this.qrData);
        qr.addData(this.qrData);
        qr.make();
        return qr.createDataURL(4);
      },
    },
    methods: {
    },
    mounted() {
      console.log('QrCode mounted.');
    },
    beforeDestroy() {
      console.log('QrCode Unmounted');
    },
    watch: {
      qrData() {
        console.log('qR Data Changed');
      },
    },
  }
</script>
<template>
  <div class="qr-code-container">
    <img class="qr-code" :src="img_url" v-if="img_url" :width="width" :height="height" />
  </div>
</template>
