<style lang="scss">
  .company-list-container {
    #company-list-table {
      .el-table {
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import i18n from '../i18n.js'
  import CompanyEditModal from '../components/CompanyEditModal.vue'
  export default {
    name: 'company-list',
    components: {
      CompanyEditModal,
      'company-list-toolbar': {
        name: 'company-list-toolbar',
        template: `
          <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" @click="$parent.add_company()">{{$t('company.add')}} <i class="fas green fa-plus"></i></button>
              </div>
            </div>
          </div>
        `,
      },
      'tbl-cell-actions': {
        props: ['row'],
        template: `
          <div class="">
            <button type="button" class="btn btn-outline-primary" @click="$parent.$parent.$parent.$parent.edit_company(row)" v-tooltip:bottom="$t('company.edit')">
              <i class="fa fa-pencil-alt"></i>
            </button>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        default_company: {
          id: null,
          name: '',
          industry: '',
          logo_url: '',
          logo: '',
          logo_type: '',
          street_address: '',
          representative: '',
          phone_number: '',
          mail_address: '',
          description: '',
        },
        editing_company: null,
        table_filter_val: '',
      }
    },
    computed: {
      ...mapGetters([
        'get_company',
        'get_company_list',
      ]),
      current_company() {
        return this.get_company;
      },
      company_list() {
        return this.get_company_list;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'name',
              order: 'ascending'
            },
          },
          fields: [
            { label: i18n.t('company.name'), prop: 'name', sortable: true, searchable: true, min_width: '150px' },
            { label: i18n.t('company.industry'), prop: 'industry', sortable: true, searchable: true },
            { label: i18n.t('company.street_address'), prop: 'street_address', sortable: true, searchable: true },
            { label: i18n.t('company.representative'), prop: 'representative', sortable: true, searchable: true },
            { label: i18n.t('company.phone_number'), prop: 'phone_number', sortable: true, searchable: true },
            { label: i18n.t('company.email_address'), prop: 'mail_address', sortable: true, searchable: true },
            { label: i18n.t('actions'), sortable: false, searchable: false, component: 'tbl-cell-actions', align: 'center' },
          ],
          filters: [
            {
              prop: ['name', 'industry', 'street_address', 'representative', 'phone_number','mail_address'],
              value: this.table_filter_val,
            }
          ],
        }
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_companies',
      ]),
      fetch_companies() {
        this.do_fetch_companies();
      },
      edit_company(company) {
        let def = JSON.parse(JSON.stringify(this.default_company));
        if (!this.editing_company || this.editing_company.id != company.id) {
          this.$set(this, 'editing_company', Object.assign(def, company));
        }
        this.$refs.company_edit_modal.open();
      },
      add_company() {
        this.$set(this, 'editing_company', JSON.parse(JSON.stringify(this.default_company)));
        this.$refs.company_edit_modal.open();
      },
      saved(company) {
        console.log('Saved', company);
        this.$refs.company_edit_modal.close();
        this.$toast.success(this.$t('company.data_saved'));
        this.$set(this, 'editing_company', null);
      },
      failed(err) {
        console.error('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
    },
    mounted() {
      console.log('CompanyList mounted.');
      if (!this.company_list) {
        this.fetch_companies();
      }
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 company-list-container" :class="{loading: loading}">

    <h3>{{ $t('company.list') }}</h3>
    <company-list-toolbar />

    <div class="row mt-3 mb-3" v-if="company_list">

      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="company-list-table"
        id="company-list-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="company_list"
        :page-size="10"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else>{{scope.row[field.prop]}}</span>
          </template>
        </el-table-column>
      </data-tables>

    </div>

    <company-list-toolbar />

    <company-edit-modal @saved="saved" @failed="failed" :company="editing_company" :company_list="true" ref="company_edit_modal" />

  </div>
</template>
