<style lang="scss">
  @import '../variables';
  .status-icon {
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
    &.yellow {
      color: $yellow;
    }
    &.orange {
      color: $orange;
    }
    &.orange-light {
      color: $orange-light;
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import BsInputImage from '../cejGeneric/BsInputImage.vue';
  import QrCode from '../cejGeneric/QrCode.vue';
  export default {
    name: 'sandbox-page',
    title: 'sandbox',
    components: {
      'sandbox-toolbar': {
        template: `
          <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
              <div class="btn-group" role="group">
                <download-csv
                  class="btn btn-outline-primary"
                  :fields="['first_name','last_name','email','enabled','role']"
                  :data="$parent.user_list"
                  name="user_list.csv">
                  Download Users CSV <i class="fa fa-file-csv"></i>
                </download-csv>
                <button type="button" class="btn btn-outline-primary" @click="$parent.add_foo()">Add Foo <i class="fas green fa-plus"></i></button>
                <button type="button" class="btn btn-outline-success" @click="$parent.$refs.confirm_modal.open()">Confirm <i class="fas green fa-check"></i></button>
              </div>
            </div>
          </div>
        `,
      },
      BsInputImage,
      QrCode,
    },
    data() {
      return {
        loading: false,
        form: {
          // Generic
          textbox_req_input: '',
          textbox_def_input: '',
          textbox_num_input: '',
          textbox_email_input: '',
          checkbox_single_input: null,
          checkbox_single_default_input: true,
          checkbox_multiple_input: ['foo'],
          checkbox_inline_input: null,
          radio_multiple_input: null,
          switch_single_input: null,
          radio_slots_input: null,
          input_image: '',
          vselect_select_single: null,
          vselect_select_multi: null,
          vdatepicker_datetime_req: null,
          vdatepicker_datetime_inline_req: null,
        },
        cal_attributes: [
          {
            bar: {
              style: {
                backgroundColor: 'brown',
              },
            },
            dates: [
              new Date('2021-03-12'),
              new Date('2021-03-26'),
              new Date('2021-03-15'),
            ],
          },
          {
            key: 'today',
            highlight: {
              color: 'red',
              fillMode: 'light',
            },
            dates: new Date(),
          },
        ],
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_user_list',
      ]),
      current_user() {
        return this.get_user;
      },
      user_list() {
        return this.get_user_list;
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_users',
      ]),
      fetch_users() {
        this.do_fetch_users();
      },
      add_foo() {
        console.log('Add Foo Clicked');
      },
      saved(foo) {
        console.log('sandbox saved', foo);
        this.$refs.sandbox_saved_toast.show();
      },
      failed(err) {
        console.log('Error', err);
        this.$refs.sandbox_error_toast.show();
      },
      image_uploaded(image) {
        console.log('Base64 Image', image);
      },
      change_select(foo) {
        console.log('Select Changed', foo);
      },
      returnId(option) {
        return option.id;
      },
      confirmed() {
        console.log('Confirmed');
      },
      rejected() {
        console.log('Rejected');
      },
    },
    mounted() {
      console.log('SandboxPage mounted.');
      this.fetch_users();
    },
  watch: {

  },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 sandbox-container" :class="{loading: loading}">

    <h3>Sandbox</h3>
    <sandbox-toolbar />

    <div class="row mt-3 mb-3">
      <bs-form submit_button="Submit Foo" reset_button="Reset Bar">
        <fieldset>
          <legend>Generic Form Elements</legend>

          <!-- Generic Elements -->
          <bs-input-text id="sandbox_text_req" label="Textbox Required Input" placeholder="Foo Here"
            v-model="form.textbox_req_input" rules="required|min:3" />
          <bs-input-text id="sandbox_default_input" label="Textbox Default Input" placeholder="Bar Here"
            v-model="form.textbox_def_input" rules="" />
          <bs-input-text id="sandbox_num_req" type="number" label="Textbox Number Input" placeholder="How Many"
            v-model="form.textbox_num_input" rules="required|positive" />
          <bs-input-text id="sandbox_email_input" type="email" label="Textbox E-Mail Input" placeholder="E-Mail Here"
            v-model="form.textbox_email_input" rules="required|email">
            <template v-slot:prepend>
              <i class="fa fa-at"></i>
            </template>
            <template v-slot:append>
              <i class="fa fa-envelope"></i>
            </template>
          </bs-input-text>

          <bs-input-radio-checkboxes id="sandbox_checkbox_single" type="checkbox" label="Checkbox Single Input"
            v-model="form.checkbox_single_input" rules="radio_required" :options="[{value: 'foo', label: 'Single Option'}]" />

          <bs-input-radio-checkboxes id="sandbox_checkbox_single_default" type="checkbox" label="Checkbox Single Input Default"
            v-model="form.checkbox_single_default_input" :options="[{label: 'Single Option Default'}]" />

          <bs-input-radio-checkboxes id="sandbox_checkbox_multiple" type="checkbox" label="Checkbox Multiple Input"
            v-model="form.checkbox_multiple_input" rules="radio_required|exact_length:2,Select Two Options" :options="[{value: 'foo', label: 'Option 1'}, {value: 'bar', label: 'Option 2'}, {value: 'foobar', label: 'Option 3'}]"/>

          <bs-input-radio-checkboxes id="sandbox_checkbox_inline" type="checkbox" label="Checkbox Inline"
            v-model="form.checkbox_inline_input" rules="radio_required" :options="[{value: 1, label: 'Single Option'}]" :inline="true" />

          <bs-input-radio-checkboxes id="sandbox_radio_multiple" type="radio" label="Radio Multiple Inline Input"
            v-model="form.radio_multiple_input" rules="radio_required" :options="[{value: 1, label: 'Option 1'}, {value: 2, label: 'Option 2'}, {value: 3, label: 'Option 3'}]" :inline="true" />

          <bs-input-radio-checkboxes id="sandbox_switch_single" type="toggle" label="Switch Single Input"
            v-model="form.switch_single_input" rules="radio_required" :options="[{value: 1, label: 'Single Switch'}]"/>

          <bs-input-radio-checkboxes id="sandbox_radio_slots" type="radio" label="Radio Multiple Slots"
            v-model="form.radio_slots_input" rules="radio_required" :options="[{value: 1}, {value: 2}]">
            <template v-slot:label_1>
              <span><i class="fa fa-rocket"></i> Option 1 with Icon</span>
            </template>
            <template v-slot:label_2>
              <span>Option 2 with <a href="javascript://">Link</a></span>
            </template>
          </bs-input-radio-checkboxes>

          <bs-v-select id="sandbox_select_single_req" label="V-Select Required Single Dropdown" placeholder="Select an Option"
            v-model="form.vselect_select_single" @change="change_select" :options="[{id: 1, custom_name: 'Option 1', sub: 'Subtext 1'}, {id: 2, custom_name: 'Option 2', sub: 'Subtext 2'}, {id: 3, custom_name: 'Option 3', sub: 'Subtext 3'}]"
            :reduce="returnId" select_label="custom_name" rules="select_required">
            <template #option="custom_opt">
              <h5 class="m-0 p-1 d-inline-block">
                {{ custom_opt.custom_name }} <i class="status-icon fa fa-rocket green"></i>
              </h5>
              <div class="text-muted">{{custom_opt.sub}}</div>
            </template>
            <template #selected-option="custom_opt">
              <h5 class="m-0 d-inline-block">
                <span v-if="custom_opt">{{ custom_opt.custom_name }}</span>
              </h5>
              <i class="status-icon fa fa-rocket green ms-3"></i>
            </template>
          </bs-v-select>

          <bs-v-select id="sandbox_select_multi_req" label="V-Select Required Multiple Dropdown" placeholder="Select Options"
            v-model="form.vselect_select_multi" @change="change_select" :options="[{id: 1, custom_name: 'Option 1', sub: 'Subtext 1'}, {id: 2, custom_name: 'Option 2', sub: 'Subtext 2'}, {id: 3, custom_name: 'Option 3', sub: 'Subtext 3'}]"
            :reduce="returnId" select_label="custom_name" rules="select_required|exact_length:2" :multiple="true">
            <template #option="custom_opt">
              <h5 class="m-0 p-1 d-inline-block">
                {{ custom_opt.custom_name }} <i class="status-icon fa fa-rocket green"></i>
              </h5>
              <div class="text-muted">{{custom_opt.sub}}</div>
            </template>
            <template #selected-option="custom_opt">
              <h5 class="m-0 d-inline-block">
                <span v-if="custom_opt">{{ custom_opt.custom_name }}</span>
              </h5>
              <i class="status-icon fa fa-rocket green ms-3"></i>
            </template>
          </bs-v-select>

          <bs-input-image
            id="sandbox_input_image"
            label="Input Image"
            v-model="form.input_image"
            rules="image_required"
            ref="picture_input"
            width="400"
            height="100"
            prefill="http://localhost:8080/company/logo/1.jpg"
          />

          <bs-v-date-picker id="sandbox_datetime_req" mode="dateTime" label="V-Date-Picker Required" placeholder="Select a Date and Time"
            v-model="form.vdatepicker_datetime_req" timezone="Asia/Tokyo" :attributes="cal_attributes" rules="date_required">
          </bs-v-date-picker>

          <bs-v-date-picker id="sandbox_datetime_inline_req" mode="dateTime" label="V-Date-Picker Inline Required" placeholder="Select a Date and Time"
            v-model="form.vdatepicker_datetime_inline_req" timezone="Asia/Tokyo" :attributes="cal_attributes" rules="date_required" :inline="true">
          </bs-v-date-picker>

        </fieldset>

      </bs-form>
    </div>

    <div class="row mt-3 mb-3">
      <h1>Extras</h1>
      <qr-code qr-data="https://solanasystems.com" />
    </div>

    <div class="row mt-3 mb-3">
      <confirm-modal ref="confirm_modal" title="Confirm Title" message="Are you sure about that?" @confirmed="confirmed" @rejected="rejected" />
    </div>

  </div>
</template>
