<style lang="scss">
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
//  import i18n from '../i18n.js'
  export default {
    name: 'inventory-edit-form',
    props: {
      product: {
        default: '',
      },
      buttons: {
        type: Boolean,
        default: true,
      },
      inventory_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form_loading: true,
        form: {
          id: null,
          name: '',
          description: '',
          product_image: '',
          product_image_type: '',
          image_url: null,
          unit_price: '',
          stock_quantity: '',
          date_available: '',
          sales_target_number: '',
        },
        invalid: false,
        cal_attributes: [
          {
            key: 'today',
            highlight: {
              color: 'red',
              fillMode: 'light',
            },
            dates: new Date(),
          },
        ],
        cal_masks: {
          input: ["YYYY-MM-DD", "YYYY/MM/DD", "L"],
        },
      }
    },
    components: {

    },
    computed: {
      ...mapGetters([
        'get_promotion',
      ]),
      current_promotion() {
        return this.get_promotion;
      },
    },
    methods: {
      ...mapActions([
        'do_save_product',
      ]),
      init_form() {
        if (this.product) {
          let product = JSON.parse(JSON.stringify(this.product));
          this.form.id = product.id;
          this.form.name = product.name;
          this.form.description = product.description;
          this.form.image_url = product.image_url ? product.image_url : null;
          this.form.product_image = null;
          this.form.product_image_type = product.product_image_type;
          this.form.unit_price = product.unit_price;
          this.form.stock_quantity = product.stock_quantity;
          this.form.date_available = product.date_available;
          this.form.sales_target_number = product.sales_target_number;
        }
      },
      trigger_save() {
        // Trigger Validate & Submit
        this.$refs.inventory_form.trigger_submit();
      },
      save_product() {
        let $this = this;
        let promotion = this.current_promotion;
        let params = {
          product: this.form,
          list: this.inventory_list,
          promotion_id: promotion.id,
          company_id: promotion.company_id,
        };
        this.do_save_product(params)
          .then(function(product) {
            $this.$emit('saved', product);
          }).finally(function() {
            $this.$refs.inventory_form.disable_buttons(false);
            $this.$refs.inventory_form.set_form_loading(false);
          }).catch(function(err) {
            $this.$emit('failed', err);
          });
      },
      buttons_disabled(disabled) {
        this.$emit('buttons_disabled', disabled);
      },
      set_form_loading(form_loading = true) {
        this.$emit('form_loading', form_loading);
      },
      form_is_loading(form_loading = true) {
        let $this = this;
        this.$set(this, 'form_loading', form_loading);
        if (!form_loading) {
          window.setTimeout(() => {
            $this.$refs.product_image_uploader.onResize();
          }, 1000);
        }
      },
      returnId(option) {
        return option.id;
      },
      imageChanged(image) {
        this.form.image_url = null;
        this.form.product_image_type = image.type;
      },
      imageRemoved() {
        this.form.image_url = null;
        this.form.product_image = null;
        this.form.product_image_type = null;
      },
    },
    mounted() {
      console.log('InventoryEditForm Mounted');
      this.init_form();
    },
    watch: {
      'product.id': function(new_val) {
        if (new_val != this.form.id) {
          this.init_form();
        }
      },
    },
  }
</script>
<template>
  <div>
    <bs-form ref="inventory_form" class="inventory-form" :submit_button="buttons ? 'submit' : false" :reset_button="false" @submit="save_product" @buttons_disabled="buttons_disabled" @form_loading="set_form_loading">

      <fieldset>
        <legend>{{$t('product.info')}}</legend>
        <bs-input-text id="product_name" type="text" :label="$t('product.name')" :placeholder="$t('product.name')" v-model="form.name" rules="required" autocomplete="product-name" />
        <bs-input-text id="product_description" type="text" :label="$t('product.description')" :placeholder="$t('product.description')" v-model="form.description" />
        <bs-input-text id="product_unit_price" type="number" :label="$t('product.unit_price')" :placeholder="$t('product.unit_price')" v-model="form.unit_price">
          <template v-slot:prepend>
            <i class="fa fa-yen-sign"></i>
          </template>
        </bs-input-text>
        <bs-input-image id="product_image_uploader" ref="product_image_uploader" :label="$t('product.image')" v-model="form.product_image" size="2" width="300" height="300" :prefill="form.image_url" @changed="imageChanged" @removed="imageRemoved" max_size_text_key="image_upload.max_size_300" />
      </fieldset>
      <fieldset>
        <legend>{{$t('product.inventory')}} @ {{ current_promotion.name }}</legend>
        <bs-input-text id="product_stock_quantity" type="number" :label="$t('product.stock_quantity')" :placeholder="$t('product.stock_quantity')" v-model="form.stock_quantity" />
        <bs-v-date-picker id="product_date_available" mode="date" :label="$t('product.date_available')" :placeholder="$t('product.date_available')"
          v-model="form.date_available" timezone="Asia/Tokyo" rules="date_required" :attributes="cal_attributes" :masks="cal_masks">
        </bs-v-date-picker>
        <bs-input-text id="product_sales_target_number" type="number" :label="$t('product.sales_target')" :placeholder="$t('product.sales_target')" v-model="form.sales_target_number" :input="cal_attributes.input"/>
      </fieldset>
    </bs-form>
  </div>
</template>
