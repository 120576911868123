<style lang="scss">

</style>
<script>
  import LocationEditForm from '../components/LocationEditForm.vue'
  import i18n from '../i18n.js'
  export default {
    name: 'location-edit-modal',
    components: {
      LocationEditForm,
    },
    props: {
      location: null,
      location_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        modal: null,
        component_key: 0,
        error_message: '',
      }
    },
    computed: {
      location_title() {
        return (this.location && this.location.id ? i18n.t('location.data') + ' ' + this.location.name : i18n.t('location.add_new'));
      },
    },
    methods: {
      open() {
        let $this = this;
        this.$nextTick(() => {
          $this.$refs.bs_modal.open();
        });
      },
      close() {
        this.$refs.bs_modal.close();
      },
      save() {
        this.$refs.location_form.trigger_save();
      },
      saved(location) {
        this.$emit('saved', location);
      },
      failed(err) {
        this.$emit('failed', err);
      },
      disable_buttons(disable = true) {
        this.$refs.bs_modal.disable_buttons(disable);
      },
      set_form_loading(form_loading = true) {
        this.$refs.bs_modal.set_form_loading(form_loading);
      },
      form_is_loading(form_loading = true) {
        this.$refs.location_form.form_is_loading(form_loading);
      },
    },
    watch: {

    },
    mounted() {
      console.log('LocationEditModal Mounted');
    },
  }
</script>
<template>
  <div>
    <bs-modal :title="location_title" ref="bs_modal" @save="save" @form_loading="form_is_loading">
      <location-edit-form ref="location_form" :location="location" @saved="saved" @failed="failed" :buttons="false" :location_list="location_list" v-if="location" @buttons_disabled="disable_buttons" @form_loading="set_form_loading" />
    </bs-modal>
  </div>
</template>
