<style lang="scss">
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import i18n from '../i18n.js'
  export default {
    name: 'user-edit-form',
    props: {
      user: {
        default: '',
      },
      buttons: {
        type: Boolean,
        default: true,
      },
      user_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        form: {
          id: null,
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          confirm_password: '',
          enabled: true,
          role: null,
          company_ids: null,
        },
        invalid: false,
      }
    },
    components: {

    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company_list',
      ]),
      current_user() {
        return this.get_user;
      },
      company_list() {
        let list = this.get_company_list;
        if (list.length == 0) {
          this.do_fetch_companies();
        }
        return list;
      },
      edit_perms() {
        return (this.current_user && this.current_user.id != this.form.id && this.user_list);
      },
      role_options() {
        return [
          {
            value: 'admin',
            label: i18n.t('usr.admin'),
          },
          {
            value: 'user',
            label: i18n.t('usr.user'),
          },
        ];
      },
      password_rules() {
        return (this.form.id) ? 'min:8' : 'required|min:8';
      },
      confirm_password_rules() {
        return (this.form.password.length ? this.password_rules + '|required|password_match:user_password' : '');
      },
    },
    methods: {
      ...mapActions([
        'do_save_user',
        'do_fetch_companies',
      ]),
      init_form() {
        console.log('Init UserEditForm', this.user);
        if (this.user) {
          let usr = JSON.parse(JSON.stringify(this.user));
          this.form.id = usr.id;
          this.form.email = usr.email;
          this.form.first_name = usr.first_name;
          this.form.last_name = usr.last_name;
          this.form.password = '';
          this.form.confirm_password = '';
          this.form.enabled = usr.enabled;
          this.form.role = usr.role;
          this.form.company_ids = usr.company_ids;
        }
      },
      trigger_save() {
        // Trigger Validate & Submit
        this.$refs.user_form.trigger_submit();
      },
      save_user() {
        let $this = this;
        this.loading = true;
        this.invalid = false;
        this.do_save_user({user: this.form, list: this.user_list})
          .then(function(user) {
            $this.$emit('saved', user);
          }).finally(function() {
            $this.$refs.user_form.disable_buttons(false);
            $this.$refs.user_form.set_form_loading(false);
          }).catch(function(err) {
            $this.$emit('failed', err);
          });
      },
      buttons_disabled(disabled) {
        this.$emit('buttons_disabled', disabled);
      },
      set_form_loading(form_loading = true) {
        this.$emit('form_loading', form_loading);
      },
      returnId(option) {
        return option.id;
      },
    },
    mounted() {
      console.log('UserEditForm Mounted');
      this.init_form();
    },
    watch: {
      'user.id': function(new_val) {
        if (new_val != this.form.id) {
          this.init_form();
        }
      },
    },
  }
</script>
<template>
  <div>
    <bs-form ref="user_form" class="user" :submit_button="buttons ? 'submit' : false" :reset_button="false" @submit="save_user" @buttons_disabled="buttons_disabled" @form_loading="set_form_loading">

      <fieldset>
        <legend>{{$t('usr.profile')}}</legend>
        <bs-input-text id="user_email" type="email" :label="$t('usr.email')" :placeholder="$t('usr.email')" v-model="form.email" rules="required" autocomplete="username">
          <template v-slot:prepend>
            <i class="fa fa-at"></i>
          </template>
        </bs-input-text>
        <bs-input-text id="user_first_name" type="text" :label="$t('usr.first_name')" :placeholder="$t('usr.first_name')" v-model="form.first_name" rules="required" autocomplete="given-name">
          <template v-slot:prepend>
            <i class="fa fa-user"></i>
          </template>
        </bs-input-text>
        <bs-input-text id="user_last_name" type="text" :label="$t('usr.last_name')" :placeholder="$t('usr.last_name')" v-model="form.last_name" rules="required" autocomplete="family-name">
          <template v-slot:prepend>
            <i class="fa fa-user"></i>
          </template>
        </bs-input-text>
      </fieldset>

      <fieldset>
        <legend>{{$t('usr.security')}}</legend>
        <bs-input-text id="user_password" type="password" :label="$t('usr.password')" :placeholder="$t('usr.password')" v-model="form.password" :rules="password_rules" autocomplete="new-password">
          <template v-slot:prepend>
            <i class="fa fa-key"></i>
          </template>
        </bs-input-text>
        <bs-input-text id="user_confirm_password" type="password" :label="$t('usr.confirm_password')" :placeholder="$t('usr.confirm_password')" v-model="form.confirm_password" :rules="confirm_password_rules" autocomplete="new-password">
          <template v-slot:prepend>
            <i class="fa fa-key"></i>
          </template>
        </bs-input-text>
      </fieldset>

      <fieldset v-if="edit_perms">
        <legend>{{ $t('usr.permissions') }}</legend>
        <bs-input-radio-checkboxes id="user_enabled" type="switch" v-model="form.enabled" :inline="true" :label="$t('usr.account_enabled')" />
        <bs-input-radio-checkboxes id="user_role" type="radio" v-model="form.role" :label="$t('usr.role')" :options="role_options" />

        <bs-v-select id="user_companies" :label="$t('usr.companies')" :placeholder="$t('usr.select_company')"
          v-model="form.company_ids" :options="company_list"
          :reduce="returnId" select_label="name" :multiple="true">
        </bs-v-select>
      </fieldset>

      <!--fieldset>
        <legend>{{ $t('settings') }</legend>
        <bs-input-text id="user_slideshow_seconds" type="number" label="Dashboard Slide Duration" placeholder="10 Seconds" v-model="form.slideshow_seconds" min="0">
          <template v-slot:prepend>
            <i class="fa fa-clock"></i>
          </template>
          <template v-slot:append>
            Seconds
          </template>
        </bs-input-text>
      </fieldset-->

    </bs-form>
  </div>
</template>