<script>

import { ValidationProvider } from 'vee-validate';
import BsInputFeedback from './BsInputFeedback.vue';

export default {
  components: {
    ValidationProvider,
    BsInputFeedback,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    colClass: {
      type: String,
      default: 'mb-3',
    },
    groupClass: {
      type: String,
      default: '',
    },
    // must be included in props
    value: {
      type: null
    },
    label: {
      default: ''
    },
    rules: {
      type: [Object, String],
      default: null
    },
    min: {
      default: ''
    },
    max: {
      default: ''
    },
  },
  computed: {

  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value !== '' && this.value !== null) {
      this.innerValue = this.value;
    }
  }
};
</script>
<template>
  <ValidationProvider ref="vp" :vid="id" :name="label" :rules="rules" v-slot="{ errors, valid }" :slim="true">
    <div :class="colClass">
      <label :for="id" class="form-label" v-if="label">{{label}}</label>
      <div class="input-group" :class="groupClass">
        <span v-if="$slots.prepend" class="input-group-text">
          <slot name="prepend" />
        </span>
        <input
          :id="id"
          :name="id"
          :type="type"
          class="form-control"
          v-model="innerValue"
          :error-messages="errors"
          :success="valid"
          v-bind="$attrs"
          v-on="$listeners"
          :state="errors[0] ? false : (valid ? true : null)"
          :class="{ 'is-valid': rules != null && value !== '' && valid && !errors[0], 'is-invalid': errors[0] }"
          :min="min"
          :max="max"
        />
        <span v-if="$slots.append" class="input-group-text">
          <slot name="append" />
        </span>
        <bs-input-feedback :valid="valid" v-if="errors[0]">{{ errors[0] }}</bs-input-feedback>
      </div>
    </div>
  </ValidationProvider>
</template>