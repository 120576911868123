<style lang="scss">
  .promotion-space-list-container {
    #promotion-space-list-table {
      .el-table {
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import i18n from '../i18n.js'
  import Promotion_spaceEditModal from '../components/PromotionSpaceEditModal.vue'
  export default {
    name: 'admin-promotion-space-list',
    title: 'promotion_space.list',
    components: {
      Promotion_spaceEditModal,
      'promotion-space-list-toolbar': {
        name: 'promotion-space-list-toolbar',
        template: `
          <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" @click="$parent.add_promotion_space()">{{$t('promotion_space.add')}} <i class="fas green fa-plus"></i></button>
              </div>
            </div>
          </div>
        `,
      },
      'tbl-cell-actions': {
        props: ['row'],
        template: `
          <div class="">
            <button type="button" class="btn btn-outline-primary" @click="$parent.$parent.$parent.$parent.edit_promotion_space(row)" v-tooltip:bottom="$t('promotion_space.edit')">
              <i class="fa fa-pencil-alt"></i>
            </button>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        default_promotion_space: {
          id: null,
          name: '',
          location_id: '',
          description: '',
        },
        editing_promotion_space: null,
        location_id: null,
        table_filter_val: '',
      }
    },
    computed: {
      ...mapGetters([
        'get_promotion_space_list',
        'get_location_list',
      ]),
      location_list() {
        return this.get_location_list;
      },
      promotion_space_list() {
        let list = this.get_promotion_space_list;
        if (this.location_id) list = list.filter(ps => ps.location_id == this.location_id);
        return list;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'name',
              order: 'ascending'
            },
          },
          fields: [
            { label: i18n.t('promotion_space.name'), prop: 'name', sortable: true, searchable: true, min_width: '150px' },
            { label: i18n.t('promotion_space.description'), prop: 'description', sortable: true, searchable: true },
            { label: i18n.t('promotion_space.location'), prop: 'location_name', sortable: true, searchable: true },
            { label: i18n.t('actions'), sortable: false, searchable: false, component: 'tbl-cell-actions', align: 'center' },
          ],
          filters: [
            {
              prop: ['name', 'description', 'location_name'],
              value: this.table_filter_val,
            }
          ],
        }
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_promotion_space_list',
      ]),
      fetch_promotion_space_list() {
        this.do_fetch_promotion_space_list();
      },
      edit_promotion_space(promotion_space) {
        let def = JSON.parse(JSON.stringify(this.default_promotion_space));
        if (!this.editing_promotion_space || this.editing_promotion_space.id != promotion_space.id) {
          this.$set(this, 'editing_promotion_space', Object.assign(def, promotion_space));
        }
        this.$refs.promotion_space_edit_modal.open();
      },
      add_promotion_space() {
        this.$set(this, 'editing_promotion_space', JSON.parse(JSON.stringify(this.default_promotion_space)));
        this.$refs.promotion_space_edit_modal.open();
      },
      saved(promotion_space) {
        console.log('saved', promotion_space);
        this.$refs.promotion_space_edit_modal.close();
        this.$toast.success(this.$t('promotion_space.data_saved'));
        this.$set(this, 'editing_promotion_space', null);
      },
      failed(err) {
        console.log('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
      returnId(option) {
        return option.id;
      },
      change_location(loc) {
console.log('Changed Loc', loc)
      },
    },
    mounted() {
      console.log('PromotionSpaceList mounted.');
      if (!this.location_list || this.location_list.length == 0) {
        this.fetch_promotion_space_list();
      }
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 promotion-space-list-container" :class="{loading: loading}">

    <h3>{{ $t('promotion_space.list') }} <i class="fa fa-map-marked-alt"></i></h3>
    <promotion-space-list-toolbar />

    <div class="row mt-3 mb-3" v-if="promotion_space_list">
      <div class="col-6">
        <bs-v-select id="location_id_filter" :placeholder="$t('promotion.select_location')"
          v-model="location_id" :options="location_list" @change="change_location"
          :reduce="returnId" select_label="name">
        </bs-v-select>
      </div>
      <div class="col-6">
        <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
          <template v-slot:prepend>
            <i class="fa fa-search"></i>
          </template>
        </bs-input-text>
      </div>
    </div>

    <data-tables
      ref="promotion-space-list-table"
      id="promotion-space-list-table"
      :table-props="table_config.options"
      :fields="table_config.fields"
      :data="promotion_space_list"
      :page-size="10"
      :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
      :filters="table_config.filters"
    >
      <el-table-column v-for="field in table_config.fields"
        :label="field.label"
        :prop="field.prop"
        :key="field.prop"
        :sortable="field.sortable ? 'custom' : false"
        :sort-by="field.sort_by ? field.sort_by : field.prop"
        :align="field.align"
        :min-width="field.min_width ? field.min_width : null"
      >
        <template slot-scope="scope">
          <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
          <span v-else>{{scope.row[field.prop]}}</span>
        </template>
      </el-table-column>
    </data-tables>


    <promotion-space-list-toolbar />

    <promotion-space-edit-modal @saved="saved" @failed="failed" :promotion_space="editing_promotion_space" :promotion_space_list="true" ref="promotion_space_edit_modal" />

  </div>
</template>
