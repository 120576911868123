<style lang="scss">
  .promotion_space-edit-form {
    .cej-input-image {
      .preview-container {
        .picture-inner {
          height: 85% !important;
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
//  import i18n from '../i18n.js'
  export default {
    name: 'promotion_space-edit-form',
    props: {
      promotion_space: {
        default: '',
      },
      buttons: {
        type: Boolean,
        default: true,
      },
      promotion_space_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form_loading: true,
        form: {
          id: null,
          name: '',
          description: '',
          location_id: '',
        },
        invalid: false,
      }
    },
    components: {

    },
    computed: {
      ...mapGetters([
        'get_promotion_space',
        'get_location_list',
      ]),
      location_list() {
        return this.get_location_list;
      },
    },
    methods: {
      ...mapActions([
        'do_save_promotion_space',
      ]),
      init_form() {
        if (this.promotion_space) {
          let promotion_space = JSON.parse(JSON.stringify(this.promotion_space));
          this.form.id = promotion_space.id;
          this.form.name = promotion_space.name;
          this.form.description = promotion_space.description;
          this.form.location_id = promotion_space.location_id;
        }
      },
      trigger_save() {
        // Trigger Validate & Submit
        this.$refs.promotion_space_form.trigger_submit();
      },
      save_promotion_space() {
        let $this = this;
        this.do_save_promotion_space({promotion_space: this.form, list: this.promotion_space_list})
          .then(function(promotion_space) {
            $this.$emit('saved', promotion_space);
          }).finally(function() {
            $this.$refs.promotion_space_form.disable_buttons(false);
            $this.$refs.promotion_space_form.set_form_loading(false);
          }).catch(function(err) {
            $this.$emit('failed', err);
          });
      },
      buttons_disabled(disabled) {
        this.$emit('buttons_disabled', disabled);
      },
      set_form_loading(form_loading = true) {
        this.$emit('form_loading', form_loading);
      },
      form_is_loading(form_loading = true) {
        this.$set(this, 'form_loading', form_loading);
      },
      returnId(option) {
        return option.id;
      },
    },
    mounted() {
      console.log('PromotionSpaceEditForm Mounted');
      this.init_form();
    },
    watch: {
      'promotion_space.id': function(new_val) {
        if (new_val != this.form.id) {
          this.init_form();
        }
      },
    },
  }
</script>
<template>
  <div>
    <bs-form ref="promotion_space_form" class="promotion_space-edit-form" :submit_button="buttons ? 'submit' : false" :reset_button="false" @submit="save_promotion_space" @buttons_disabled="buttons_disabled" @form_loading="set_form_loading">

      <fieldset>
        <legend>{{$t('promotion_space.data')}}</legend>
        <bs-input-text id="promotion_space_name" type="text" :label="$t('promotion_space.name')" :placeholder="$t('promotion_space.name')" v-model="form.name" rules="required" autocomplete="promotion_space-name" />
        <bs-input-text id="promotion_space_description" type="text" :label="$t('promotion_space.description')" :placeholder="$t('promotion_space.description')" v-model="form.description" />
        <bs-v-select id="promotion_space_location_id" :label="$t('promotion_space.location')" :placeholder="$t('promotion.select_location')"
          v-model="form.location_id" :options="location_list"
          :reduce="returnId" select_label="name" rules="select_required">
        </bs-v-select>

      </fieldset>
    </bs-form>
  </div>
</template>
