<style lang="scss">

</style>
<script>
  import UserEditForm from '../components/UserEditForm.vue'
  import i18n from '../i18n.js'
  export default {
    name: 'user-edit-modal',
    components: {
      UserEditForm,
    },
    props: {
      user: null,
      user_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        modal: null,
        component_key: 0,
        error_message: '',
      }
    },
    computed: {
      user_title() {
        return (this.user && this.user.id ? i18n.t('usr.edit_user') + ' ' + this.user.email : i18n.t('usr.add_new_user'));
      },
    },
    methods: {
      open() {
        let $this = this;
        this.$nextTick(() => {
          $this.$refs.bs_modal.open();
        });
      },
      close() {
        this.$refs.bs_modal.close();
      },
      save() {
        this.$refs.user_form.trigger_save();
      },
      saved(user) {
        this.$emit('saved', user);
      },
      failed(err) {
        this.$emit('failed', err);
      },
      disable_buttons(disable = true) {
        this.$refs.bs_modal.disable_buttons(disable);
      },
      set_form_loading(form_loading = true) {
        this.$refs.bs_modal.set_form_loading(form_loading);
      },
    },
    watch: {

    },
    mounted() {
      console.log('UserEditModal Mounted');
    },
  }
</script>
<template>
  <div>
    <bs-modal :title="user_title" ref="bs_modal" @save="save">
      <user-edit-form ref="user_form" :user="user" @saved="saved" @failed="failed" :buttons="false" :user_list="user_list" v-if="user" @buttons_disabled="disable_buttons" @form_loading="set_form_loading" />
    </bs-modal>
  </div>
</template>