<style lang="scss">
  .v-select-container {
    &.is-invalid .v-select > div {
      border-color: #e3342f;
      padding-right: calc(1.6em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e3342f'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.4em + 0.1875rem) center;
      background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
    }
  }
  .v-select-all {
    font-size: 0.875em;
  }
</style>
<script>

import { ValidationProvider } from 'vee-validate';
import BsInputFeedback from './BsInputFeedback.vue';
import VSelect from 'vue-select'


export default {
  components: {
    ValidationProvider,
    BsInputFeedback,
    VSelect,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    colClass: {
      type: String,
      default: 'mb-3',
    },
    // must be included in props
    value: {
      type: null
    },
    label: {
      default: ''
    },
    select_label: {
      default: 'label'
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    options: {
      type: Array,
    },
    selectClass: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: null
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    select_all: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: "",
    initialValue: null,
  }),
  computed: {

  },
  methods: {
    change(selected) {
      this.$emit('change', selected);
    },
    set_select_all() {
      let opts = [];
      this.options.forEach(opt => {
        opts.push(opt.id);
      });
      this.$set(this, 'innerValue', opts);
    },
    set_select_none() {
      this.$set(this, 'innerValue', []);
    },
  },
  mounted() {
    if (this.value) {
      this.innerValue = this.value;
      this.initialValue = this.value;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
    options(new_options, old_options) {
      if (!old_options) {
        this.$refs.vselect.value = this.innerValue;
      }
    },
  },
};
</script>
<template>
  <ValidationProvider ref="vp" :vid="id" :name="label" :rules="rules" v-slot="{ errors, valid }" :value="value" :slim="true" :error-messages="errorMessages">
    <div :class="colClass">
      <label :for="id" class="form-label" v-if="label">{{label}}</label>
      <!--div v-if="options && options.length > 1" class="v-select-container" :class="{ 'is-valid': value !== '' && valid && !errors[0], 'is-invalid': errors[0] }"-->
      <div class="v-select-container" :class="{ 'is-valid': value != initialValue && valid && !errors[0], 'is-invalid': errors[0] }">
        <v-select ref="vselect" :class="selectClass" v-bind="$attrs" v-on="$listeners" :options="options" :label="select_label" v-model="innerValue" @option:selected="change" :close-on-select="closeOnSelect">
          <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </v-select>
      </div>
      <div class="input-group">
        <input
          type="hidden"
          class="form-control"
          v-model="innerValue"
          :error-messages="errors"
          :success="valid"
          v-bind="$attrs"
          v-on="$listeners"
          :state="errors[0] ? false : (valid ? true : null)"
          :class="{ 'is-valid': value !== '' && valid && !errors[0], 'is-invalid': errors[0] }"
        />
        <span class="m-1 v-select-all" v-if="select_all">
          <a class="me-3" @click="set_select_all" href="javascript://">{{ $t('select_all')}}</a>
          <a @click="set_select_none" href="javascript://">{{ $t('select_none')}}</a>
        </span>
        <bs-input-feedback :valid="valid" v-if="errors[0]">{{ errors[0] }}</bs-input-feedback>
      </div>
    </div>
  </ValidationProvider>
</template>