<style lang="scss">
  .cej-checkboxes {
    .form-check {
      input, label {
        cursor: pointer;
      }
    }
    .invalid-feedback {
      display: unset;
    }
  }
</style>
<script>

import { ValidationProvider } from 'vee-validate';
import BsInputFeedback from './BsInputFeedback.vue';

export default {
  components: {
    ValidationProvider,
    BsInputFeedback,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
      default: 'radio', // checkbox, switch
    },
    colClass: {
      type: String,
      default: 'mb-3',
    },
    // must be included in props
    value: {
      default: false,
    },
    label: {
      default: ''
    },
    rules: {
      type: [Object, String],
      default: null
    },
    options: {
      type: Array,
      default: () => { return [{label: ''}]; }
    },
    inline: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    input_type() {
      return this.type == 'radio' ? 'radio' : 'checkbox';
    },
    input_container_class() {
      return {
        'form-check-inline': this.inline,
        'form-switch': this.type == 'switch' || this.type == 'toggle' ? true : false,
      };
    },
    computed_options() {
      let options = this.options;
      options.forEach(opt => {
        if (!opt.value) opt.value = true;
      });
      return options;
    },
  },
  data: () => ({
    innerValue: [],
  }),
  watch: {
    // Handles internal model changes.
    innerValue(new_val) {
      let ret = null;
      if (this.options.length == 1) {
        ret = (new_val[0] ? true : false);
      } else {
        ret = new_val;
      }
      this.$emit("input", ret);
    },
    // Handles external model changes.
    value(new_val) {
      let val = false;
      if (this.options.length == 1) {
        let opt = this.computed_options[0];
        val = new_val ? [opt.value] : [];
      } else {
        val = new_val;
      }
      this.innerValue = val;
    },
  },
  created() {
    let new_val = this.value;
    if (new_val) {
      let val = false;
      if (new_val) {
        val = !Array.isArray(new_val) ? [new_val] : new_val;
      }
      this.innerValue = val;
    }
  }
};
</script>
<template>
  <ValidationProvider ref="vp" :vid="id" :name="label" :rules="rules" v-slot="{ errors, valid }" :slim="true">
    <div class="cej-checkboxes" :class="colClass">
      <label :for="id" class="form-label me-3" v-if="label">{{label}}</label>
      <div class="form-check" :class="input_container_class" v-for="option in computed_options" :key="option.value">
        <input
          :type="input_type"
          class="form-check-input"
          :value="option.value"
          v-model="innerValue"
          :class="{ 'is-valid': rules != null && value !== '' && valid && !errors[0], 'is-invalid': errors[0] }"
          :id="id + '_' + option.value"
        />
        <label class="form-check-label" :for="id + '_' + option.value">
          <span v-if="option.label">{{ option.label }}</span>
          <span v-else-if="$slots[`label_${option.value}`]">
            <slot
              :name="`label_${option.value}`"
              :option="option"
            />
          </span>
        </label>
      </div>
      <bs-input-feedback :valid="valid" v-if="errors[0]">{{ errors[0] }}</bs-input-feedback>
    </div>
  </ValidationProvider>
</template>