<script>
  import { mapActions, mapGetters } from 'vuex'
  import { languageLabels } from '../i18n.js';
  export default {
    name: 'language-switcher',
    components: {

    },
    props: {
      listClass: {
        default: 'nav-item dropdown no-print'
      },
      dark: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'get_language',
      ]),
      languages() {
        return languageLabels;
      },
      current_language() {
        return this.get_language;
      },
    },
    methods: {
      ...mapActions([
        'do_change_language',
      ]),
      change_language(language) {
        this.do_change_language(language);
      },
    },
    mounted() {

    },
  }
</script>
<template>
  <li :class="listClass">
    <a class="nav-link dropdown-toggle" href="javascript://" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      {{ languages[current_language] }} <i class="fa fa-globe"></i>
    </a>
    <ul class="dropdown-menu" :class="{'dropdown-menu-dark': dark}" aria-labelledby="languageDropdown">
      <li v-for="(lang, lang_key) in languages" :key="lang_key">
        <a class="dropdown-item" href="javascript://" @click="change_language(lang_key)">{{ lang }}</a>
      </li>
    </ul>
  </li>
</template>
