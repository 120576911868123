<style lang="scss">
</style>
<script>
  import { mapGetters } from 'vuex'
  import TopNavbar from './TopNavbar.vue'
  import MenuNavbar from './MenuNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import MobileFooter from './MobileFooter.vue'
  export default {
    components: {
      TopNavbar,
      MenuNavbar,
      ContentFooter,
      MobileFooter,
    },
    computed: {
      ...mapGetters([
        'is_logged_in',
        'get_user',
      ]),
      active_route() {
        return this.$route.name;
      },
      layout() {
        let layout = 'standard';
        this.$route.matched.forEach((matched) => {
          if (matched.meta.layout) layout = matched.meta.layout;
        });
        return layout;
      },
    },
    methods: {
    },
    mounted() {
      console.log('AppLayout mounted.');
    },
    created() {

    },
    watch: {
    },
  }

</script>
<template>
  <div class="inner-wrapper">
    <top-navbar v-if="layout == 'standard'"></top-navbar>
    <menu-navbar v-if="layout == 'standard'"></menu-navbar>

    <div class="container-fluid">
      <div class="row">
        <div>
          <main class="col main-content">
            <router-view></router-view>
          </main>
        </div>
      </div>
      <div class="row">
        <content-footer v-if="layout == 'standard'"></content-footer>
        <mobile-footer v-if="layout == 'mobile'"></mobile-footer>
      </div>
    </div>
  </div>
</template>