<script>

import Chart from 'vue-chartjs3'


export default {
  components: {
    Chart,
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'line'
    },
    fullscreen_mode: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      default_options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
      },
    }
  },
  computed: {
    chart_options() {
      return Object.assign(this.default_options, this.options);
    },
  },
  mounted() {
  },
}
</script>
<template>
  <div class="cej-chart-container">
    <Chart :data="data" :options="chart_options" :type="type" v-if="data" />
  </div>
</template>
