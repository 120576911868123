<style lang="scss">
  .sales-schedule-list-container {
    #sales-schedule-list-table {
      .el-table {
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import i18n from '../i18n.js';
  import { bus } from '../main';
  export default {
    name: 'sales-schedule-list',
    components: {
      'tbl-cell-status': {
        props: ['row'],
        template: `
          <span>{{ $t('sales.' + row.sales_status)}}</span>
        `,
      },
      'tbl-cell-actions': {
        props: ['row'],
        template: `
          <div class="">
            <button type="button" class="btn btn-outline-primary" @click="$parent.$parent.$parent.$parent.show_confirmation(row)" v-tooltip:bottom="$t('sales.cancel_reservation')" v-if="row.sales_status == 'reserved'">
              <i class="fa fa-times red"></i>
            </button>
            <router-link type="button" class="btn btn-outline-primary" :to="{ name: 'sales.management', params: { reservation_num: row.reservation_number } }" v-tooltip:bottom="$t('smgmt.view_reservation')">
              <i class="fa fa-receipt"></i>
            </router-link>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        saving: false,
        table_filter_val: '',
        request_timer_duration: 60000,
        request_timer: null,
        sale_to_cancel: null,
      }
    },
    computed: {
      ...mapGetters([
        'get_promotion',
        'get_sales_schedule',
      ]),
      current_promotion() {
        return this.get_promotion;
      },
      sales_schedule() {
        return this.get_sales_schedule;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'confirmed_at',
              order: 'descending'
            },
          },
          fields: [
            // Res #, Time, Status, Action (View, Cancel)
            { label: i18n.t('sales.reservation_number'), prop: 'reservation_number', sortable: true, searchable: true, min_width: '150px' },
            { label: i18n.t('smgmt.reservation_time'), prop: 'confirmed_at', sortable: true, searchable: true, min_width: '150px', formatter: this.$options.filters.formatDatemin },
            { label: i18n.t('smgmt.status'), prop: 'sales_status', sortable: true, searchable: true, component: 'tbl-cell-status' },
            { label: i18n.t('actions'), sortable: false, searchable: false, component: 'tbl-cell-actions', align: 'center' },
          ],
          filters: [
            {
              prop: ['reservation_number', 'sales_status'],
              value: this.table_filter_val,
            }
          ],
        }
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_sales_schedule',
        'do_save_sale',
      ]),
      fetch_sales_schedule() {
        this.clear_request_timer();
        this.loading = true;
        this.do_fetch_sales_schedule().then(resp => {
          if (resp) {
            // console.log('Schedule', resp);
          }
        }).finally(() => {
          this.loading = false;
          this.set_request_timer();
        }).catch(err => {
          console.error('Error', err);
          this.$toast.error(this.$t('server_error'));
        });
      },
      clear_request_timer() {
        if (this.request_timer) {
          clearTimeout(this.request_timer);
          this.request_timer = null;
        }
      },
      set_request_timer() {
        let $this = this;
        this.request_timer = setTimeout(function(){ $this.fetch_sales_schedule() }, this.request_timer_duration);
      },
      cancel_reservation() {
        let sale = this.sale_to_cancel;
        if (!sale) return;
        return new Promise((resolve, reject) => {
          if (sale.id && !['canceled', 'expired', 'purchased'].includes(sale.sales_status)) {
            let request = {
              action: 'cancel',
              promotion_id: sale.promotion_id,
              sale: {id: sale.id},
              sale_id: sale.id,
            };
            this.saving = true;
            this.do_save_sale(request).then(resp => {
              if (resp.success) {
                this.$toast.success(this.$t('sales.canceled'));
                this.fetch_sales_schedule();
                resolve(resp);
              } else {
                this.$toast.error(this.$t('server_error'));
                reject(resp);
              }
            }).finally(() => {
              this.saving = false;
            }).catch(err => {
              reject(err);
            });
          } else {
            resolve();
          }
        });
      },
      show_confirmation(sale) {
        this.sale_to_cancel = sale;
        this.$refs.confirm_modal.open();
      },
    },
    mounted() {
      console.log('SalesScheduleList mounted.');
      this.fetch_sales_schedule();
      bus.$on('sale_purchased', () => {
        this.fetch_sales_schedule();
      });
    },
    beforeDestroy() {
      console.log('SalesScheduleList Unmounted');
      this.clear_request_timer();
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 sales-schedule-list-container" :class="{loading: loading}">

    <h3>{{ $t('smgmt.schedule') }}</h3>

    <div class="row mt-3 mb-3" v-if="sales_schedule">

      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="sales-schedule-list-table"
        id="sales-schedule-list-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="sales_schedule"
        :page-size="10"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
          :formatter="field.formatter"
          :className="field.className"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else-if="field.formatter && scope.row[field.prop]" :class="field.spanClass">{{ field.formatter(scope.row[field.prop]) }}</span>
            <span v-else :class="field.spanClass">{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </data-tables>

    </div>

    <confirm-modal ref="confirm_modal" title="sales.cancel_reservation" message="sales.cancel_reservation_message" save_text="sales.confirm_cancel" close_text="sales.reject_cancel" save_class="btn-danger" @confirmed="cancel_reservation" />

  </div>
</template>
