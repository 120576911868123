<style lang="scss">
  .promotion-list-container {
    #promotion-list-table {
      .el-table {
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import i18n from '../i18n.js'
  import PromotionEditModal from '../components/PromotionEditModal.vue'
  export default {
    name: 'promotion-list',
    components: {
      PromotionEditModal,
      'promotion-list-toolbar': {
        name: 'promotion-list-toolbar',
        template: `
          <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" @click="$parent.add_promotion()">{{$t('promotion.add')}} <i class="fas green fa-plus"></i></button>
              </div>
            </div>
          </div>
        `,
      },
      'tbl-cell-actions': {
        props: ['row'],
        template: `
          <div class="">
            <button type="button" class="btn btn-outline-primary" @click="$parent.$parent.$parent.$parent.edit_promotion(row)" v-tooltip:bottom="$t('promotion.edit')">
              <i class="fa fa-pencil-alt"></i>
            </button>
            <router-link type="button" class="btn btn-outline-primary" :to="{ name: 'exhibitor.dashboard', params: { promotion_id: row.id, company_id: row.company_id } }" v-tooltip:bottom="$t('promotion.view')">
              <i class="fa fa-calendar-week"></i>
            </router-link>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        default_promotion: {
          id: null,
          promotion_space_id: null,
          company_id: null,
          name: '',
          start_date: null,
          end_date: null,
          progress_status: '',
          sales_target: null,
          description: '',
        },
        editing_promotion: null,
        table_filter_val: '',
      }
    },
    computed: {
      ...mapGetters([
        'get_promotion',
        'get_promotion_list',
      ]),
      current_promotion() {
        return this.get_promotion;
      },
      promotion_list() {
        return this.get_promotion_list;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'name',
              order: 'ascending'
            },
          },
          fields: [
            { label: i18n.t('promotion.name'), prop: 'name', sortable: true, searchable: true, min_width: '150px' },
            { label: i18n.t('promotion.location'), prop: 'location_name', sortable: true, searchable: true },
            { label: i18n.t('promotion.space_name'), prop: 'space_name', sortable: true, searchable: true },
            { label: i18n.t('promotion.start_date'), prop: 'start_date', sortable: true, searchable: true, formatter: this.$options.filters.formatDate },
            { label: i18n.t('promotion.end_date'), prop: 'end_date', sortable: true, searchable: true, formatter: this.$options.filters.formatDate },
            { label: i18n.t('company.name'), prop: 'company_name', sortable: true, searchable: true },
            { label: i18n.t('company.industry'), prop: 'company_industry', sortable: true, searchable: true },
            { label: i18n.t('actions'), sortable: false, searchable: false, component: 'tbl-cell-actions', align: 'center' },
          ],
          filters: [
            {
              prop: ['start_date', 'end_date', 'company_name', 'company_industry', 'space_name', 'location_name'],
              value: this.table_filter_val,
            }
          ],
        }
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_promotions',
      ]),
      fetch_promotions() {
        this.do_fetch_promotions();
      },
      edit_promotion(promotion) {
        let def = JSON.parse(JSON.stringify(this.default_promotion));
        if (!this.editing_promotion || this.editing_promotion.id != promotion.id) {
          this.$set(this, 'editing_promotion', Object.assign(def, promotion));
        }
        this.$refs.promotion_edit_modal.open();
      },
      add_promotion() {
        this.$set(this, 'editing_promotion', JSON.parse(JSON.stringify(this.default_promotion)));
        this.$refs.promotion_edit_modal.open();
      },
      saved(promotion) {
        console.log('saved', promotion);
        this.$refs.promotion_edit_modal.close();
        this.$toast.success(this.$t('promotion.data_saved'));
        this.$set(this, 'editing_promotion', null);
      },
      failed(err) {
        console.log('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
    },
    mounted() {
      console.log('PromotionList mounted.');
      if (!this.promotion_list) {
        this.fetch_promotions();
      }
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 promotion-list-container" :class="{loading: loading}">

    <h3>{{ $t('promotion.list') }}</h3>
    <promotion-list-toolbar />

    <div class="row mt-3 mb-3" v-if="promotion_list">

      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="promotion-list-table"
        id="promotion-list-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="promotion_list"
        :page-size="10"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
          :formatter="field.formatter"
          :className="field.className"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else-if="field.formatter && scope.row[field.prop]" :class="field.spanClass">{{ field.formatter(scope.row[field.prop]) }}</span>
            <span v-else :class="field.spanClass">{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </data-tables>

    </div>

    <promotion-list-toolbar />

    <promotion-edit-modal @saved="saved" @failed="failed" :promotion="editing_promotion" :promotion_list="true" ref="promotion_edit_modal" />

  </div>
</template>
