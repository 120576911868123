<style lang="scss">
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'promotion-edit-form',
    props: {
      promotion: {
        default: '',
      },
      buttons: {
        type: Boolean,
        default: true,
      },
      promotion_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: {
          id: null,
          location_id: null,
          promotion_space_id: null,
          company_id: null,
          name: '',
          start_date: null,
          end_date: null,
          progress_status: '',
          sales_target: null,
          description: '',
        },
        invalid: false,
        cal_attributes: [
          {
            key: 'today',
            highlight: {
              color: 'red',
              fillMode: 'light',
            },
            dates: new Date(),
          },
        ],
        cal_masks: {
          input: ["YYYY-MM-DD", "YYYY/MM/DD", "L"],
        },
      }
    },
    components: {

    },
    computed: {
      ...mapGetters([
        'get_promotion',
        'get_company_list',
        'get_location_list',
        'get_promotion_space_list',
      ]),
      current_promotion() {
        return this.get_promotion;
      },
      edit_perms() {
        return (this.current_promotion && this.current_promotion.id != this.form.id && this.promotion_list);
      },
      company_list() {
        return this.get_company_list;
      },
      location_list() {
        return this.get_location_list;
      },
      promotion_space_list() {
        let list = this.get_promotion_space_list;
        if (this.form.location_id) list = list.filter( ps => ps.location_id == this.form.location_id);
        return list;
      },
    },
    methods: {
      ...mapActions([
        'do_save_promotion',
        'do_fetch_promotion_space_list',
      ]),
      init_form() {
        if (this.promotion) {
          let promotion = JSON.parse(JSON.stringify(this.promotion));
          this.form.id = promotion.id;
          this.form.promotion_space_id = promotion.promotion_space_id;
          this.form.location_id = promotion.location_id;
          this.form.company_id = promotion.company_id;
          this.form.name = promotion.name;
          this.form.start_date = promotion.start_date;
          this.form.end_date = promotion.end_date;
          this.form.progress_status = promotion.progress_status;
          this.form.sales_target = promotion.sales_target;
          this.form.description = promotion.description;
        }
      },
      fetch_promotion_space_list() {
        // Fetches Location List & Promotion Space List
        this.do_fetch_promotion_space_list();
      },
      trigger_save() {
        // Trigger Validate & Submit
        this.$refs.promotion_form.trigger_submit();
      },
      save_promotion() {
        let $this = this;
        this.invalid = false;
        this.do_save_promotion({promotion: this.form, list: this.promotion_list})
          .then(function(promotion) {
            $this.$emit('saved', promotion);
          }).finally(function() {
            $this.$refs.promotion_form.disable_buttons(false);
            $this.$refs.promotion_form.set_form_loading(false);
          }).catch(function(err) {
            $this.$emit('failed', err);
          });
      },
      buttons_disabled(disabled) {
        this.$emit('buttons_disabled', disabled);
      },
      set_form_loading(form_loading = true) {
        this.$emit('form_loading', form_loading);
      },
      returnId(option) {
        return option.id;
      },
      change_location() {
        if (this.location_list == 0) return;
        let found = this.promotion_space_list.filter( ps => ps.id == this.promotion_space_id );
        if (found.length == 0) {
          this.$set(this.form, 'promotion_space_id', null);
        }
      },
    },
    mounted() {
      console.log('PromotionEditForm Mounted');
      if (!this.location_list || this.location_list.length == 0) {
        this.fetch_promotion_space_list();
      }
      this.init_form();
    },
    watch: {
      'promotion.id': function(new_val) {
        if (new_val != this.form.id) {
          this.init_form();
        }
      },
    },
  }
</script>
<template>
  <div>
    <bs-form ref="promotion_form" class="promotion" :submit_button="buttons ? 'submit' : false" :reset_button="false" @submit="save_promotion" @buttons_disabled="buttons_disabled" @form_loading="set_form_loading">

      <fieldset>
        <legend>{{$t('promotion.data')}}</legend>
        <bs-v-select id="company_id" :label="$t('company.name')" :placeholder="$t('promotion.select_company')"
          v-model="form.company_id" :options="company_list"
          :reduce="returnId" select_label="name" rules="select_required">
        </bs-v-select>
        <bs-input-text id="name" type="text" :label="$t('promotion.name')" :placeholder="$t('promotion.name')" v-model="form.name" rules="required"/>
        <bs-input-text id="description" type="text" :label="$t('promotion.description')" :placeholder="$t('promotion.description')" v-model="form.description"/>
        <bs-v-select id="location_id" :label="$t('promotion.location')" :placeholder="$t('promotion.select_location')"
          v-model="form.location_id" :options="location_list" @change="change_location"
          :reduce="returnId" select_label="name" rules="select_required">
        </bs-v-select>
        <bs-v-select id="promotion_space_id" :label="$t('promotion.space_name')" :placeholder="$t('promotion.select_space')"
          v-model="form.promotion_space_id" :options="promotion_space_list"
          :reduce="returnId" select_label="name" rules="select_required">
        </bs-v-select>

        <bs-v-date-picker id="start_date" mode="date" :label="$t('promotion.start_date')" :placeholder="$t('promotion.select_date')"
          v-model="form.start_date" timezone="Asia/Tokyo" rules="date_required" :attributes="cal_attributes" :masks="cal_masks">
        </bs-v-date-picker>
        <bs-v-date-picker id="end_date" mode="date" :label="$t('promotion.end_date')" :placeholder="$t('promotion.select_date')"
          v-model="form.end_date" timezone="Asia/Tokyo" rules="date_required" :attributes="cal_attributes" :masks="cal_masks">
        </bs-v-date-picker>
        <bs-input-text id="progress_status" type="text" :label="$t('promotion.progress_status')" :placeholder="$t('promotion.progress_status')" v-model="form.progress_status"/>
        <bs-input-text id="sales_target" type="text" :label="$t('promotion.sales_target')" :placeholder="$t('promotion.sales_target')" v-model="form.sales_target"/>
      </fieldset>
    </bs-form>
  </div>
</template>
