<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    components: {
    },
    computed: {
      ...mapGetters([
        'get_user',
      ]),
      user() {
        return this.get_user;
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_companies',
      ]),
    },
    mounted() {
      console.log('App mounted.');
      if (this.user) {
        this.do_fetch_companies();
      }
    },
  }

</script>
<template>
  <div class="wrapper">

    <router-view></router-view>

  </div>
</template>