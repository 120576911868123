<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import i18n from '../i18n.js'
  import InventoryEditModal from '../components/InventoryEditModal.vue'
  export default {
    name: 'inventory-list',
    components: {
      InventoryEditModal,
      'inventory-list-toolbar': {
        name: 'inventory-list-toolbar',
        template: `
          <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" @click="$parent.add_product()">{{$t('product.add_new')}} <i class="fas green fa-plus"></i></button>
              </div>
            </div>
            <div class="col-auto">
              <download-csv
                class="btn btn-outline-primary"
                :fields="$parent.csv_fields"
                :data="$parent.inventory_list"
                :name="$parent.current_promotion.name + ' Inventory.csv'">
                {{ $t('csv_export') }} <i class="fa fa-file-csv"></i>
              </download-csv>
            </div>
          </div>
        `,
      },
      'tbl-cell-product': {
        props: ['row'],
        template: `
          <div>
            <product-image :product="row" />
            <div class="d-inline-block">
              <div>{{ row.name }}</div>
              <div class="invoice-highlight">{{ row.unit_price | formatCurrency }} <span class="invoice-muted">{{ $t('sales.each') }}</span></div>
            </div>
          </div>
        `,
      },
      'tbl-cell-status': {
        props: ['row'],
        template: `
          <span>{{ $t('sales.' + row.sales_status)}}</span>
        `,
      },
      'tbl-cell-actions': {
        props: ['row'],
        template: `
          <div class="">
            <button type="button" class="btn btn-outline-primary" @click="$parent.$parent.$parent.$parent.edit_product(row)" v-tooltip:bottom="$t('product.edit')">
              <i class="fa fa-pencil-alt"></i>
            </button>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        saving: false,
        table_filter_val: '',
        request_timer_duration: 60000,
        request_timer: null,
        default_product: {
          id: null,
          name: '',
          description: '',
          image_url: '',
          product_image: '',
          product_image_type: '',
          unit_price: '',
          stock_quantity: '',
          date_available: '',
          sales_target_number: '',
        },
        editing_product: null,
      }
    },
    computed: {
      ...mapGetters([
        'get_promotion',
        'get_inventory_list',
      ]),
      current_promotion() {
        return this.get_promotion;
      },
      inventory_list() {
        return this.get_inventory_list;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'name',
              order: 'ascending'
            },
          },
          fields: [
            { label: i18n.t('product.name'), prop: 'name', sortable: true, searchable: true, min_width: '150px', component: 'tbl-cell-product' },
            { label: i18n.t('product.description'), prop: 'description', sortable: true, searchable: true, min_width: '150px' },
            { label: i18n.t('product.date_available'), prop: 'date_available', sortable: true, searchable: false, formatter: this.$options.filters.formatDate },
            { label: i18n.t('product.price'), prop: 'unit_price', sortable: true, searchable: false, formatter: this.$options.filters.formatCurrency },
            { label: i18n.t('product.total_stock'), prop: 'stock_quantity', sortable: true, searchable: false, formatter: this.$options.formatNumber },
            { label: i18n.t('product.units_sold'), prop: 'quantity_purchased', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
            { label: i18n.t('product.units_reserved'), prop: 'quantity_reserved', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
            { label: i18n.t('product.remaining_stock'), prop: 'stock_remaining', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
            { label: i18n.t('actions'), sortable: false, searchable: false, component: 'tbl-cell-actions', align: 'center' },
          ],
          filters: [
            {
              prop: ['name', 'description', 'date_available', 'unit_price'],
              value: this.table_filter_val,
            }
          ],
        }
      },
      csv_fields() {
        let fields = ['product_id'];
        for (let i = 0; i < this.table_config.fields.length; i++) {
          let field = this.table_config.fields[i];
          fields.push(field.prop);
        }
        return fields;
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_inventory_list',
      ]),
      fetch_inventory_list() {
        this.clear_request_timer();
        this.loading = true;
        let params = {
          promotion_id: this.current_promotion.id,
        }
        this.do_fetch_inventory_list(params).then(resp => {
          if (resp) {
            // console.log('Products', resp);
          }
        }).finally(() => {
          this.loading = false;
          this.set_request_timer();
        }).catch(err => {
          console.error('Error', err);
          this.$toast.error(this.$t('server_error'));
        });
      },
      clear_request_timer() {
        if (this.request_timer) {
          clearTimeout(this.request_timer);
          this.request_timer = null;
        }
      },
      set_request_timer() {
        let $this = this;
        this.request_timer = setTimeout(function(){ $this.fetch_inventory_list() }, this.request_timer_duration);
      },
      edit_product(product) {
        let def = JSON.parse(JSON.stringify(this.default_product));
        if (!this.editing_product || this.editing_product.id != product.id) {
          this.$set(this, 'editing_product', Object.assign(def, product));
        }
        this.$refs.inventory_edit_modal.open();
      },
      add_product() {
        this.$set(this, 'editing_product', JSON.parse(JSON.stringify(this.default_product)));
        this.$refs.inventory_edit_modal.open();
      },
      saved(resp) {
        // console.log('saved', resp);
        if (resp && resp.success) {
          this.$refs.inventory_edit_modal.close();
          this.$toast.success(this.$t('product.saved'));
          this.$set(this, 'editing_product', null);
        } else {
          this.$toast.error(this.$t('server_error'));
        }
      },
      failed(err) {
        console.log('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
    },
    mounted() {
      console.log('InventoryList mounted.');
      this.fetch_inventory_list();
    },
    beforeDestroy() {
      console.log('InventoryList Unmounted');
      this.clear_request_timer();
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 inventory-list-list-container" :class="{loading: loading}">

    <h3>{{ $t('inventory.management') }} <i class="fa fa-boxes"></i></h3>

    <inventory-list-toolbar />

    <div class="row mt-3 mb-3" v-if="inventory_list">

      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="inventory-list-list-table"
        id="inventory-list-list-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="inventory_list"
        :page-size="25"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
          :formatter="field.formatter"
          :className="field.className"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else-if="field.formatter && scope.row[field.prop]" :class="field.spanClass">{{ field.formatter(scope.row[field.prop]) }}</span>
            <span v-else :class="field.spanClass">{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </data-tables>

    </div>

    <inventory-list-toolbar />

    <inventory-edit-modal @saved="saved" @failed="failed" :product="editing_product" ref="inventory_edit_modal" />


  </div>
</template>
