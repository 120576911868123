<style lang="scss">
  @import '../variables.scss';
  .ar-qr-scanner {
    .card {
      background-color: $lightgray;
      border-radius: .75em;
      .col {
        cursor: pointer;
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import SalesScheduleList from '../components/SalesScheduleList.vue'
  import SalesHistoryList from '../components/SalesHistoryList.vue'
  export default {
    name: 'sales-management',
    title: 'sales.management',
    components: {
      SalesScheduleList,
      SalesHistoryList,
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
      ]),
      route_reservation_num() {
        return this.$route.params.reservation_num;
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
    },
    methods: {
      ...mapActions([

      ]),
      ...mapMutations([

      ]),
      open_qr_scanner() {
        this.$refs.qr_scanner.open();
      },
      validate_code(qr_url) {
        let url = window.location.origin + '/#/mobile/order/';
        let valid = qr_url.startsWith(url);
        if (valid) {
          qr_url = qr_url.replace(url, '');
          let parts = qr_url.split('/');
          valid = (parts[1] == this.promotion.id); // Verify we have the same Promotion ID
        }
        return valid;
      },
      scanned_qr(qr_url) {
        let $this = this;
        let url = window.location.origin + '/#/mobile/order/';
        qr_url = qr_url.replace(url, '');
        let parts = qr_url.split('/');
        this.$refs.qr_scanner.close();
        this.$nextTick(() => {
          $this.$router.push({ name: 'sales.management', params: { reservation_num: parts[2] } });
        })
      },
    },
    mounted() {
      console.log('SalesManagement mounted.');
      
    },
    beforeDestroy() {
      console.log('SalesManagement Unmounted');
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-1" v-show="!route_reservation_num">
    <div class="row justify-content-center">

      <div class="container-fluid sales-management-container" v-if="promotion">

        <div class="row justify-content-center ar-qr-scanner">
          <div class="col-8 col-sm-6 col-md-4">
            <div class="card m-3">
              <div class="card-body container-fluid">
                <div class="row">
                  <div class="col p-0 d-flex align-items-center justify-content-center" @click.stop="open_qr_scanner">
                    <h4 class="m-3 text-center">{{ $t('qr.scan_qr_code') }}</h4>
                  </div>
                  <div class="col p-0">
                    <qr-scanner ref="qr_scanner" @scanned="scanned_qr" :validate="validate_code" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <sales-schedule-list />
        </div>

        <div>
          <sales-history-list />
        </div>



      </div>


    </div>
  </div>
</template>
