<script>
import { ValidationObserver, extend, localize } from "vee-validate";
import { required, email, min, max, max_value, confirmed } from 'vee-validate/dist/rules';
import i18n from '../i18n.js'

import en from '../locales/en-validate.json';
import ja from '../locales/ja-validate.json';
localize({
  en,
  ja
});

extend('image_required', required);
extend('radio_required', required);
extend('select_required', required);
extend('date_required', required);
extend('required', required);
extend('email', email);
extend('min', min);
extend('max', max);
extend('max_value', max_value);
extend('max_quantity', max_value);
extend("exact_length", {
  params: ["length", "message"],
  validate(val, { length, message }) {
    if (val.length !== Number(length))
      return message ?? "Please select " + length + " option" + (length > 1 ? 's' : '');
    return true;
  }
});
extend('positive', value => {
  if (value >= 0) {
    return true;
  }
  return i18n.t('validate.positive');
});
extend('confirmed', confirmed);
extend('password_match', confirmed);

import { mapGetters } from 'vuex'
export default {
  components: {
    ValidationObserver,
  },
  props: {
    // vid: {
    //   type: String
    // },
    // rules: {
    //   type: [Object, String],
    //   default: ""
    // },
    reset_button: {
      default: 'reset',
    },
    submit_button: {
      default: 'submit',
    },
    formClass: {
      type: String,
      default: 'row g-3',
    },
  },
  data() {
    return {
      buttons_disabled: false,
      form_loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'get_language',
    ]),
    current_language() {
      return this.get_language;
    },
    observer() {
      return this.$refs.form_observer;
    },
    errors() {
      if (!this.$refs.form_observer) return {};
      return this.$refs.form_observer.errors;
    },
  },
  methods: {
    set_language() {
      localize(this.current_language);
    },
    trigger_submit() {
      this.observer.handleSubmit(this.submit_form);
    },
    submit_form(values) {
      this.disable_buttons();
      this.set_form_loading();
      this.$emit('submit', values);
    },
    reset_form() {
      console.log('Form Reset');
      this.$emit('reset');
    },
    disable_buttons(disable = true) {
      this.$set(this, 'buttons_disabled', disable);
      this.$emit('buttons_disabled', disable);
    },
    set_form_loading(loading = true) {
      this.$set(this, 'form_loading', loading);
      this.$emit('form_loading', loading);
    },
  },
  mounted() {
    let $this = this;
    this.set_language();
    // Add Observer Watcher after Init
    this.$nextTick(() => {
      if ($this.$refs.form_observer) {
        $this.$watch(
          () => {
            let errs = Object.values($this.$refs.form_observer.errors);
            let invalid = false;
            errs.map( (e) => {
              if (e.length > 0) invalid = true;
            });
            return invalid;
          },
          (invalid) => {
            $this.disable_buttons(invalid);
          }
        );
      }
    });
  },
  watch: {
    current_language() {
      this.set_language();
    },
  }
};
</script>
<template>
  <ValidationObserver ref="form_observer" v-slot="{ handleSubmit }" :slim="true">
    <form ref="form" :class="formClass" novalidate @submit.prevent="handleSubmit(submit_form)" @reset="reset_form">
      <slot />

      <div class="col-12">
        <button class="btn btn-primary" :disabled="buttons_disabled" type="submit" v-if="!$slots['buttons'] && submit_button !== false">{{ $t(submit_button) }}<i class="fa fa-spinner fa-spin ms-2" v-if="form_loading"></i></button>
        <button class="btn btn-danger" :disabled="buttons_disabled" type="reset" v-if="!$slots['buttons'] && reset_button !== false">{{ $t(reset_button) }}</button>
        <slot name="buttons" />
      </div>
    </form>
  </ValidationObserver>
</template>