<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import PromotionList from '../components/PromotionList.vue';
  import CompanyList from '../components/CompanyList.vue';
  export default {
    name: 'admin-dashboard',
    title: 'dashboard.admin_dashboard',
    components: {
      PromotionList,
      CompanyList,
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'get_user',
      ]),
      active_route() {
        return this.$router.currentRoute;
      },
    },
    methods: {
      ...mapActions([

      ]),
      ...mapMutations([

      ]),

    },
    mounted() {
      console.log('AdminDashboard mounted.');
      
    },
    beforeDestroy() {

      console.log('AdminDashboard Unmounted');
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-1">
    <div class="row justify-content-center">

      <div class="admin-dashboard-container">

        <div>
          <company-list/>
        </div>

        <div>
          <promotion-list/>
        </div>

      </div>



    </div>
  </div>
</template>
