<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import QrCode from '../cejGeneric/QrCode.vue';
  import ProductList from '../components/ProductList.vue';
  import CountdownTimer from '../cejGeneric/CountdownTimer.vue';
  export default {
    name: 'mobile-order',
    title: 'sales.booking_confirmation',
    components: {
      QrCode,
      ProductList,
      CountdownTimer,
    },
    data(){
      return {
        save_pending: false,
        buttons_disabled: false,
        saving: false,
      }
    },
    computed: {
      ...mapGetters([
        'get_language',
        'get_sale',
      ]),
      route_company_id() {
        return this.$route.params.company_id;
      },
      route_promotion_id() {
        return this.$route.params.promotion_id;
      },
      route_reservation_num() {
        return this.$route.params.reservation_num;
      },
      sale() {
        return this.get_sale;
      },
      plist() {
        return this.$refs.product_list;
      },
      qr_data() {
        if (!this.sale) return null;
        let path = this.$router.resolve({ name: 'mobile.order', params: {company_id: this.route_company_id, promotion_id: this.route_promotion_id, reservation_num: this.sale.reservation_number} }).href;
        return window.location.origin + "/" + path;
      },
    },
    methods: {
      ...mapActions([
      ]),
      ...mapMutations([
        'update_sale',
      ]),
      products_selected(products_info) {
        if (this.plist.initialized) {
          this.$set(this, 'save_pending', (products_info.num_items > 0));
        }
      },
      disable_buttons(disabled) {
        this.buttons_disabled = disabled;
      },
      save_reservation() {
        let $this = this;
        this.buttons_disabled = true;
        this.saving = true;
        this.plist.save_sale('update-quantities').then(() => {
          $this.save_pending = false;
        }).finally(function() {
          $this.buttons_disabled = false;
          $this.saving = false;
        }).catch(err => {
          console.log('save_reservation Error', err);
        });

      },
      print_reservation() {
        console.log('Print Reservation');
      },
    },
    mounted() {
      console.log('MobileOrder mounted.');
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 mobile-order-container mt-sm-2 mb-sm-2">
    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-8 col-xl-6 col-xxl-5">

        <div class="card">
          <div class="card-header p-2">
            <div class="container-fluid ps-1">
              <div class="row">
                <div class="col-12">
                  <h3>{{ $t('sales.reservation_num') }}: {{ route_reservation_num }}</h3>
                </div>
                <div class="col-12" v-if="sale && !saving">
                  <div v-if="sale.sales_status == 'purchased'">
                    <div class="invoice-highlight">
                      {{ $t('sales.purchased_at') }}: {{ sale.purchased_at | formatDatetime }}
                    </div>
                  </div>
                  <div v-else-if="sale.sales_status == 'canceled'">
                    <div class="invoice-highlight">
                      {{ $t('sales.canceled') }}
                    </div>
                  </div>
                  <div v-else>
                    <countdown-timer :expires_at="sale.expires_at" timer_label="sales.remaining_time" timer_expired="sales.expired" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div>
              <product-list ref="product_list" heading="sales.booking_confirmation" mode="customer-invoice" @products_selected="products_selected" @buttons_disabled="disable_buttons" />
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <button class="btn btn-primary m-1" :disabled="buttons_disabled" @click="save_reservation" v-if="save_pending">{{ $t('sales.save_reservation') }}<i class="fa fa-save ms-2"></i><i class="fa fa-spinner fa-spin ms-2" v-if="saving"></i></button>
                <div class="alert alert-warning mt-3" role="alert" v-if="save_pending">
                  {{ $t('sales.save_pending') }}
                </div>
              </div>
              <div class="col-12 text-center">
                <qr-code :qr-data="qr_data" v-if="qr_data" width="150" height="150" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>