<script>
  import { mapGetters } from 'vuex';
  import { bus } from '../main';
  export default {
    name: 'location-switcher-button',
    components: {
    },
    props: {
      linkClass: {
        default: 'nav-item'
      },
      dark: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_promotion',
      ]),
      user() {
        return this.get_user;
      },
      promotion() {
        return this.get_promotion;
      },
    },
    methods: {
      show_locations() {
        this.$nextTick(function () {
          bus.$emit('location_switcher_open');
        });
      },
    },
    mounted() {
    },
    created(){

    },
  }
</script>
<template>
  <li :class="linkClass" v-if="user">
    <a class="nav-link" href="javascript://" id="locationSwitcherButton" role="button" @click="show_locations">
      <span v-if="!promotion">{{ $t('promotion.select') }}</span>
      <span v-if="promotion">{{ promotion.name }} - {{ promotion.space_name }}</span>
      &nbsp;<i class="fa fa-calendar-week"></i>
    </a>
  </li>
</template>
