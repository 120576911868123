<style lang="scss">

</style>
<script>
  export default {
    name: 'mobile-section',
    mounted() {
      console.log('MobileSection mounted.');
    }
  }

</script>
<template>
  <div class="section-wrapper">
    <router-view></router-view>
  </div>
</template>