<script>
  export default {
    title: '404_not_found'
  }

</script>
<template>
  <div class="wrapper">
    <top-navbar></top-navbar>
    <menu-navbar></menu-navbar>

    <div class="main-panel">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-md-offset-2 text-center mt-5 mb-5">
            <h2 class="title text-danger">{{ $t('404_not_found') }}</h2>
            <h2 class="title">{{ $t('404_page_not_exists') }}</h2>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>

