
<style lang="scss">

</style>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'user-section',
    computed: {
      ...mapGetters([
        'get_user',
      ]),
      active_route() {
        return this.$route.name;
      },
      user() {
        return this.get_user;
      },
    },
    mounted() {
      console.log('UserSection mounted.');
    },
  }

</script>
<template>
  <div class="section-wrapper">
    <!--ul class="nav nav-tabs mt-3">
      <li class="nav-item">
        <router-link class="nav-link" :class="{ active: active_route == 'user.profile' }" :to="{ 'name': 'user.profile' }">{{ $t('usr.my_profile') }}</router-link>
      </li>
      <li class="nav-item" v-if="user && user.is_admin">
        <router-link class="nav-link" :class="{ active: active_route == 'admin.user-list' }" :to="{ 'name': 'admin.user-list' }">{{ $t('usr.user_list') }}</router-link>
      </li>
    </ul-->
    <router-view></router-view>
  </div>
</template>