<style>

</style>
<script>
  import LanguageSwitcher from '../cejGeneric/LanguageSwitcher.vue'
  export default {
  components: {
    LanguageSwitcher,
  },
}

</script>
<template>
  <footer class="footer">
    <div class="container-fluid justify-content-center">
      <ul class="list-inline text-center">
        <li class="list-inline-item copyright">&copy; 2021 {{ $t('copyright_company') }}</li>
        <language-switcher list-class="list-inline-item dropdown" :dark="false" />
      </ul>
    </div>
  </footer>
</template>
