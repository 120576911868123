<script>
export default {
  components: {
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    css_class: {
      type: String,
      default: 'invalid-feedback',
    },
  },
};
</script>
<template>
  <div :class="css_class">
    <slot/>
  </div>
</template>