// import store from '../store';
import axios from 'axios';

axios.interceptors.response.use(undefined, function (error) {
  if (error.response) {
    // Request made and server responded
    console.error('Axios HTTP ' + error.response.status, error.message);
    console.error('Error Data', error.response.data);
    if (error.response.status == 401) {
      if (window.location.hash != '#/pub/login') {
        window.location.href = '#/pub/logout';
      }
    } else if (error.response.status == 419) {
      window.location.reload();
    }
    return error.response;
  } else if (error.request) {
     // The request was made but no response was received
    console.error('Axios Error', error.request);
  } else {
     // Something happened in setting up the request that triggered an Error
    console.error('Axios HTTP', error.message);
  }
  return Promise.reject(error);
});

export default {
  // Auth
  async login(request) {
    const { data } = await axios.post(`/auth/login`, request);
    return data;
  },
  async auth_verify(request) {
    const { data } = await axios.post(`/auth/verify`, request);
    return data;
  },
  async send_reset_password_email(request) {
    const { data } = await axios.post(`/auth/send-reset-password`, request);
    return data;
  },
  async reset_password(request) {
    const { data } = await axios.post(`/auth/reset-password`, request);
    return data;
  },

  // Users
  async fetch_users() {
    const { data } = await axios.get(`/user/list`);
    return data;
  },
  async save_user(request) {
    const { data } = await axios.post(`/user/save`, request);
    return data;
  },

  // Companies
  async fetch_companies(request) {
    const { data } = await axios.get(`/company/list`, request);
    return data;
  },
  async save_company(request) {
    const { data } = await axios.post(`/company/save`, request);
    return data;
  },

  // Locations / Promotions
  async fetch_promotions(request) {
    const { data } = await axios.get(`/promotion/list`, request);
    return data;
  },
  async save_promotion(request) {
    const { data } = await axios.post(`/promotion/save`, request);
    return data;
  },
  async fetch_promotion_spaces(request) {
    const { data } = await axios.get(`/promotion/spaces-list`, request);
    return data;
  },
  async save_location(request) {
    const { data } = await axios.post(`/promotion/save-location`, request);
    return data;
  },
  async save_promotion_space(request) {
    const { data } = await axios.post(`/promotion/save-promotion-space`, request);
    return data;
  },

  // Products
  async fetch_products(request) {
    const { data } = await axios.post(`/product/list`, request);
    return data;
  },
  async save_product(request) {
    const { data } = await axios.post(`/product/save`, request);
    return data;
  },

  // Sales
  async fetch_sales(request) {
    let endpoint = `/sales/list`;
    if (request.sales_status) {
      endpoint += `/${request.sales_status}`;
    }
    const { data } = await axios.post(endpoint, request);
    return data;
  },
  async save_sale(request) {
    const { data } = await axios.post(`/sales/save`, request);
    return data;
  },
  async save_sale_quantities(request) {
    const { data } = await axios.post(`/sales/save-quantities`, request);
    return data;
  },
  async fetch_sale(request) {
    let endpoint = `/sales/reservation`;
    if (request.promotion_id) {
      endpoint += `/${request.promotion_id}`;
    }
    if (request.reservation_num) {
      endpoint += `/${request.reservation_num}`;
    }
    const { data } = await axios.get(endpoint, request);
    return data;
  },
  async fetch_exhibitor_sales_progress(request) {
    const { data } = await axios.post(`/sales/exhibitor/progress`, request);
    return data;
  },
  async fetch_exhibitor_sales_analysis(request) {
    const { data } = await axios.post(`/sales/exhibitor/analysis`, request);
    return data;
  },

}
