<script>
import { Popover } from 'bootstrap'
export default {
  name: 'bs-popover',
  props: {
    target: {
      required: true,
    },
    content: {
      required: false,
      default: '',
    },
    title: {
      default: 'My Popover',
    },
    trigger: {
      default: 'click',
    },
    delay: {
      default: 0,
    },
    placement: {
      default: 'right',
    },
    template: {
      type: String,
      default: `
        <div class="popover cej-popover" role="tooltip">
          <div class="popover-arrow"></div>
          <h3 class="popover-header text-dark"></h3>
          <div class="popover-body"></div>
        </div>
      `,
    }
  },
  data() {
    return {
      popover: null,
    }
  },
  mounted() {
    this.create();
  },
  methods: {
    create() {
      // pass bootstrap popover options from props
      var options = JSON.parse(JSON.stringify(this.$props));
      let target_elm = document.getElementById(this.target);
      let content_elm = this.$slots.default[0].elm;
      if (!options.content) {
        options.html = true;
        options.content = function() {
          return content_elm;
        }
      }
      this.popover = new Popover(target_elm, options);
    },
  },
  beforeDestroy() {
    if (this.popover) {
      this.popover.dispose();
      this.popover = null;
    }
  },
  watch: {
    target() {
      if (this.popover && this.popover.tip) {
        let elm = this.popover.tip.getElementsByClassName("popover-header")[0];
        elm.textContent = this.title;
      }
    },
  }
}

</script>
<template>
  <div class="d-none">
    <slot/>
  </div>
</template>
