<style lang="scss">
  @import '../variables';
  .product-list-container {
    .invoice-highlight {
      color: $red;
    }
    .invoice-muted {
      font-size: 0.8em;
    }
    #product-list-table {
      .el-table {
        .cell {
          word-break: break-word;
          .select-quantity {
            max-width: 120px;
            min-width: 50px;
            .vs__search, .vs__search:focus {
              padding: 0;
            }
          }
        }
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import i18n from '../i18n';
  export default {
    name: 'product-list',
    components: {
      'tbl-cell-product': {
        props: ['row'],
        template: `
          <div>
            <product-image :product="row" />
            <div class="d-inline-block">
              <div>{{ row.name }}</div>
              <div class="invoice-highlight">{{ row.unit_price | formatCurrency }} <span class="invoice-muted">{{ $t('sales.each') }}</span></div>
            </div>
          </div>
        `,
      },
      'tbl-cell-quantity': {
        props: ['row'],
        computed: {
          quantity_options() {
            return [
              {id: 0, label: '0'},
              {id: 1, label: '1'},
              {id: 2, label: '2'},
              {id: 3, label: "3"},
              {id: 4, label: "4"},
              {id: 5, label: "5"},
              {id: 6, label: "6"},
              {id: 7, label: "7"},
              {id: 8, label: "8"},
              {id: 9, label: "9"},
              {id: 10, label: "10"},
            ];
          },
          product_list() {
            return this.$parent.$parent.$parent.$parent.$parent.$parent;
          },
        },
        methods: {
          returnId(option) {
            return option.id;
          },
        },
        template: `
          <div>
            <div v-if="product_list.sale_complete">
              <span class="invoice-highlight">{{product_list.form.quantity[row.product_id]}}</span>
            </div>
            <div v-else>
              <bs-v-select :id="'product_quantity_' + row.product_id" :name="'product_quantity_' + row.product_id" label=""
                v-model="product_list.form.quantity[row.product_id]" :options="quantity_options" select_label="label"
                :reduce="returnId" :appendToBody="true" select-class="select-quantity" :rules="'max_quantity:' + row.stock_remaining">
              </bs-v-select>
            </div>
          </div>
        `,
      },
    },
    props: {
      heading: {
        type: String,
        default: 'sales.selection',
      },
      mode: {
        type: String,
        default: 'shop',
      },
    },
    data() {
      return {
        initialized: false,
        loading_list: false,
        loading_sale: false,
        saving: false,
        table_filter_val: '',
        form: {
          id: null,
          quantity: {},
          stock: {},
        },
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
        'get_sale',
        'get_product_list',
      ]),
      route_company_id() {
        return this.$route.params.company_id;
      },
      route_promotion_id() {
        return this.$route.params.promotion_id;
      },
      route_reservation_num() {
        return this.$route.params.reservation_num;
      },
      active_route() {
        return this.$router.currentRoute;
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
      sale() {
        return this.get_sale;
      },
      sale_product_ids() {
        if (!this.sale) return [];
        let ids = this.sale.products.map( (sp) => sp.product_id );
        return ids;
      },
      product_list() {
        return this.get_product_list;
      },
      table_config() {
        let fields = [];
        fields.push({ label: i18n.t('product.name'), prop: 'name', sortable: true, searchable: true, min_width: (this.mode == 'customer-invoice' ? '125px' : '200px'), component: 'tbl-cell-product' });
        if (this.mode == 'shop') {
          fields.push({ label: i18n.t('sales.remaining_stock'), prop: 'stock_remaining', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber });
        }
        fields.push({ label: i18n.t('sales.quantity'), prop: 'quantity', sortable: true, searchable: false, min_width: '100px', component: 'tbl-cell-quantity' });
        fields.push({ label: i18n.t('sales.subtotal'), prop: 'subtotal', sortable: true, searchable: true, min_width: '100px', formatter: this.$options.filters.formatCurrency, spanClass: 'invoice-highlight' });
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'name',
              order: 'ascending'
            },
          },
          fields: fields,
          filters: [
            {
              prop: ['name', 'description', 'unit_price'],
              value: this.table_filter_val,
            }
          ],
          pagination_props: {
            background: false,
            pageSizes: [5, 10, 25, 50, 100, 999],
            disabled: this.mode == 'shop' ? false : true,
            layout: this.mode == 'shop' ? 'prev, pager, next, jumper, sizes, total' : '',
          },
        }
      },
      products_info() {
        let list = [];
        let mode = this.mode;
        let sale_product_ids = this.sale_product_ids;
        let products = [...this.product_list];
        if (mode == 'customer-invoice' && this.sale) {
          products = JSON.parse(JSON.stringify(this.sale.products));
        }
        let num_products = 0; // Unique # of Products
        let num_items = 0; // Total Items
        let total_cost = 0;
        if (products && (mode == 'shop' || sale_product_ids.length > 0)) {
          if (mode != 'shop') products = products.filter( (item) => sale_product_ids.includes(item.product_id) );
          if (products.length) {
            list = products.map(item => {
              item.quantity = 0;
              if (this.form.quantity[item.product_id]) {
                item.quantity = parseInt(this.form.quantity[item.product_id]);
                num_products++;
                num_items = num_items + item.quantity;
              }
              item.subtotal = item.quantity * item.unit_price;
              if (item.subtotal) total_cost = total_cost + item.subtotal;
              if (this.form.stock[item.product_id]) {
                item.stock_remaining = this.form.stock[item.product_id];
              }
              return item;
            });
          }
        }
        return {list, num_items, num_products, total_cost};
      },
      sale_complete() {
        if (!this.sale) return false;
        return (['canceled', 'expired', 'purchased'].includes(this.sale.sales_status));
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_product_list',
        'do_fetch_sale',
        'do_save_sale',
      ]),
      init_form(skip_quantity = false) {
        if (this.loading_list || this.loading_sale) return;
        let sale = this.sale;
        let list = this.product_list;
        let mode = this.mode;
        if (mode == 'customer-invoice' && sale) {
          list = sale.products;
        }
        this.$set(this.form, 'id', sale ? sale.id : null);
        if (!skip_quantity) this.$set(this.form, 'quantity', {});
        this.$set(this.form, 'stock', {});
        let def = '0';
        list.forEach(item => {
          let sale_product = null;
          let q = def;
          let a = 0;
          if (sale) {
            sale_product = sale.products.filter( (sp) => sp.product_id == item.product_id );
            if (sale_product) sale_product = sale_product[0];
          }
          if (sale_product) {
            q = sale_product.quantity;
            a = parseInt(sale_product.stock_remaining);
          }
          if (!skip_quantity) this.$set(this.form.quantity, item.product_id, q);
          this.$set(this.form.stock, item.product_id, a);
        });
        this.$nextTick(() => {
          this.$set(this, 'initialized', true);
        });
      },
      fetch_product_list() {
        if (this.mode == 'customer-invoice') return;
        let $this = this;
        this.loading_list = true;
        let request = {
          company_id: this.company ? this.company.id : this.route_company_id,
          promotion_id: this.promotion ? this.promotion.id : this.route_promotion_id,
        };
        this.do_fetch_product_list(request)
          .then( () => {
            $this.$set($this, 'loading_list', false);
            $this.init_form();
          }).catch(err => {
            $this.$set($this, 'loading_list', false);
            console.error('fetch_product err', err);
            $this.$toast.error($this.$t('server_error'));
          });
      },
      fetch_sale() {
        let $this = this;
        this.loading_sale = true;
        let request = {
          promotion_id: this.promotion ? this.promotion.id : this.route_promotion_id,
          reservation_num: this.route_reservation_num,
        };
        if (!this.route_reservation_num) return;
        this.do_fetch_sale(request)
          .then( () => {
            $this.$set($this, 'loading_sale', false);
            $this.init_form();
          }).catch(err => {
            $this.$set($this, 'loading_sale', false);
            console.error('fetch_sale err', err);
            $this.$toast.error($this.$t('server_error'));
          });
      },
      save_sale(action) {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line no-unused-vars
          let quantity = Object.fromEntries( Object.entries(this.form.quantity).filter( ([key,value]) => { return (parseInt(value) > 0); } ) );
          let request = {
            action: action,
            promotion_id: this.promotion ? this.promotion.id : this.route_promotion_id,
            sale: Object.assign({}, this.form),
            sale_id: this.form.id,
          };
          request.sale.quantity = quantity;
          this.saving = true;
          this.do_save_sale(request).then(resp => {
            if (resp.success) {
              // console.log('Sale Saved', resp);
              if (request.action == 'purchase') {
                this.$toast.success(this.$t('sales.reservation_completed'));
              } else {
                this.$toast.success(this.$t('sales.reservation_saved'));
              }
              resolve(resp);
            } else {
              if (resp.quantity_errors && resp.quantity_errors.length > 0) {
                this.$toast.error(this.$t('sales.quantity_unavailable'));
                this.init_form(true);
              } else {
                this.$toast.error(this.$t('server_error'));
              }
              reject(resp);
            }
          }).finally(() => {
            this.saving = false;
          }).catch(err => {
            reject(err);
          });
        });
      },
      cancel_sale() {
        return new Promise((resolve, reject) => {
          if (this.form.id && !['canceled', 'expired', 'purchased'].includes(this.sale.sales_status)) {
            let request = {
              action: 'cancel',
              promotion_id: this.promotion ? this.promotion.id : this.route_promotion_id,
              sale: Object.assign({}, this.form),
              sale_id: this.form.id,
            };
            this.saving = true;
            this.do_save_sale(request).then(resp => {
              if (resp.success) {
                this.$toast.success(this.$t('sales.canceled'));
                resolve(resp);
              } else {
                this.$toast.error(this.$t('server_error'));
                reject(resp);
              }
            }).finally(() => {
              this.saving = false;
            }).catch(err => {
              reject(err);
            });
          } else {
            this.init_form();
            resolve();
          }
        });
      },
    },
    mounted() {
      console.log('ProductList mounted.', this.product_list);
      if (this.route_reservation_num && (!this.sale || this.sale.reservation_number != this.route_reservation_num)) {
        this.fetch_sale();
      }
      if (!this.product_list || this.product_list.length == 0) {
        this.fetch_product_list();
      } else {
        this.init_form();
      }
    },
    beforeDestroy() {
      console.log('ProductList Unmounted');
    },
    watch: {
      promotion() {
        this.fetch_product_list();
      },
      route_reservation_num() {
        this.fetch_sale();
      },
      form: {
        deep: true,
        handler() {
          if (Object.keys(this.form.quantity).length > 0) {
            this.$emit('products_selected', this.products_info);
          }
        },
      },
    },
  }
</script>
<template>
  <div class="container-fluid product-list-container ps-3 pe-3" :class="{ loading: loading_list || loading_sale }">

    <div class="row mt-3 mb-3" v-if="products_info.list">

      <div class="col-12 col-sm-6">
        <h3>{{ $t(heading) }}</h3>
      </div>
      <div class="col-12 col-sm-6" v-if="mode != 'customer-invoice'">
        <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val" class="no-print">
          <template v-slot:prepend>
            <i class="fa fa-search"></i>
          </template>
        </bs-input-text>
      </div>

      <div class="col-12 col-sm-6" v-if="sale && mode != 'customer-invoice'">
        <h5>{{ $t('sales.reservation_number') }}: {{ route_reservation_num }}</h5>
      </div>
      <div class="col-12 col-sm-6 text-end" v-if="sale && !saving && mode != 'customer-invoice'">
        <div v-if="sale.sales_status == 'purchased'">
          <div class="invoice-highlight">
            {{ $t('sales.purchased_at') }}: {{ sale.purchased_at | formatDatetime }}
          </div>
        </div>
        <div v-else-if="sale.sales_status !== 'canceled'">
          <div class="no-print"><countdown-timer :expires_at="sale.expires_at" timer_label="sales.remaining_time" timer_expired="sales.expired" /></div>
          <div class="only-print invoice-highlight">
            {{ $t('sales.expires_at') }}: {{ sale.expires_at | formatDatetime }}
          </div>
        </div>
      </div>

      <div class="col-12" :class="{ 'p-0': mode == 'customer-invoice' }">
        <bs-form ref="form" :submit_button="false" :reset_button="false" v-on="$listeners">
          <data-tables
            ref="product-list-table"
            id="product-list-table"
            :table-props="table_config.options"
            :fields="table_config.fields"
            :data="products_info.list"
            :page-size="this.mode == 'shop' ? 5 : 999"
            :pagination-props="table_config.pagination_props"
            :filters="table_config.filters"
          >
            <el-table-column v-for="field in table_config.fields"
              :label="field.label"
              :prop="field.prop"
              :key="field.prop"
              :sortable="field.sortable ? 'custom' : false"
              :sort-by="field.sort_by ? field.sort_by : field.prop"
              :align="field.align"
              :min-width="field.min_width ? field.min_width : null"
              :formatter="field.formatter"
              :className="field.className"
            >
              <template slot-scope="scope">
                <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
                <span v-else-if="field.formatter && scope.row[field.prop]" :class="field.spanClass">{{ field.formatter(scope.row[field.prop]) }}</span>
                <span v-else :class="field.spanClass">{{ scope.row[field.prop] }}</span>
              </template>
            </el-table-column>
          </data-tables>
        </bs-form>
      </div>

      <div class="col-6 fs-3 mt-3 mb-3">
        {{ $t('sales.items_selected') }}: <span class="invoice-highlight">{{ products_info.num_items | formatNumber }}</span>
      </div>
      <div class="col-6 text-end fs-3 mt-3 mb-3">
        {{ $t('sales.total_cost') }}: <span class="invoice-highlight">{{ products_info.total_cost | formatCurrency }} <span class="invoice-muted text-nowrap">{{ $t('sales.tax_included') }}</span></span>
      </div>
    </div>

  </div>
</template>
