<style lang="scss">
  .location-edit-form {
    .cej-input-image {
      .preview-container {
        .picture-inner {
          height: 85% !important;
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
//  import i18n from '../i18n.js'
  export default {
    name: 'location-edit-form',
    props: {
      location: {
        default: '',
      },
      buttons: {
        type: Boolean,
        default: true,
      },
      location_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form_loading: true,
        form: {
          id: null,
          name: '',
          description: '',
        },
        invalid: false,
      }
    },
    components: {

    },
    computed: {
      ...mapGetters([

      ]),
    },
    methods: {
      ...mapActions([
        'do_save_location',
      ]),
      init_form() {
        if (this.location) {
          let location = JSON.parse(JSON.stringify(this.location));
          this.form.id = location.id;
          this.form.name = location.name;
          this.form.description = location.description;
        }
      },
      trigger_save() {
        // Trigger Validate & Submit
        this.$refs.location_form.trigger_submit();
      },
      save_location() {
        let $this = this;
        this.do_save_location({location: this.form, list: this.location_list})
          .then(function(location) {
            $this.$emit('saved', location);
          }).finally(function() {
            $this.$refs.location_form.disable_buttons(false);
            $this.$refs.location_form.set_form_loading(false);
          }).catch(function(err) {
            $this.$emit('failed', err);
          });
      },
      buttons_disabled(disabled) {
        this.$emit('buttons_disabled', disabled);
      },
      set_form_loading(form_loading = true) {
        this.$emit('form_loading', form_loading);
      },
      form_is_loading(form_loading = true) {
        this.$set(this, 'form_loading', form_loading);
      },
      returnId(option) {
        return option.id;
      },
    },
    mounted() {
      console.log('LocationEditForm Mounted');
      this.init_form();
    },
    watch: {
      'location.id': function(new_val) {
        if (new_val != this.form.id) {
          this.init_form();
        }
      },
    },
  }
</script>
<template>
  <div>
    <bs-form ref="location_form" class="location-edit-form" :submit_button="buttons ? 'submit' : false" :reset_button="false" @submit="save_location" @buttons_disabled="buttons_disabled" @form_loading="set_form_loading">

      <fieldset>
        <legend>{{$t('location.data')}}</legend>
        <bs-input-text id="location_name" type="text" :label="$t('location.name')" :placeholder="$t('location.name')" v-model="form.name" rules="required" autocomplete="location-name" />
        <bs-input-text id="location_description" type="text" :label="$t('location.description')" :placeholder="$t('location.description')" v-model="form.description" />
      </fieldset>
    </bs-form>
  </div>
</template>
