import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
// import moment from 'moment-timezone'
import api from "./services/http"
import i18n, { browserLanguage, setElementUiLang } from './i18n.js'
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  state: {
    // locale
    language: null,

    // auth
    auth_status: '',

    // Users
    user: null,
    user_list: [],

    // Companies
    company: null,
    company_list: [],

    // Promotions
    promotion: null,
    promotion_list: [],
    location_list: [],
    promotion_space_list: [],

    // Products
    product_list: [],
    inventory_list: [],

    // Sales
    sales_schedule: [],
    sale: null,
    exhibitor_sales_progress: {},
    exhibitor_sales_analysis: {},

  },
  getters: {
    // Locale
    get_language(state) {
      let store = state.language;
      let local = localStorage.getItem('language');
      if (store) return store;
      if (local !== undefined && local !== null) return local;
      return browserLanguage;
    },


    // Auth
    is_logged_in(state) {
      return (state.user && state.user.id ? true : false);
    },
    get_user_role(state) {
      if (!state.user) return false;
      return state.user.role;
    },
    get_auth_status(state) {
      return state.auth_status;
    },

    // Users
    get_user(state) {
      return state.user;
    },
    get_user_list(state) {
      return state.user_list;
    },

    // Companies
    get_company(state) {
      return state.company;
    },
    get_company_list(state) {
      return state.company_list;
    },

    // Locations / Promotions
    get_promotion(state) {
      return state.promotion;
    },
    get_promotion_list(state) {
      return state.promotion_list;
    },
    get_location_list(state) {
      return state.location_list;
    },
    get_promotion_space_list(state) {
      return state.promotion_space_list;
    },

    // Products
    get_product_list(state) {
      return state.product_list;
    },
    get_inventory_list(state) {
      return state.inventory_list;
    },

    // Sales
    get_sales_schedule(state) {
      return state.sales_schedule;
    },
    get_sale(state) {
      return state.sale;
    },
    get_exhibitor_sales_progress(state) {
      if (!state.promotion) return [];
      let promotion_id = state.promotion.id;
      if (!state.exhibitor_sales_progress[promotion_id]) return [];
      return state.exhibitor_sales_progress[promotion_id];
    },
    get_exhibitor_sales_analysis(state) {
      if (!state.promotion) return {};
      let promotion_id = state.promotion.id;
      if (!state.exhibitor_sales_analysis[promotion_id]) return {};
      return state.exhibitor_sales_analysis[promotion_id];
    },


  },
  actions: {
    // Locale
    do_change_language({commit}, language) {
      commit('update_language', language);
    },

    // Auth
    do_login({commit}, req) {
      return new Promise((resolve, reject) => {
        commit('update_auth_request');
        api.login(req).then(resp => {
          if (!resp.success) {
            commit('update_auth_error', resp.code);
            reject(resp);
          } else {
            let user = resp.user;
            let token = resp.access_token;
            let remember = req.remember;
            commit('update_auth_success', {user, token, remember});
            resolve(resp);
          }
        }).catch(err => {
          commit('update_auth_error', err.code);
          reject(err);
        })
      });
    },
    do_auth_verify({commit}, req) {
      return new Promise((resolve, reject) => {
        commit('update_auth_request');
        api.auth_verify(req).then(resp => {
          if (!resp.success) {
            commit('update_auth_error', resp.code);
            reject(resp);
          } else {
            if (!req || !('check' in req)) {
              commit('update_auth_success', resp);
            }
            resolve(resp);
          }
        }).catch(err => {
          console.error('Verify Error', err);
          commit('update_auth_error', err.code);
          reject(err);
        })
      });
    },
    do_logout({commit}) {
      commit('update_logout');
    },
    do_send_reset_password_email({commit}, req) {
      return new Promise((resolve, reject) => {
        commit('update_auth_request');
        api.send_reset_password_email(req).then(resp => {
          if (!resp.success) {
            commit('update_auth_error', resp.code);
            reject(resp);
          } else {
            resolve(resp);
          }
        }).catch(err => {
          commit('update_auth_error', err.code);
          reject(err);
        })
      });
    },
    do_reset_password({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('update_auth_request');
        api.reset_password(user).then(resp => {
          if (!resp.success) {
            commit('update_auth_error', resp.code);
            reject(resp);
          } else {
            resolve(resp);
          }
        }).catch(err => {
          commit('update_auth_error', err.code);
          reject(err);
        })
      });
    },

    // Users
    do_save_user({commit,state}, request) {
      return new Promise((resolve, reject) => {
        api.save_user(request).then(resp => {
          if (resp.success) {
            if (!request.list && resp.user && resp.user.id == state.user.id) {
              commit('update_auth_success', {user: resp.user});
            } else if (request.list) {
              commit('update_user_list', resp.user_list);
            }
            resolve(resp);
          } else {
            reject();
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_fetch_users({commit}) {
      return new Promise((resolve, reject) => {
        api.fetch_users({}).then(resp => {
          if (resp.user_list) {
            commit('update_user_list', resp.user_list);
          }
          resolve(resp);
        }).catch(err => {
          reject(err);
        })
      });
    },

    // Companies
    do_fetch_companies({commit}, request) {
      console.log('Fetch Companies')
      return new Promise((resolve, reject) => {
        api.fetch_companies(request).then(resp => {
          if (resp.company_list) {
            commit('update_company_list', resp.company_list);
          }
          resolve(resp.company_list);
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_save_company({commit,state}, request) {
      return new Promise((resolve, reject) => {
        api.save_company(request).then(resp => {
          if (resp.success) {
            if (resp.company_list) {
              commit('update_company_list', resp.company_list);
            }
            if (state.company && state.company.id == resp.company.id) {
              commit('update_company', resp.company);
            }
            resolve(resp);
          } else {
            reject();
          }
        }).catch(err => {
          reject(err);
        })
      });
    },

    // Locations / Promotions
    do_fetch_promotions({commit}, request) {
      console.log('Fetch Promotions')
      return new Promise((resolve, reject) => {
        api.fetch_promotions(request).then(resp => {
          if (resp.promotion_list) {
            commit('update_promotion_list', resp.promotion_list);
          }
          // Single Promotion - Automatically Select
          if (!this.state.promotion && resp.promotion_list.length == 1) {
            let promotion = resp.promotion_list[0];
            let company_list = this.state.company_list;
            let company = company_list.filter( (c) => c.id == promotion.company_id);
            if (company.length) {
              company = company[0];
            } else {
              company = null;
            }
            let location_promotion = {company: company, promotion: promotion};
            commit('update_location_promotion', location_promotion);
          }
          resolve(resp.promotion_list);
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_save_promotion({commit}, request) {
      return new Promise((resolve, reject) => {
        api.save_promotion(request).then(resp => {
          if (resp.success) {
            if (resp.promotion_list) {
              commit('update_promotion_list', resp.promotion_list);
            }
            resolve(resp);
          } else {
            reject();
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_change_location_promotion({commit}, location_promotion) {
      if (!location_promotion.promotion) {
        let plist = this.state.promotion_list;
        if (plist.length) {
          plist = plist.filter( (p) => p.id == location_promotion.promotion_id );
          if (plist.length) {
            location_promotion.promotion = plist[0];
          }
        }
      }
      if (!location_promotion.company) {
        let clist = this.state.company_list;
        if (clist.length) {
          clist = clist.filter( (c) => c.id == location_promotion.company_id );
          if (clist.length) {
            location_promotion.company = clist[0];
          }
        }
      }
      commit('update_location_promotion', location_promotion);
    },
    do_fetch_promotion_space_list({commit}, request) {
      console.log('Fetch Promotion Spaces')
      return new Promise((resolve, reject) => {
        api.fetch_promotion_spaces(request).then(resp => {
          if (resp.promotion_space_list) {
            commit('update_promotion_space_list', resp.promotion_space_list);
          }
          if (resp.location_list) {
            commit('update_location_list', resp.location_list);
          }
          resolve(resp);
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_save_location({commit}, request) {
      return new Promise((resolve, reject) => {
        api.save_location(request).then(resp => {
          if (resp.success) {
            if (resp.location_list) {
              commit('update_location_list', resp.location_list);
            }
            if (resp.promotion_space_list) {
              commit('update_promotion_space_list', resp.promotion_space_list);
            }
            resolve(resp);
          } else {
            reject();
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_save_promotion_space({commit}, request) {
      return new Promise((resolve, reject) => {
        api.save_promotion_space(request).then(resp => {
          if (resp.success) {
            if (resp.location_list) {
              commit('update_location_list', resp.location_list);
            }
            if (resp.promotion_space_list) {
              commit('update_promotion_space_list', resp.promotion_space_list);
            }
            resolve(resp);
          } else {
            reject();
          }
        }).catch(err => {
          reject(err);
        })
      });
    },

    // Products
    do_fetch_product_list({commit}, request) {
      console.log('Fetch Product List')
      return new Promise((resolve, reject) => {
        api.fetch_products(request).then(resp => {
          if (resp.product_list) {
            commit('update_product_list', resp.product_list);
          }
          resolve(resp.product_list);
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_fetch_inventory_list({commit}, request) {
      console.log('Fetch Inventory List')
      return new Promise((resolve, reject) => {
        request.inventory = true;
        api.fetch_products(request).then(resp => {
          if (resp.product_list) {
            commit('update_inventory_list', resp.product_list);
          }
          resolve(resp.inventory_list);
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_save_product({commit}, request) {
      console.log('Save Product', request);
      return new Promise((resolve, reject) => {
        request.inventory = true;
        api.save_product(request).then(resp => {
          if (resp.product_list) {
            commit('update_inventory_list', resp.product_list);
          }
          resolve(resp);
        }).catch(err => {
          reject(err);
        })
      });
    },


    // Sales
    do_fetch_sales_schedule({commit, state}, request = {}) { // Reserved and recently Expired
      return new Promise((resolve, reject) => {
        if (state.promotion) {
          request.promotion_id = state.promotion.id;
        }
        api.fetch_sales(request).then(resp => {
          if (resp.sales_list) {
            commit('update_sales_schedule', resp.sales_list);
            resolve(resp);
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_fetch_sales_history({state}, request = {}) { // Purchased
      return new Promise((resolve, reject) => {
        request.sales_status = 'purchased';
        if (state.promotion) {
          request.promotion_id = state.promotion.id;
        }
        api.fetch_sales(request).then(resp => {
          resolve(resp);
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_save_sale({commit}, request) {
      console.log('Save Sale', request);
      return new Promise((resolve, reject) => {
        let api_endpoint = api.save_sale;
        if (request.action == 'update-quantities') {
          api_endpoint = api.save_sale_quantities;
        }
        api_endpoint(request).then(resp => {
          if (resp.sale) {
            commit('update_sale', resp.sale);
          }
          if (resp.product_list) {
            commit('update_product_list', resp.product_list);
          }
          resolve(resp);
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_fetch_sale({commit}, request) {
      console.log('Fetch Sale', request);
      return new Promise((resolve, reject) => {
        api.fetch_sale(request).then(resp => {
          if (resp.sale) {
            commit('update_sale', resp.sale);
            resolve(resp.sale);
          } else {
            reject(resp);
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_fetch_exhibitor_sales_progress({commit, state}, request = {}) {
      return new Promise((resolve, reject) => {
        if (state.promotion) {
          request.promotion_id = state.promotion.id;
        }
        console.log('Fetch Exhibitor Sales Progress', request);
        api.fetch_exhibitor_sales_progress(request).then(resp => {
          if (resp.sales_progress) {
            commit('update_exhibitor_sales_progress', resp);
            resolve(resp.sales_progress);
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    do_fetch_exhibitor_sales_analysis({commit, state}, request = {}) {
      return new Promise((resolve, reject) => {
        if (state.promotion) {
          request.promotion_id = state.promotion.id;
        }
        console.log('Fetch Exhibitor Sales Analysis', request);
        api.fetch_exhibitor_sales_analysis(request).then(resp => {
          if (resp.sales_analysis) {
            commit('update_exhibitor_sales_analysis', resp);
            resolve(resp.sales_analysis);
          }
        }).catch(err => {
          reject(err);
        })
      });
    },

  },
  mutations: {
    // Locale
    update_language(state, language) {
      state.language = language;
      i18n.locale = language;
      localStorage.setItem('language', language);
      setElementUiLang();
    },

    // Auth
    clear_auth_error(state) {
      console.log('clear_auth_error');
      state.auth_status = '';
    },
    update_auth_request(state) {
      state.auth_status = 'loading';
    },
    update_auth_success(state, params){
      let user = params.user;
      let token = params.token;
      if (user && user.id) {
        state.auth_status = 'success';
        state.user = user;
        localStorage.setItem('user_info', JSON.stringify(user));
        if (token) {
          if (params.remember) {
            localStorage.setItem('access_token', token);
          } else {
            Cookies.set('access_token', token);
          }
          Vue.prototype.$axios.defaults.headers.common['x-access-token'] = token;
        }
      }
    },
    update_auth_error(state, code = 500) {
      state.auth_status = (code == 401 ? 'usr.invalid_credentials' : 'server_error');
      if (code == 401) {
        localStorage.removeItem('access_token');
        Cookies.remove('access_token');
        localStorage.removeItem('user_info');
        delete Vue.prototype.$axios.defaults.headers.common['x-access-token'];
      }
    },
    update_logout() {
      this.reset(); // Resets all state
      localStorage.removeItem('access_token');
      Cookies.remove('access_token');
      localStorage.removeItem('user_info');
      delete Vue.prototype.$axios.defaults.headers.common['x-access-token'];
    },

    // Users
    update_user_list(state, user_list) {
      state.user_list = user_list;
    },

    // Companies
    update_company_list(state, company_list) {
      state.company_list = company_list;
    },
    update_company(state, company) {
      state.company= company;
    },

    // Promotions
    update_promotion_list(state, promotion_list) {
      state.promotion_list = promotion_list;
    },
    update_location_promotion(state, location_promotion) {
      state.company = location_promotion.company;
      state.promotion = location_promotion.promotion;

      localStorage.setItem('company_id', state.company.id);
      localStorage.setItem('promotion_id', state.promotion.id);

      state.product_list = [];
      state.sales_schedule = [];
      state.sale = null;
      state.sales_progress = [];
    },
    update_promotion_space_list(state, promotion_space_list) {
      state.promotion_space_list = promotion_space_list;
    },
    update_location_list(state, location_list) {
      state.location_list = location_list;
    },

    // Products
    update_product_list(state, product_list) {
      state.product_list = product_list;
    },
    update_inventory_list(state, inventory_list) {
      state.inventory_list = inventory_list;
    },


    // Sales
    update_sales_schedule(state, sales_list) {
      state.sales_schedule = sales_list;
    },
    update_sale(state, sale) {
      state.sale = sale;
    },
    update_exhibitor_sales_progress(state, response) {
      Vue.set(state.exhibitor_sales_progress, response.promotion_id, response.sales_progress);
    },
    update_exhibitor_sales_analysis(state, response) {
      Vue.set(state.exhibitor_sales_analysis, response.promotion_id, response.sales_analysis);
    },

  },

})
