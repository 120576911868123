<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import InventoryList from '../components/InventoryList.vue'
  export default {
    name: 'inventory-management',
    title: 'inventory.management',
    components: {
      InventoryList,
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'get_promotion',
      ]),
      promotion() {
        return this.get_promotion;
      },
    },
    methods: {
      ...mapActions([

      ]),
      ...mapMutations([

      ]),
    },
    mounted() {
      console.log('InventoryManagement mounted.');
      
    },
    beforeDestroy() {
      console.log('InventoryManagement Unmounted');
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-1">
    <div class="row justify-content-center">

      <div class="container-fluid sales-management-container" v-if="promotion">

        <div>
          <inventory-list />
        </div>


      </div>


    </div>
  </div>
</template>
