import vSelect from 'vue-select';
import BsCarousel from './cejGeneric/BsCarousel.vue';
import BsCollapse from './cejGeneric/BsCollapse.vue';
import BsForm from './cejGeneric/BsForm.vue';
import BsInputFeedback from './cejGeneric/BsInputFeedback.vue';
import BsInputRadioCheckboxes from './cejGeneric/BsInputRadioCheckboxes.vue';
import BsInputText from './cejGeneric/BsInputText.vue';
import BsInputImage from './cejGeneric/BsInputImage.vue';
import BsModal from './cejGeneric/BsModal.vue';
import ConfirmModal from './cejGeneric/ConfirmModal.vue';
import BsPopover from './cejGeneric/BsPopover.vue';
import BsVDatePicker from './cejGeneric/BsVDatePicker.vue';
import BsVSelect from './cejGeneric/BsVSelect.vue';

import CejChart from './cejGeneric/CejChart.vue';
import LanguageSwitcher from './cejGeneric/LanguageSwitcher.vue';
import "vue-select/dist/vue-select.css";
// Vue.use(vClickOutside)

import './cejGeneric/v-tooltip.js';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(vSelect.name, vSelect);
    Vue.component(BsCarousel.name, BsCarousel);
    Vue.component(BsCollapse.name, BsCollapse);
    Vue.component(BsInputFeedback.name, BsInputFeedback);
    Vue.component(BsInputRadioCheckboxes.name, BsInputRadioCheckboxes);
    Vue.component(BsInputText.name, BsInputText);
    Vue.component(BsInputImage.name, BsInputImage);
    Vue.component(BsForm.name, BsForm);
    Vue.component(BsModal.name, BsModal);
    Vue.component(ConfirmModal.name, ConfirmModal);
    Vue.component(BsPopover.name, BsPopover);
    Vue.component(BsVDatePicker.name, BsVDatePicker);
    Vue.component(BsVSelect.name, BsVSelect);
    Vue.component(LanguageSwitcher.name, LanguageSwitcher);
    Vue.component(CejChart.name, CejChart);
  }
}

export default GlobalComponents
