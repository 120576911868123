<style lang="scss">
  @import '../variables.scss';
  .reservation-buttons {
    .left-buttons {
      text-align: left;
    }
    .right-buttons {
      text-align: right;
    }
    @include media-breakpoint-down(md) {
      .left-buttons, .right-buttons {
        text-align: center;
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import QrCode from '../cejGeneric/QrCode.vue';
  import ProductList from '../components/ProductList.vue'
  export default {
    name: 'sales-confirmation',
    title: 'sales.confirmation',
    components: {
      ProductList,
      QrCode,
    },
    data() {
      return {
        save_pending: false,
        buttons_disabled: false,
        saving: false,
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
        'get_sale',
      ]),
      route_reservation_num() {
        return this.$route.params.reservation_num;
      },
      active_route() {
        return this.$router.currentRoute;
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
      sale() {
        return this.get_sale;
      },
      plist() {
        return this.$refs.product_list;
      },
      qr_data() {
        if (!this.sale) return null;
        let path = this.$router.resolve({ name: 'mobile.order', params: {company_id: this.company.id, promotion_id: this.promotion.id, reservation_num: this.sale.reservation_number} }).href;
        return window.location.origin + "/" + path;
      },
      sale_complete() {
        if (!this.sale) return false;
        return (['canceled', 'expired', 'purchased'].includes(this.sale.sales_status));
      },
    },
    methods: {
      ...mapActions([
      ]),
      ...mapMutations([
        'update_sale',
      ]),
      products_selected(products_info) {
        console.log('Products', products_info);
        if (this.plist.initialized) {
          this.$set(this, 'save_pending', (products_info.num_items > 0));
        }
      },
      disable_buttons(disabled) {
        this.buttons_disabled = disabled;
      },
      complete_reservation() {
        let $this = this;
        if (this.save_pending) {
          console.log('Making Reservation', this.plist.products_info);
          this.buttons_disabled = true;
          $this.saving = true;
          this.plist.save_sale('update').then(resp => {
            console.log('Sale Updated', resp);
            $this.save_pending = false;
          }).finally(function() {
            $this.buttons_disabled = false;
            $this.saving = false;
          }).catch(err => {
            console.log('make_reservation Error', err);
          });
        } else {
          this.$toast.success(this.$t('sales.reservation_completed'));
          this.update_sale(null);
          this.$router.push({ name: 'sales.selection' });
        }
      },
      print_reservation() {
        console.log('Print Reservation');
        window.print();
      },
      back() {
        this.$router.push({ name: 'sales.selection', params: { reservation_num: this.route_reservation_num } });
      },
      cancel_reservation() {
        let $this = this;
        console.log('Canceling Reservation');
        this.buttons_disabled = true;
        $this.saving = true;
        this.plist.cancel_sale().then(() => {
          $this.$toast.success($this.$t('sales.canceled'));
          $this.update_sale(null);
          $this.$router.push({ name: 'sales.selection' });
        }).finally(function() {
          $this.buttons_disabled = false;
          $this.saving = false;
        }).catch(err => {
          $this.$toast.error($this.$t('server_error'));
          console.log('make_reservation Error', err);
        });
      },
      show_confirmation() {
        this.$refs.confirm_modal.open();
      },
    },
    mounted() {
      console.log('SalesSelection mounted.');
    },
    beforeDestroy() {
      console.log('SalesSelection Unmounted');
    },
    watch: {
      promotion() {
        this.$router.push({ name: 'sales.selection' });
      },
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-1">

    <product-list ref="product_list" heading="sales.confirmation" mode="invoice" @products_selected="products_selected" @buttons_disabled="disable_buttons" />

    <div class="container-fluid product-list-container ps-3 pe-3">
      <div class="row mt-3 mb-3 reservation-buttons">
        <div class="col-12 col-md-4 left-buttons">
          <button class="btn btn-secondary m-1" :disabled="buttons_disabled" @click="back">{{ $t('sales.back') }}<i class="fa fa-arrow-left ms-2"></i></button>
          <button class="btn btn-danger m-1" :disabled="buttons_disabled" @click="show_confirmation" v-if="!sale_complete">{{ $t('sales.cancel_reservation') }}<i class="fas fa-trash-alt ms-2"></i> <i class="fa fa-spinner fa-spin ms-2" v-if="saving"></i></button>
        </div>
        <div class="col-12 col-md-4 text-center">
          <div class="alert alert-warning m-1" role="alert" v-if="save_pending">
            {{ $t('sales.save_pending') }}
          </div>
          <qr-code :qr-data="qr_data" v-if="qr_data" width="150" height="150" />
        </div>
        <div class="col-12 col-md-4 right-buttons">
          <button class="btn btn-orange m-1 text-light" :disabled="buttons_disabled" @click="print_reservation">{{ $t('sales.print_reservation') }}<i class="fa fa-print ms-2"></i><i class="fa fa-spinner fa-spin ms-2" v-if="saving"></i></button>
          <button class="btn btn-primary m-1" :disabled="buttons_disabled" @click="complete_reservation" v-if="save_pending">{{ $t('sales.save_reservation') }}<i class="fa fa-save ms-2"></i><i class="fa fa-spinner fa-spin ms-2" v-if="saving"></i></button>
          <button class="btn btn-success m-1" :disabled="buttons_disabled" @click="complete_reservation" v-if="!save_pending && !sale_complete">{{ $t('sales.complete_reservation') }}<i class="fa fa-check ms-2"></i><i class="fa fa-spinner fa-spin ms-2" v-if="saving"></i></button>
        </div>
      </div>
    </div>

    <confirm-modal ref="confirm_modal" title="sales.cancel_reservation" message="sales.cancel_reservation_message" save_text="sales.confirm_cancel" close_text="sales.reject_cancel" save_class="btn-danger" @confirmed="cancel_reservation" />

  </div>
</template>
