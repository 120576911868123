<style lang="scss">

</style>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'promotion-section',
    computed: {
      ...mapGetters([
        'get_promotion',
      ]),
      active_route() {
        return this.$route.name;
      },
      promotion() {
        return this.get_promotion;
      },
    },
    mounted() {
      console.log('PromotionSection mounted.');
    }
  }

</script>
<template>
  <div class="section-wrapper">
    <router-view></router-view>
  </div>
</template>
