<style lang="scss">

</style>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'user-profile',
    title: 'usr.my_profile',
    components: {
    },
    data() {
      return {
        loading: false,
        form: {
          id: null,
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          confirm_password: '',
        },
        invalid: false,
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
      ]),
      user() {
        return this.get_user;
      },
    },
    methods: {
      saved(user) {
        console.log('saved', user);
        this.$toast.success(this.$t('usr.profile_saved'));
      },
      failed(err) {
        console.log('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
    },
    mounted() {
      console.log('UserProfile mounted.');
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 user-profile-container" :class="{loading: loading}">

    <h3>{{ $t('usr.my_profile') }}</h3>

    <div class="row p-2">
      <user-edit-form :user="user" @saved="saved" @failed="failed" />
    </div>

  </div>
</template>
