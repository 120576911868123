<style lang="scss">
  .location-list-container {
    #location-list-table {
      .el-table {
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import i18n from '../i18n.js'
  import LocationEditModal from '../components/LocationEditModal.vue'
  export default {
    name: 'admin-location-list',
    title: 'location.list',
    components: {
      LocationEditModal,
      'location-list-toolbar': {
        name: 'location-list-toolbar',
        template: `
          <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" @click="$parent.add_location()">{{$t('location.add')}} <i class="fas green fa-plus"></i></button>
              </div>
            </div>
          </div>
        `,
      },
      'tbl-cell-actions': {
        props: ['row'],
        template: `
          <div class="">
            <button type="button" class="btn btn-outline-primary" @click="$parent.$parent.$parent.$parent.edit_location(row)" v-tooltip:bottom="$t('location.edit')">
              <i class="fa fa-pencil-alt"></i>
            </button>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        default_location: {
          id: null,
          name: '',
          description: '',
        },
        editing_location: null,
        table_filter_val: '',
      }
    },
    computed: {
      ...mapGetters([
        'get_location_list',
      ]),
      location_list() {
        return this.get_location_list;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'name',
              order: 'ascending'
            },
          },
          fields: [
            { label: i18n.t('location.name'), prop: 'name', sortable: true, searchable: true, min_width: '150px' },
            { label: i18n.t('location.description'), prop: 'description', sortable: true, searchable: true },
            { label: i18n.t('actions'), sortable: false, searchable: false, component: 'tbl-cell-actions', align: 'center' },
          ],
          filters: [
            {
              prop: ['name', 'description'],
              value: this.table_filter_val,
            }
          ],
        }
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_promotion_space_list',
      ]),
      fetch_locations() {
        this.do_fetch_promotion_space_list();
      },
      edit_location(location) {
        let def = JSON.parse(JSON.stringify(this.default_location));
        if (!this.editing_location || this.editing_location.id != location.id) {
          this.$set(this, 'editing_location', Object.assign(def, location));
        }
        this.$refs.location_edit_modal.open();
      },
      add_location() {
        this.$set(this, 'editing_location', JSON.parse(JSON.stringify(this.default_location)));
        this.$refs.location_edit_modal.open();
      },
      saved(location) {
        console.log('saved', location);
        this.$refs.location_edit_modal.close();
        this.$toast.success(this.$t('location.data_saved'));
        this.$set(this, 'editing_location', null);
      },
      failed(err) {
        console.log('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
    },
    mounted() {
      console.log('AdminLocationList mounted.');
      if (!this.location_list || this.location_list.length == 0) {
        this.fetch_locations();
      }
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 location-list-container" :class="{loading: loading}">

    <h3>{{ $t('location.list') }} <i class="fa fa-plane"></i></h3>
    <location-list-toolbar />

    <div class="row mt-3 mb-3" v-if="location_list">

      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="location-list-table"
        id="location-list-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="location_list"
        :page-size="10"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else>{{scope.row[field.prop]}}</span>
          </template>
        </el-table-column>
      </data-tables>

    </div>

    <location-list-toolbar />

    <location-edit-modal @saved="saved" @failed="failed" :location="editing_location" :location_list="true" ref="location_edit_modal" />

  </div>
</template>
