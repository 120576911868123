<style lang="scss">
  .user-list-container {
    #user-list-table {
      .el-table {
        th.is-sortable {
          .cell {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import i18n from '../i18n.js'
  import UserEditModal from '../components/UserEditModal.vue'
  export default {
    name: 'admin-user-list',
    title: 'usr.user_list',
    components: {
      UserEditModal,
      'user-list-toolbar': {
        name: 'user-list-toolbar',
        template: `
          <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" @click="$parent.add_user()">{{$t('usr.add_user')}} <i class="fas green fa-plus"></i></button>
              </div>
            </div>
          </div>
        `,
      },
      'tbl-cell-enabled': {
        props: ['row'],
        template: `<i class="fas" :class="{ 'fa-check': row.enabled, 'fa-times': !row.enabled }"></i>`,
      },
      'tbl-cell-role': {
        props: ['row'],
        template: `<span>{{$t('usr.' + row.role)}}</span>`,
      },
      'tbl-cell-companies': {
        props: ['row'],
        template: `<span v-if="row.company_names">{{ row.company_names.join(', ') }}</span>`,
      },
    },
    data() {
      return {
        loading: false,
        default_user: {
          id: null,
          email: null,
          first_name: null,
          last_name: null,
          password: '',
          confirm_password: '',
          enabled: true,
          role: 'user',
          company_ids: null,
          company_names: null,
        },
        editing_user: null,
        table_filter_val: '',
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_user_list',
      ]),
      current_user() {
        return this.get_user;
      },
      user_list() {
        return this.get_user_list;
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'last_name',
              order: 'ascending'
            },
          },
          fields: [
            { label: i18n.t('usr.email'), prop: 'email', sortable: true, searchable: true, min_width: '200px' },
            { label: i18n.t('usr.first_name'), prop: 'first_name', sortable: true, searchable: true },
            { label: i18n.t('usr.last_name'), prop: 'last_name', sortable: true, searchable: true },
            { label: i18n.t('enabled'), prop: 'enabled', sortable: true, searchable: false, component: 'tbl-cell-enabled', align: 'center' },
            { label: i18n.t('usr.role'), prop: 'role', sortable: true, searchable: true, component: 'tbl-cell-role' },
            { label: i18n.t('usr.company_name'), prop: 'company_names', sortable: true, searchable: true, component: 'tbl-cell-companies' },
          ],
          action_col: {
            label: i18n.t('actions'),
            props: {
              align: 'center',
            },
            buttons: [
              {
                props: {
                  type: 'primary',
                  icon: 'fas fa-pencil-alt',
                  class: 'btn btn-outline-primary',
                },
                handler: row => {
                  this.edit_user(row);
                },
                label: ''
              }
            ]
          },
          filters: [
            {
              prop: ['first_name', 'last_name', 'email', 'role', 'company_names'],
              value: this.table_filter_val,
            }
          ],
        }
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_users',
      ]),
      fetch_users() {
        this.do_fetch_users();
      },
      edit_user(user) {
        let def = JSON.parse(JSON.stringify(this.default_user));
        if (!this.editing_user || this.editing_user.id != user.id) {
          this.$set(this, 'editing_user', Object.assign(def, user));
        }
        console.log('DS', this.default_user);
        this.$refs.user_edit_modal.open();
      },
      add_user() {
        this.$set(this, 'editing_user', JSON.parse(JSON.stringify(this.default_user)));
        console.log('DS', this.default_user);
        this.$refs.user_edit_modal.open();
      },
      saved(user) {
        console.log('saved', user);
        this.$refs.user_edit_modal.close();
        this.$toast.success(this.$t('usr.profile_saved'));
        this.$set(this, 'editing_user', null);
      },
      failed(err) {
        console.log('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
    },
    mounted() {
      console.log('AdminUserList mounted.');
      this.fetch_users();
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 pe-4 user-list-container" :class="{loading: loading}">

    <h3>{{ $t('usr.user_list') }} <i class="fas fa-users"></i></h3>
    <user-list-toolbar />

    <div class="row mt-3 mb-3" v-if="user_list">

      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="user-list-table"
        id="user-list-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="user_list"
        :action-col="table_config.action_col"
        :page-size="100"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else>{{scope.row[field.prop]}}</span>
          </template>
        </el-table-column>
      </data-tables>

    </div>

    <user-list-toolbar />

    <user-edit-modal @saved="saved" @failed="failed" :user="editing_user" :user_list="true" ref="user_edit_modal" />


  </div>
</template>
