<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import ExhibitorSalesProgress from '../components/ExhibitorSalesProgress.vue';
  import ExhibitorSalesAnalysis from '../components/ExhibitorSalesAnalysis.vue';
  export default {
    name: 'exhibitor-dashboard',
    title: 'exhibitor.dashboard',
    components: {
      ExhibitorSalesProgress,
      ExhibitorSalesAnalysis,
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
      ]),
      route_sub_action() {
        return this.$route.params.sub_action;
      },
      route_sub_id() {
        return this.$route.params.sub_id;
      },
      active_route() {
        return this.$router.currentRoute;
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
    },
    methods: {
      ...mapActions([

      ]),
      ...mapMutations([

      ]),

    },
    mounted() {
      console.log('ExhibitorDashboard mounted.');
      
    },
    beforeDestroy() {
      console.log('ExhibitorDashboard Unmounted');
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-1">
    <div class="row justify-content-center">

      <div class="container-fluid exhibitor-dashboard-container" v-if="promotion">

        <div>
          <exhibitor-sales-progress/>
        </div>
        <div>
          <exhibitor-sales-analysis/>
        </div>

      </div>


    </div>
  </div>
</template>
