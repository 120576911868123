<style lang="scss">
  .countdown-timer {
    color: #e3342f;
  }
</style>
<script>
  export default {
    name: 'countdown-timer',
    components: {

    },
    props: {
      expires_at: {
        type: String,
        default: '',
      },
      timer_label: {
        type: String,
        default: 'Time Remaining'
      },
      timer_expired: {
        type: String,
        default: 'Time Expired',
      },
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
      console.log('CountdownTimer mounted.');
    },
    beforeDestroy() {
      console.log('CountdownTimer Unmounted');
    },
    watch: {
    },
  }
</script>
<template>
  <div class="countdown-timer">
    <vac :end-time="new Date(expires_at).getTime()">
      <template
        v-slot:process="{ timeObj }">
        <span>{{ $t(timer_label) + `: 00:${timeObj.m}:${timeObj.s}` }}</span>
      </template>
      <template v-slot:finish>
          <span>{{ $t(timer_expired) }}</span>
      </template>
    </vac>
  </div>
</template>
