
<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import LocationSwitcherWarning from '../components/LocationSwitcherWarning.vue';
  export default {
    name: 'exhibitor-section',
    components: {
      LocationSwitcherWarning,
    },
    computed: {
      ...mapGetters([
        // 'get_user',
        'get_promotion',
        'get_promotion_list',
        'get_company_list',
      ]),
      route_company_id() {
        return this.$route.params.company_id;
      },
      route_promotion_id() {
        return this.$route.params.promotion_id;
      },
      active_route() {
        return this.$route.name;
      },
      // user() {
      //   return this.get_user;
      // },
      promotion() {
        return this.get_promotion;
      },
      promotion_list() {
        return this.get_promotion_list;
      },
      company_list() {
        return this.get_company_list;
      },
    },
    methods: {
      ...mapActions([
        'do_change_location_promotion',
      ]),
      init_location_promotion() {
        if (this.route_promotion_id && this.route_company_id) {
          if (!this.promotion || (this.route_promotion_id !== this.promotion.id)) {
            let location_promotion = {
              promotion_id: this.route_promotion_id,
              company_id: this.route_company_id,
            }
            this.do_change_location_promotion(location_promotion);
          }
        }
      },
    },
    mounted() {
      console.log('ExhibitorSection mounted.');
      this.init_location_promotion();
    },
    watch: {
      company_list() {
        this.init_location_promotion();
      },
      promotion_list() {
        this.init_location_promotion();
      },
      promotion(new_promotion) {
        // Update Route Params
        let route_prom_id = this.route_promotion_id;
        if (new_promotion && route_prom_id != new_promotion.id) {
          console.log(new_promotion.id, route_prom_id);
          this.$router.replace({ name: this.active_route.name, params: { promotion_id: new_promotion.id, company_id: new_promotion.company_id } });
        }
      },
    }
  }

</script>
<template>
  <div class="section-wrapper">

    <router-view v-if="promotion"></router-view>

    <location-switcher-warning v-if="!promotion && promotion_list.length"/>

  </div>
</template>