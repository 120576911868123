import Vue from 'vue'
import VueI18n from 'vue-i18n'
export const languageLabels = {
  en: 'English',
  ja: '日本語',
};

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const locale_messages = loadLocaleMessages();
export const languages = Object.getOwnPropertyNames(locale_messages);

function checkDefaultLanguage() {
  let matched = null;
  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach(lang => {
      let languagePartials = navigator.language.split('-')[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  if (!matched) {
    languages.forEach(lang => {
      let languagePartials = navigator.language.split('-')[0];
      if (lang.split('-')[0] === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}
let storage_lang = localStorage.getItem('language');
if (storage_lang === undefined) storage_lang = null;
export const browserLanguage = storage_lang || checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'ja';

const i18n = new VueI18n({
  locale: browserLanguage,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: locale_messages,
});

// Element UI
import ElementUI from 'element-ui'
// Todo: Can we dynamically load and set only the correct language?
import eui_ja from 'element-ui/lib/locale/lang/ja';
import eui_en from 'element-ui/lib/locale/lang/en';

export const setElementUiLang = function setElementUiLang() {
  storage_lang = localStorage.getItem('language');
  if (storage_lang === undefined) storage_lang = null;
  let set_lang = storage_lang || browserLanguage;

  switch (set_lang) {
    case 'en':
      ElementUI.locale(eui_en);
    break;
    case 'ja':
      ElementUI.locale(eui_ja);
    break;
  }
}

i18n.locale = browserLanguage;
setElementUiLang();

export default i18n;