<script>
  import { mapGetters } from 'vuex';
  import { bus } from '../main';
  export default {
    name: 'location-switcher-warning',
    components: {

    },
    props: {
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'get_promotion',
        'get_user',
      ]),
      promotion() {
        return this.get_promotion;
      },
      user() {
        return this.get_user;
      },
    },
    methods: {
      open_location_switcher() {
        bus.$emit('location_switcher_open');
      },
    },
    mounted() {
    },
    watch: {
    },
  }
</script>
<template>
  <div class="container-fluid exhibitor-dashboard-container" v-if="!promotion">
    <div class="alert alert-warning m-3" role="alert">
      <p>{{ $t('promotion.please_select') }}</p>
      <a class="alert-link" href="javascript://" @click="open_location_switcher">{{ $t('promotion.select') }}</a>
    </div>
  </div>
</template>
