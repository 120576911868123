import AppLayout from './layout/AppLayout.vue'
import NotFound from './pages/NotFoundPage.vue'

// Public Section
import PublicSection from './sections/PublicSection.vue'
import Auth from './pages/Auth.vue'
import AuthReset from './pages/AuthReset.vue'
import AuthLogout from './pages/AuthLogout.vue'

// Admin Section
import AdminSection from './sections/AdminSection.vue'
import AdminDashboard from './pages/AdminDashboard.vue'
import AdminUserList from './pages/AdminUserList.vue'
import AdminLocationList from './pages/AdminLocationList.vue'
import AdminPromotionSpaceList from './pages/AdminPromotionSpaceList.vue'


// Exhibitor Section
import ExhibitorSection from './sections/ExhibitorSection.vue'
import ExhibitorDashboard from './pages/ExhibitorDashboard.vue'
import ExhibitorSalesProgressResults from './pages/ExhibitorSalesProgressResults.vue'
import ExhibitorSalesAnalysisResults from './pages/ExhibitorSalesAnalysisResults.vue'

// User Section
import UserSection from './sections/UserSection.vue'
import UserProfile from './pages/UserProfile.vue'

// Mobile Section
import MobileSection from './sections/MobileSection.vue'
import MobileOrder from './pages/MobileOrder.vue'

// Sales Section
import SalesSection from './sections/SalesSection.vue'
import SalesSelection from './pages/SalesSelection.vue'
import SalesConfirmation from './pages/SalesConfirmation.vue'
import SalesManagement from './pages/SalesManagement.vue'
import SalesReservation from './pages/SalesReservation.vue'

// // Inventory Section
import InventorySection from './sections/InventorySection.vue'
import InventoryManagement from './pages/InventoryManagement.vue'

// Sandbox
import SandboxPage from './pages/SandboxPage.vue'


import i18n from './i18n';

// Default Page
let default_route = 'exhibitor.dashboard';
let user_info = localStorage.getItem('user_info');
if (user_info) {
  user_info = JSON.parse(user_info);
  if (user_info.role == 'admin') default_route = 'admin.dashboard';
}

const routes = [
  {
    path: '',
    redirect: { name: default_route },
    name: 'app',
    component: AppLayout,
    meta: {
      title: i18n.t('app_name'),
    },
    children: [
      {
        name: 'pub',
        path: '/pub',
        component: PublicSection,
        redirect: { name: 'auth.login'},
        children: [
          {
            path: 'login/:action?',
            name: 'auth.login',
            component: Auth,
          },
          {
            path: 'logout',
            name: 'auth.logout',
            component: AuthLogout,
          },
          {
            path: 'reset-password/:email/:token',
            name: 'auth.reset-password',
            component: AuthReset,
          },
        ]
      },
      {
        name: 'mobile',
        path: '/mobile',
        component: MobileSection,
        redirect: { name: 'mobile.order'},
        children: [
          {
            path: 'order/:company_id/:promotion_id/:reservation_num',
            name: 'mobile.order',
            component: MobileOrder,
          },
        ],
        meta: {
          layout: 'mobile',
        },
      },
      {
        name: 'admin',
        path: '/admin',
        component: AdminSection,
        redirect: { name: 'admin.dashboard'},
        children: [
          {
            path: 'dashboard',
            name: 'admin.dashboard',
            component: AdminDashboard,
          },
          {
            path: 'user-list',
            name: 'admin.user-list',
            component: AdminUserList,
          },
          {
            path: 'sandbox',
            name: 'admin.sandbox',
            component: SandboxPage,
          },
          {
            path: 'location-list',
            name: 'admin.location-list',
            component: AdminLocationList,
          },
          {
            path: 'promotion-space-list',
            name: 'admin.promotion-space-list',
            component: AdminPromotionSpaceList,
          },
        ],
        meta: {
          requires_auth: 'admin',
        },
      },
      {
        name: 'exhibitor',
        path: '/exhibitor',
        component: ExhibitorSection,
        redirect: { name: 'exhibitor.dashboard'},
        children: [
          {
            path: 'dashboard/:company_id?/:promotion_id?',
            name: 'exhibitor.dashboard',
            component: ExhibitorDashboard,
          },
          {
            path: 'sales-progress/:company_id?/:promotion_id?',
            name: 'exhibitor.sales_progress',
            component: ExhibitorSalesProgressResults,
          },
          {
            path: 'sales-analysis/:company_id?/:promotion_id?',
            name: 'exhibitor.sales_analysis',
            component: ExhibitorSalesAnalysisResults,
          },
        ],
        meta: {
          requires_auth: true
        },
      },
      {
        name: 'sales',
        path: '/sales',
        component: SalesSection,
        redirect: { name: 'sales.selection'},
        children: [
          {
            path: 'selection/:reservation_num?',
            name: 'sales.selection',
            component: SalesSelection,
          },
          {
            path: 'confirmation/:reservation_num',
            name: 'sales.confirmation',
            component: SalesConfirmation,
          },
          {
            path: 'management/:reservation_num?',
            name: 'sales.management',
            components: {
              default: SalesManagement,
              invoice: SalesReservation,
            },
          },
          {
            path: 'reservation/:reservation_num',
            name: 'sales.reservation',
            component: SalesReservation,
          },
        ],
        meta: {
          requires_auth: true
        },
      },
      {
        name: 'inventory',
        path: '/inventory',
        component: InventorySection,
        redirect: { name: 'inventory.management'},
        children: [
          {
            path: 'management',
            name: 'inventory.management',
            component: InventoryManagement,
          },
        ],
        meta: {
          requires_auth: true
        },
      },
      {
        path: '/user',
        component: UserSection,
        redirect: { name: 'user.profile'},
        children: [
          {
            path: 'profile',
            name: 'user.profile',
            component: UserProfile,
          },
        ],
        meta: {
          requires_auth: true
        },
      },
    ]
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
  var res= require('../components/Dashboard/Views/' + name + '.vue');
  return res;
};**/

export default routes
