<style lang="scss">
  .company-edit-form {
    .cej-input-image {
      .preview-container {
        .picture-inner {
          height: 85% !important;
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
//  import i18n from '../i18n.js'
  export default {
    name: 'company-edit-form',
    props: {
      company: {
        default: '',
      },
      buttons: {
        type: Boolean,
        default: true,
      },
      company_list: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form_loading: true,
        form: {
          id: null,
          name: '',
          industry: '',
          logo: '',
          logo_type: '',
          logo_url: null,
          street_address: '',
          representative: '',
          phone_number: '',
          mail_address: '',
          description: '',
        },
        invalid: false,
      }
    },
    components: {

    },
    computed: {
      ...mapGetters([
        'get_company',
      ]),
      current_company() {
        return this.get_company;
      },
      edit_perms() {
        return (this.current_company && this.current_company.id != this.form.id && this.company_list);
      },
    },
    methods: {
      ...mapActions([
        'do_save_company',
      ]),
      init_form() {
        if (this.company) {
          let company = JSON.parse(JSON.stringify(this.company));
          this.form.id = company.id;
          this.form.name = company.name;
          this.form.industry = company.industry;
          this.form.logo_url = company.logo_url ? company.logo_url : null;
          this.form.logo = null;
          this.form.logo_type = company.logo_type;
          this.form.street_address = company.street_address;
          this.form.representative = company.representative;
          this.form.phone_number = company.phone_number;
          this.form.mail_address = company.mail_address;
          this.form.description = company.description;
        }
      },
      trigger_save() {
        // Trigger Validate & Submit
        this.$refs.company_form.trigger_submit();
      },
      save_company() {
        let $this = this;
        this.do_save_company({company: this.form, list: this.company_list})
          .then(function(company) {
            $this.$emit('saved', company);
          }).finally(function() {
            $this.$refs.company_form.disable_buttons(false);
            $this.$refs.company_form.set_form_loading(false);
          }).catch(function(err) {
            $this.$emit('failed', err);
          });
      },
      buttons_disabled(disabled) {
        this.$emit('buttons_disabled', disabled);
      },
      set_form_loading(form_loading = true) {
        this.$emit('form_loading', form_loading);
      },
      form_is_loading(form_loading = true) {
        let $this = this;
        this.$set(this, 'form_loading', form_loading);
        if (!form_loading) {
          window.setTimeout(() => {
            $this.$refs.company_logo_uploader.onResize();
          }, 1000);
        }
      },
      returnId(option) {
        return option.id;
      },
      logoChanged(image) {
        this.form.logo_url = null;
        this.form.logo_type = image.type;
      },
      logoRemoved() {
        this.form.logo_url = null;
        this.form.logo = null;
        this.form.logo_type = null;
      },
    },
    mounted() {
      console.log('CompanyEditForm Mounted');
      this.init_form();
    },
    watch: {
      'company.id': function(new_val) {
        if (new_val != this.form.id) {
          this.init_form();
        }
      },
    },
  }
</script>
<template>
  <div>
    <bs-form ref="company_form" class="company-edit-form" :submit_button="buttons ? 'submit' : false" :reset_button="false" @submit="save_company" @buttons_disabled="buttons_disabled" @form_loading="set_form_loading">

      <fieldset>
        <legend>{{$t('company.edit')}}</legend>
        <bs-input-text id="company_name" type="text" :label="$t('company.name')" :placeholder="$t('company.name')" v-model="form.name" rules="required" autocomplete="company-name" />
        <bs-input-text id="company_description" type="text" :label="$t('company.description')" :placeholder="$t('company.description')" v-model="form.description" />
        <bs-input-text id="company_industry" type="text" :label="$t('company.industry')" :placeholder="$t('company.industry')" v-model="form.industry" />
        <bs-input-text id="company_representative" type="text" :label="$t('company.representative')" :placeholder="$t('company.representative')" v-model="form.representative" />
        <bs-input-text id="company_phone_number" type="tel" :label="$t('company.phone_number')" :placeholder="$t('company.phone_number')" v-model="form.phone_number" />
        <bs-input-text id="company_mail_address" type="email" :label="$t('company.email_address')" :placeholder="$t('company.email_address')" v-model="form.mail_address" />
        <bs-input-text id="company_street_address" type="text" :label="$t('company.street_address')" :placeholder="$t('company.street_address')" v-model="form.street_address" />
        <bs-input-image id="company_logo_uploader" ref="company_logo_uploader" :label="$t('company.logo')" v-model="form.logo" size="2" width="300" height="75" :prefill="form.logo_url" @changed="logoChanged" @removed="logoRemoved" />
      </fieldset>
    </bs-form>
  </div>
</template>
