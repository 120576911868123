<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import ProductList from '../components/ProductList.vue'
  export default {
    name: 'sales-selection',
    title: 'sales.selection',
    components: {
      ProductList,
    },
    data() {
      return {
        display_buttons: false,
        buttons_disabled: false,
        saving: false,
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
        'get_sale',
      ]),
      route_reservation_num() {
        return this.$route.params.reservation_num;
      },
      active_route() {
        return this.$router.currentRoute;
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
      sale() {
        return this.get_sale;
      },
      plist() {
        return this.$refs.product_list;
      },
      sale_complete() {
        if (!this.sale) return false;
        return (['canceled', 'expired', 'purchased'].includes(this.sale.sales_status));
      },
    },
    methods: {
      ...mapActions([
      ]),
      ...mapMutations([
        'update_sale',
      ]),
      products_selected(products_info) {
        console.log('Products', products_info);
        this.$set(this, 'display_buttons', (products_info.num_items > 0));
      },
      disable_buttons(disabled) {
        this.buttons_disabled = disabled;
      },
      make_reservation() {
        let $this = this;
        this.buttons_disabled = true;
        this.saving = true;
        let action = this.route_reservation_num ? 'update' : 'create';
        this.plist.save_sale(action).then(resp => {
          this.$router.push({ name: 'sales.confirmation', params: { reservation_num: resp.sale.reservation_number } });
        }).finally(function() {
          $this.buttons_disabled = false;
          $this.saving = false;
        }).catch(err => {
          console.log('make_reservation Error', err);
        });
      },
      cancel_reservation() {
        let $this = this;
        this.buttons_disabled = true;
        this.saving = true;
        this.plist.cancel_sale().then(() => {
          if ($this.route_reservation_num) {
            $this.$router.push({ name: 'sales.selection' });
          }
          $this.update_sale(null);
          $this.plist.init_form();
        }).finally(function() {
          $this.buttons_disabled = false;
          $this.saving = false;
        }).catch(err => {
          console.log('cancel_reservation Error', err);
        });
      },
      show_confirmation() {
        this.$refs.confirm_modal.open();
      },
    },
    mounted() {
      console.log('SalesSelection mounted.');
    },
    beforeDestroy() {
      console.log('SalesSelection Unmounted');
      this.update_sale(null);
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-1">

    <product-list ref="product_list" heading="sales.selection" mode="shop" @products_selected="products_selected" @buttons_disabled="disable_buttons" />

    <div class="container-fluid product-list-container ps-3 pe-3">
      <div class="row mt-3 mb-3" v-if="display_buttons">
        <div class="col">
          <button class="btn btn-danger m-1" :disabled="buttons_disabled" @click="show_confirmation">{{ $t('sales.cancel_reservation') }}<i class="fas fa-trash-alt ms-2"></i><i class="fa fa-spinner fa-spin ms-2" v-if="saving"></i></button>
        </div>
        <div class="col text-end">
          <button class="btn btn-primary m-1" :disabled="buttons_disabled" @click="make_reservation" v-if="!sale_complete">{{ $t('sales.make_reservation') }}<i class="fa fa-check ms-2"></i><i class="fa fa-spinner fa-spin ms-2" v-if="saving"></i></button>
        </div>
      </div>
    </div>

    <confirm-modal ref="confirm_modal" title="sales.cancel_reservation" message="sales.cancel_reservation_message" save_text="sales.confirm_cancel" close_text="sales.reject_cancel" save_class="btn-danger" @confirmed="cancel_reservation" />

  </div>
</template>
