import store from '../store';
import i18n from '../i18n'


export default {
  created() {
    const title = this.getPageTitle(this);
    if (title) {
      this.setPageTitle(title);
    }
  },
  methods: {
    setPageTitle(title) {
      document.title = title;
    },
    getPageTitle(vm) {
      const { title } = vm.$options;
      if (title) {
        let app_name = i18n.t('app_name') || 'APP';
        let base_title = app_name;
        let company = store.getters.get_company;
        if (company && company.name) base_title = `${app_name} - ${company.name}`;
        return typeof title === 'function'
          ? `${base_title} - ` + title.call(vm)
          : `${base_title} - ` + i18n.t(title);
      }
    },
  }
}