import Vue from 'vue';
window.Vue = require('vue').default;
 // import Vuex from 'vuex'
 // import * as mdb from 'mdb-ui-kit'; // lib
import './app.scss'
import store from './store.js'
import VueRouter from 'vue-router'
import i18n from './i18n'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

import Axios from 'axios'
Vue.prototype.$axios = Axios;
let token = localStorage.getItem('access_token') || Cookies.get('access_token');
if (token) {
  Vue.prototype.$axios.defaults.headers.common['x-access-token'] = token;
  store.dispatch('do_auth_verify');
}
import App from './App.vue'
import routes from './routes'


// Libraries
import moment from 'moment-timezone'
moment.tz.setDefault("UTC");
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);
typeof moment.duration.fn.format === "function";
typeof moment.duration.format === "function";
Vue.prototype.moment = moment;
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import http from "./services/http"
Vue.prototype.http = http
import jquery from 'jquery';
Vue.prototype.jquery = jquery;

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
import VueDataTables from 'vue-data-tables'
Vue.use(VueDataTables)
import VCalendar from 'v-calendar'
Vue.use(VCalendar)
import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)
var vueAwesomeCountdown = require('vue-awesome-countdown').default
Vue.use(vueAwesomeCountdown)

let display_tz = 'Asia/Tokyo';

// Global Mixins
import titleMixin from './mixins/titleMixin'
Vue.mixin(titleMixin)

// Global Components
import GlobalComponents from './globalComponents'

export default {
  install (Vue) {
    Vue.use(GlobalComponents)
  }
}



// Random Funcs
/**
* Simple object check.
* @param item
* @returns {boolean}
*/
Vue.prototype.isObject = function(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
* Deep merge two objects.
* @param target
* @param ...sources
*/
Vue.prototype.mergeDeep = function(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();
  if (this.isObject(target) && this.isObject(source)) {
    for (const key in source) {
      if (this.isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        this.mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return this.mergeDeep(target, ...sources);
}

Vue.prototype.componentId = function(prefix) {
  prefix = (prefix || this.$vnode.tag) + "_" + this._uid;
  function _getParentPrefix(_node) {
    var _uid_list = null;
    if (_node) {
      _uid_list = "_" + _node._uid;
      var _parent_uid_list = _getParentPrefix(_node.$parent);
      if (_parent_uid_list) {
        _uid_list = _uid_list + _parent_uid_list;
      }
    }
    return _uid_list;
  }
  var parent_prefix = _getParentPrefix(this.$parent);
  var _elId = "_cejid_" + prefix + parent_prefix;
  return _elId;
}


// Global Filters
Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('formatDatetime', function(value, format = 'YYYY-MM-DD HH:mm:ss') {
  if (value) {
    return moment(String(value)).tz(display_tz).format(format);
  }
});
Vue.filter('formatDatemin', function(value, format = 'YY-MM-DD HH:mm') {
  if (value) {
    return moment(String(value)).tz(display_tz).format(format);
  }
});
Vue.filter('formatDate', function(value, format = 'YYYY-MM-DD') {
  if (value) {
    return moment(String(value)).tz(display_tz).format(format);
  }
});
Vue.filter('formatBytes', function(value, decimal_places = 0) {
  var bytes = require('bytes');
  if (value) {
    return bytes(value, {decimalPlaces: decimal_places});
  }
});
Vue.filter('formatNumber', function(value, digits = 0) {
  let locale = 'ja-JP';
  let str = parseFloat(value).toLocaleString(locale, {maximumFractionDigits: digits});
  return str;
});
Vue.filter('formatCurrency', function(value, digits = 0, prepend_symbol = '¥') {
  let locale = 'ja-JP';
  let str = parseFloat(value).toLocaleString(locale, {maximumFractionDigits: digits});
  if (prepend_symbol) str = prepend_symbol + str;
  return str;
});
Vue.filter('formatStat', function(value, stat_type, prepend_symbol = '¥') {
  let locale = 'ja-JP';
  if (value === null) {
    return 'N/A';
  }
  if (stat_type == 'watts' || stat_type == 'kilowatts') {
    value = parseFloat(value);
    if (stat_type == 'watts') {
      value = value / 1000;
    }
    if (value < 1000000) {
      value = value.toLocaleString(locale, {maximumFractionDigits: 2});
      return `${value} kW`;
    } else {
      value = value / 1000;
      value = value.toLocaleString(locale, {maximumFractionDigits: 2});
      return `${value} MW`;
    }
  } else if (stat_type == 'currency') {
    let str = parseFloat(value).toLocaleString(locale, {maximumFractionDigits: 0});
    if (prepend_symbol) str = prepend_symbol + str;
    return str;
  } else if (stat_type == 'rpm') {
    value = parseFloat(value).toLocaleString(locale, {maximumFractionDigits: 1});
    return `${value} RPM`;
  } else if (stat_type == 'wind_speed') {
    value = parseFloat(value).toLocaleString(locale, {maximumFractionDigits: 1});
    return `${value} m/s`;
  } else if (stat_type == 'wind_direction') {
    value = parseFloat(value).toLocaleString(locale, {maximumFractionDigits: 1});
    return `${value}°`;
  } else {
    return parseFloat(value).toLocaleString(locale, {maximumFractionDigits: 1});
  }
});
Vue.filter('duration', function(value) {
  return moment.duration(value, 'seconds').format("d[d] h[h] m[m]", {
    largest: 2,
    trim: "all"
  });
});

// Configure Router
const router = new VueRouter({
  // mode: 'history', -- need to disable / redirect laravel 404
  routes: routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to, from, next) => {
  let matched = to.matched.filter(record => record.meta.requires_auth)
  if (matched.length > 0) {
    matched.forEach(record => {
      let token = localStorage.getItem('access_token') || Cookies.get('access_token');
      let user_info = localStorage.getItem('user_info');
      if (user_info) user_info = JSON.parse(user_info);
      if (token && user_info &&
        (record.meta.requires_auth === true || record.meta.requires_auth == user_info.role)
      ) {
        next();
        return;
      } else {
        next({name: 'auth.login'});
      }
    })
  } else {
    next();
  }
});



// Load Components
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Global Event Bus
export const bus = new Vue();

// Load App
new Vue({
  i18n,
  el: '#app',
  render: h => h(App),
  router,
  store
});
