<script>
  import { Carousel } from 'bootstrap'
  export default {
    name: 'bs-carousel',
    props: {
      ride: {
        default: 'carousel',
      },
      interval: {
        default: 5000,
      },
      wrap: {
        default: true,
      },
      pause: {
        default: 'hover',
      },
      id: {
        type: String,
        default: 'cej-carousel'
      },
      css_class: {
        type: String,
        default: '',
      },
      labels: {
        type: Array,
        default: () => {return []},
      },
    },
    data() {
      return {
        carousel: null,
      }
    },
    mounted() {
      // pass bootstrap carousel options from props
      var options = this.$props;
      // console.log('Carousel Props', options);
      var ele = this.$refs.carousel_elm;
      this.carousel = new Carousel(ele, options);
      this.carousel.cycle();
    },
    methods: {
      open() {
        this.modal.show();
      },
      close() {
        this.modal.hide();
        this.$emit('close');
      },
    },
  }

</script>
<template>
  <div ref="carousel_elm" :id="id" class="carousel slide" :class="css_class">
    <div class="carousel-indicators">
      <a v-for="(label,idx) in labels" :key="label.key" :data-bs-target="'#' + id" :data-bs-slide-to="idx" :class="{active: (idx == 0)}" :aria-label="label.label"><i class="fa fa-circle"></i></a>
    </div>
    <div class="carousel-inner">
      <div v-for="(label,idx) in labels" :key="label.key" class="carousel-item" :class="{active: (idx == 0)}">
        <h5>{{label.label}}</h5>
        <slot :name="'slide' + idx"></slot>
        
      </div>
    </div>
  </div>
</template>
