<style>

</style>
<script>
  export default {}

</script>
<template>
  <footer class="footer">
    <div class="container-fluid justify-content-center">
      <div class="copyright text-center">
        &copy; 2021 {{ $t('copyright_company') }}
      </div>
    </div>
  </footer>
</template>
