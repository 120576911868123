<script>
  import { mapActions, mapGetters } from 'vuex';
  import { bus } from '../main';
  import LocationSwitcherList from './LocationSwitcherList.vue';
  export default {
    name: 'location-switcher',
    components: {
      LocationSwitcherList,
    },
    props: {
      linkClass: {
        default: 'nav-item'
      },
      dark: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
      ]),
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
    },
    methods: {
      ...mapActions([
        'do_change_location',
      ]),
      show_locations() {
        this.$nextTick(function () {
          this.$refs.promotion_select_modal.open();
        });
      },
      close() {
        this.$refs.promotion_select_modal.close();
      },
      saved(promotion) {
        console.log('saved', promotion);
        this.$refs.promotion_select_modal.close();
        this.$toast.success(this.$t('promotion.selected'));
      },
      failed(err) {
        console.log('Error', err);
        this.$toast.error(this.$t('server_error'));
      },
    },
    mounted() {
    },
    created(){
      bus.$on('location_switcher_open', () => {
        this.show_locations();
      });
    },
  }
</script>
<template>
  <bs-modal ref="promotion_select_modal" :title="$t('promotion.select')" css_class="modal-lg" :save_text="false" :close_text="promotion ? 'close' : false" :keyboard="false" backdrop="static">
    <location-switcher-list ref="promotion_select_list" @saved="saved" :promotion="promotion" :company="company" v-if="user" />
  </bs-modal>
</template>
