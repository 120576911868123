<style lang="scss">
  .exhibitor-sales-analysis-container {
    &.summary-report {
      .cej-chart-container div {
        height: 250px;
      }
    }
    &.detail-report {
      .cej-chart-container div {
        height: 400px;
      }
      #sales-analysis-table {
        .el-table {
          .cell {
            word-break: break-word;
          }
          th.is-sortable {
            .cell {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import CejChart from '../cejGeneric/CejChart.vue'
  import i18n from '../i18n';
  export default {
    name: 'exhibitor-sales-analysis',
    components: {
      CejChart,
    },
    data() {
      return {
        loading: false,
        table_filter_val: '',
        chart_colors: [
          '#6cce68',
          '#e0bb77',
          '#c2be18',
          '#ebb000',
          '#ffa600',
          '#d0ba05',
          '#a0c73a',
          '#25d295',
          '#00d2bd',
          '#00cfdd',
          '#0dcaf0',
          '#5ca9ff',
          '#9192f3',
          '#bc77d8',
          '#d959ae',
          '#e43e7b',
          '#dc3545',
        ],
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
        'get_exhibitor_sales_analysis',
      ]),
      route_company_id() {
        return this.$route.params.company_id;
      },
      route_promotion_id() {
        return this.$route.params.promotion_id;
      },
      active_route() {
        return this.$router.currentRoute;
      },
      is_summary() {
        return (this.active_route.name == 'exhibitor.dashboard' ? true : false);
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
      stats() {
        return this.get_exhibitor_sales_analysis;
      },
      line_chart_options() {
        return {
          plugins: {
            title: {
              display: true,
              text: i18n.t('exhibitor.top_earning_products'),
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(context.parsed.y);
                    // label += new Intl.NumberFormat('ja-JP').format(context.parsed.y);
                  }
                  return label;
                },
                footer: function(tooltip_items) {
                  let item = tooltip_items[0];
                  let idx = item.dataIndex;
                  let purchased = item.dataset.quantities[idx];
                  return i18n.t('sales.purchased') + ': ' + new Intl.NumberFormat('ja-JP').format(purchased);
                },
              },
            },
          },
          scales: {
            x: {
              // type: 'time',
              // time: {
              //   // Luxon format string
              //   tooltipFormat: 'DD T'
              // },
              title: {
                display: true,
                text: i18n.t('exhibitor.purchase_date'),
              },
            },
            y: {
              title: {
                display: true,
                text: i18n.t('exhibitor.revenue'),
              },
              ticks: {
                precision: 0,
                callback: function(val) {
                  return '¥ ' + val;
                },
              },
            }
          },
        };
      },
      pie_chart_options() {
        return {
          plugins: {
            legend: {
              // position: 'right',
              display: (this.is_summary ? false : true),
            },
            title: {
              display: true,
              text: i18n.t('exhibitor.top_earning_products'),
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  var label = context.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed !== null) {
                    label += new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(context.parsed);
                    // label += new Intl.NumberFormat('ja-JP').format(context.parsed.y);
                  }
                  return label;
                },
                footer: function(tooltip_items) {
                  let item = tooltip_items[0];
                  let idx = item.dataIndex;
                  let purchased = item.dataset.quantities[idx];
                  return i18n.t('sales.purchased') + ': ' + new Intl.NumberFormat('ja-JP').format(purchased);
                },
              },
            },
          },
        };
      },
      product_colors() {
        let by_idx = [];
        let by_id = {};
        if (this.stats.top) {
          let color_idx = 0;
          this.stats.top.forEach( (stat) => {
            by_id[stat.product_id] = this.chart_colors[color_idx];
            by_idx.push(this.chart_colors[color_idx]);
            color_idx++;
          });
        }
        return {by_idx: by_idx, by_id: by_id};
      },
      line_chart_data() {
        let stats = this.stats;
        let list = [];
        let product_ids = [];
        let products = {};
        let date_labels = [];

        // Filter Stats to Top Products
        if (stats.top) {
          product_ids = stats.top.map( (p) => p.product_id );
        }
        if (stats.list) {
          list = stats.list.filter( (s) => product_ids.includes(s.product_id) );
        }

        // Format Chart Data
        list.forEach( (stat) => {
          stat.purchase_date = this.$options.filters.formatDate(stat.purchase_date);
          if (!date_labels.includes(stat.purchase_date)) {
            date_labels.push(stat.purchase_date);
          }
          if (!(stat.product_id in products)) {
            products[stat.product_id] = {
              label: stat.name,
              data: [],
              quantities: [],
              fill: false,
              tension: 0.15,
              borderColor: this.product_colors.by_id[stat.product_id],
              backgroundColor: this.product_colors.by_id[stat.product_id],
            };
          }
          products[stat.product_id].data.push(stat.total_purchased);
          products[stat.product_id].quantities.push(stat.quantity_purchased);
        });

        let ds = [];
        for (const prod of Object.values(products)) {
          ds.push(prod);
        }

        return {
          labels: date_labels,
          datasets: ds,
        };
      },
      pie_chart_data() {
        let product_labels = [];
        let pie_data = [];
        let quantities = [];
        if (this.stats.top) {
          this.stats.top.forEach( (stat) => {
            product_labels.push(stat.name);
            pie_data.push(stat.total_purchased);
            quantities.push(stat.quantity_purchased);
          });
        }
        return {
          labels: product_labels,
          datasets: [{
            label: 'Pie Dataset',
            data: pie_data,
            quantities: quantities,
            backgroundColor: Object.values(this.product_colors.by_idx),
          }],
        };
      },
      line_chart_col_classes() {
        return this.is_summary ? 'col-12 col-sm-6 col-xl-8' : 'col-12 col-lg-9';
      },
      pie_chart_col_classes() {
        return this.is_summary ? 'col-12 col-sm-4 col-xl-2' : 'col-12 col-lg-3';
      },
      button_col_classes() {
        return this.is_summary ? 'col-12 col-sm-3 col-xl-2' : 'col-12';
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'purchase_date',
              order: 'descending'
            },
          },
          fields: [
            { label: i18n.t('exhibitor.purchase_date'), prop: 'purchase_date', sortable: true, searchable: true, formatter: this.$options.filters.formatDate },
            { label: i18n.t('product.name'), prop: 'name', sortable: true, searchable: true, min_width: '100px' },
            { label: i18n.t('product.description'), prop: 'description', sortable: true, searchable: true, min_width: '200px' },
            { label: i18n.t('sales.purchased'), prop: 'quantity_purchased', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
            { label: i18n.t('exhibitor.revenue'), prop: 'total_purchased', sortable: true, searchable: false, formatter: this.$options.filters.formatCurrency },
          ],
          filters: [
            {
              prop: ['name', 'description', 'purchase_date'],
              value: this.table_filter_val,
            }
          ],
        }
      },
      table_data() {
        if (!this.stats.list) return [];
        let list = this.stats.list;
        list = list.filter( (stat) => stat.total_purchased > 0 );
        return list;
      },
      csv_fields() {
        let fields = ['product_id'];
        for (let i = 0; i < this.table_config.fields.length; i++) {
          let field = this.table_config.fields[i];
          fields.push(field.prop);
        }
        return fields;
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_exhibitor_sales_analysis',
      ]),
      fetch_stats() {
        let $this = this;
        this.loading = true;
        this.do_fetch_exhibitor_sales_analysis()
          .then( () => {
            $this.$set($this, 'loading', false);
          }).catch(err => {
            console.error('fetch_stats', err);
            $this.$set($this, 'loading', false);
          });
      },
    },
    mounted() {
      console.log('ExhibitorSalesAnalysis mounted.');
      this.fetch_stats();
    },
    beforeDestroy() {
      console.log('ExhibitorSalesAnalysis Unmounted');
    },
    watch: {
      promotion() {
        this.fetch_stats();
      },
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 exhibitor-sales-analysis-container" :class="{loading: loading, 'summary-report': is_summary, 'detail-report': !is_summary }">

    <h3>{{ $t('exhibitor.sales_analysis') }}</h3>

    <div class="row mt-3 mb-3 text-center" v-if="!stats.list || !stats.list.length">
      <p>{{ $t('exhibitor.no_sales_data') }}</p>
    </div>

    <div class="row mt-3 mb-3" v-if="stats.list && stats.list.length">
      <!--
      -Chart-
      Line chart showing $ profit for each product by day
      order by $ earned desc

      pie chart showing top 5 product earners, rest in other
      -->

      <div class="exhibitor-line-chart-col" :class="line_chart_col_classes">
        <cej-chart type="line" :data="line_chart_data" :options="line_chart_options" />
      </div>
      <div class="exhibitor-pie-chart-col" :class="pie_chart_col_classes">
        <cej-chart type="pie" :data="pie_chart_data" :options="pie_chart_options" />
      </div>

      <div :class="button_col_classes">
        <div class="row align-items-center">
          <div class="col-6 col-sm-12 text-center" v-if="is_summary">
            <router-link type="button" class="btn btn-outline-primary m-1" :to="{ name: 'exhibitor.sales_analysis', params: {company_id: route_company_id, promotion_id: route_promotion_id} }">{{ $t('exhibitor.details') }} <i class="fa fa-th-list"></i></router-link>
          </div>
          <div class="col-6 col-sm-12 text-center">
            <div class="mx-auto">
              <download-csv
                class="btn btn-outline-primary m-1"
                :fields="csv_fields"
                :data="table_data"
                name="sales_analysis.csv">
                {{ $t('csv_export') }} <i class="fa fa-file-csv"></i>
              </download-csv>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-3 mb-3" v-if="!is_summary && table_data">
      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="sales-analysis-table"
        id="sales-analysis-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="table_data"
        :page-size="100"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
          :formatter="field.formatter"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else-if="field.formatter">{{ field.formatter(scope.row[field.prop]) }}</span>
            <span v-else>{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </data-tables>
    </div>

  </div>
</template>
