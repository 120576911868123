<style lang="scss">
  .ar-navbar {
    background-color: #000;
  }
  .navbar > .container-fluid {
    align-items: start !important;
    .company-logo {
      max-height: 75px;
      max-width: 300px;
    }
  }
  @media print {
    .ar-navbar {
      background-color: #fff !important;
    }
    #top-navbar-menu {
      &.no-print {
        display: none !important;
      }
    }
  }

</style>
<script>
  import { mapGetters } from 'vuex'
  import NavLinks from './NavLinks.vue';
  import LocationSwitcherButton from '../components/LocationSwitcherButton.vue';
  import LocationSwitcher from '../components/LocationSwitcher.vue';
  export default {
    name: 'top-navbar',
    components: {
      NavLinks,
      LocationSwitcherButton,
      LocationSwitcher,
    },
    data () {
      return {
        activeNotifications: false,
      }
    },
    computed: {
      ...mapGetters([
        'is_logged_in',
        'get_user',
        'get_company',
      ]),
      active_route() {
        return this.$router.currentRoute;
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      is_admin() {
        return (this.user && this.user.is_admin);
      },
      display_name() {
        return `${this.user.first_name} ${this.user.last_name}`;
      },
    },
    methods: {

    }
  }
</script>
<template>
  <nav class="navbar navbar-expand-lg navbar-dark ar-navbar">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ name: 'app' }">
        <span v-if="company && company.logo_url"><img :src="company.logo_url" class="company-logo" /></span>
        <span v-if="company && !company.logo_url">{{ company.name }}</span>
        <span v-if="!company">{{ $t('app_name') }}</span>
      </router-link>

      <button class="navbar-toggler no-print" type="button" data-bs-toggle="collapse" data-bs-target="#top-navbar-menu" aria-controls="top-navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <location-switcher />

      <div class="collapse navbar-collapse no-print" id="top-navbar-menu">

        <span class="navbar-text" v-if="user">

        </span>

        <nav-links css_class="hide-gt-992" v-if="user" />

        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

          <li class="nav-item dropdown" v-if="is_admin">
            <a class="nav-link dropdown-toggle" href="javascript://" id="adminNavDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-cog"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="adminNavDropdown">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'admin.user-list' }" v-if="is_admin">{{ $t('usr.user_list') }} <i class="fas fa-users"></i></router-link>
                <router-link class="dropdown-item" :to="{ name: 'admin.location-list' }" v-if="is_admin">{{ $t('location.list') }} <i class="fas fa-plane"></i></router-link>
                <router-link class="dropdown-item" :to="{ name: 'admin.promotion-space-list' }" v-if="is_admin">{{ $t('promotion_space.list') }} <i class="fas fa-map-marked-alt"></i></router-link>
                <router-link class="dropdown-item" :to="{ name: 'admin.sandbox' }" v-if="is_admin && [1,2].includes(user.id)">Sandbox <i class="fas fa-rocket"></i></router-link>
              </li>
            </ul>
          </li>

          <language-switcher />
          <location-switcher-button />

          <li class="nav-item" v-if="is_logged_in">
            <router-link class="nav-link" :to="{ name: 'user.profile' }">{{ display_name }} <i class="fas fa-user"></i></router-link>
          </li>
          <li class="nav-item" v-if="is_logged_in">
            <router-link class="nav-link" :to="{ name: 'auth.logout' }">{{ $t('usr.logout') }} <i class="fas fa-sign-out-alt"></i></router-link>
          </li>
          <li class="nav-item" v-if="!is_logged_in">
            <router-link class="nav-link" :to="{ name: 'auth.login' }">{{ $t('usr.login') }} <i class="fas fa-sign-in-alt"></i></router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
