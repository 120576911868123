<style lang="scss">
  .loading-indicator,
  .validation-success,
  .validation-failure,
  .validation-pending {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, .6);
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 10px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .validation-success {
    color: green;
  }
  .validation-failure {
    color: red;
  }
  .qr-button {
    img {
      margin: auto;
      display: block;
    }
  }
</style>
<script>
  import { QrcodeStream } from 'vue-qrcode-reader'
  export default {
    name: 'qr-scanner',
    components: {
      QrcodeStream,
    },
    props: {
      placeholder: {
        type: String,
        default: 'images/qr_placeholder.png',
      },
      width: {
        default: 100,
      },
      height: {
        default: 100,
      },
      validate: {
        type: Function,
      },
    },
    data() {
      return {
        show_scanner: false,
        destroyed: false,
        loading: false,
        isValid: undefined,
        camera: 'auto',
        result: null,
      }
    },
    computed: {
      validation_pending() {
        return (this.isValid === undefined && this.camera === 'off');
      },
      validation_success() {
        return (this.isValid === true);
      },
      validation_failure() {
        return this.isValid === false
      },
    },
    methods: {
      open() {
        let $this = this;
        this.show_scanner = true;
        $this.turn_camera_on();
        this.$refs.qr_modal.open();
      },
      close() {
        this.show_scanner = false;
        this.turn_camera_off();
        this.$refs.qr_modal.close();
      },
      async on_init(promise) {
        this.loading = true;
        try {
          await promise
            .catch(console.error);
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false;
        }
      },
      reset_validation_state() {
        this.isValid = undefined
      },
      async on_decode(content) {
        let $this = this;
        this.result = content;
        this.turn_camera_off();
        console.log('QR Scanned', content);
        let valid = undefined;
        if (typeof this.validate !== 'undefined') {
          valid = this.validate(content);
        } else {
          valid = this.validate_url(content);
        }
        this.$set(this, 'isValid', valid);
        if (valid) {
          this.$nextTick(() => {
            $this.$emit('scanned', content);
          });
        }
      },
      validate_url(content) {
        return content.startsWith('http');
      },
      turn_camera_on() {
        this.reset_validation_state();
        this.camera = 'auto'
      },
      turn_camera_off() {
        this.camera = 'off'
      },
      timeout(ms) {
        return new Promise(resolve => {
          window.setTimeout(resolve, ms)
        });
      },
      paint_outline(detectedCodes, ctx) {
        for (const detectedCode of detectedCodes) {
          const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints
          ctx.strokeStyle = "#38c172";
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(firstPoint.x, firstPoint.y);
          for (const { x, y } of otherPoints) {
            ctx.lineTo(x, y);
          }
          ctx.lineTo(firstPoint.x, firstPoint.y);
          ctx.closePath();
          ctx.stroke();
        }
      },
    },
    mounted() {
      console.log('QrScanner mounted.');
    },
    beforeDestroy() {
      console.log('QrScanner Unmounted');
    },
    watch: {

    },
  }
</script>
<template>
  <div class="qr-scanner-container">
    <a class="qr-button" href="javascript://" @click.stop="open">
      <img :src="placeholder" :width="width" :height="height" :title="$t('qr.click_to_scan')" />
    </a>
    <bs-modal ref="qr_modal" :title="$t('qr.scan_qr_code')" :save_text="false" :close_text="'close'" @close="close">
      <div v-if="show_scanner">

        <qrcode-stream :camera="camera" @decode="on_decode" @init="on_init" :track="this.paint_outline" v-if="!destroyed">
          <div class="loading-indicator" v-if="loading">
            {{ $t('qr.loading_camera') }}
          </div>

          <div v-if="validation_success" class="validation-success">
            {{ $t('qr.validated') }}
          </div>

          <div v-if="validation_failure" class="validation-failure">
            <div>{{ $t('qr.invalid_code') }}</div>
            <div><a href="javascript://" @click="turn_camera_on()">{{ $t('qr.scan_again') }}</a></div>
          </div>

          <div v-if="validation_pending" class="validation-pending">
            {{ $t('qr.validating') }}
          </div>
        </qrcode-stream>
      </div>
    </bs-modal>
  </div>
</template>
