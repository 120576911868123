<style lang="scss">
</style>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { bus } from '../main';
  // import i18n from '../i18n.js'
  export default {
    name: 'location-switcher-list',
    props: {
      promotion: {
        default: '',
      },
      company: {
        default: '',
      },
    },
    data() {
      return {
        loading: false,
        form: {
          promotion_id: null,
          company_id: null,
        },
        filter_val: '',
        invalid: false,
      }
    },
    components: {

    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company_list',
        'get_promotion_list',
      ]),
      current_user() {
        return this.get_user;
      },
      company_list() {
        let list = this.get_company_list;
        return list;
      },
      promotion_list() {
        let list = this.get_promotion_list;
        return list;
      },
      is_admin() {
        return (this.current_user && this.current_user.role == 'admin');
      },
      filtered_company_list() {
        let list = this.company_list;
        let promotions = this.promotion_list;
        let filter_val = this.filter_val;
        if (filter_val) {
          filter_val = filter_val.toLowerCase();
          promotions.map( (p) => {
            p.filter_str = `${p.name} ${p.description} ${p.space_name} ${p.space_description} ${p.location_name} ${p.location_description}`;
            p.filter_str = p.filter_str.toLowerCase();
          });
          list.map( (c) => {
            c.filter_str = `${c.name} ${c.description} ${c.industry}`;
            c.filter_str = c.filter_str.toLowerCase();
          });
        }
        // Set Company Promotions
        for (let idx = 0; idx < list.length; idx++) {
          let comp = list[idx];
          comp.promotions = promotions.filter( (p) => ( p.company_id == comp.id && (!filter_val || p.filter_str.includes(filter_val)) ) );
        }
        // // Filter Companies
        if (filter_val) {
          list = list.filter( (c) => (c.filter_str.includes(filter_val) || c.promotions.length));
        }
        if (list.length > 5) list = list.slice(0,5);
        return list;
      },
      num_filtered_promotions() {
        let list = this.filtered_company_list;
        let cnt = 0;
        for (let idx = 0; idx < list.length; idx++) {
          let comp = list[idx];
          cnt = cnt + comp.promotions.length;
        }
        return cnt;
      },
    },
    methods: {
      ...mapActions([
        'do_change_location',
        'do_fetch_promotions',
        'do_fetch_companies',
        'do_change_location_promotion',
      ]),
      init_form() {
        // console.log('Init LocationSwitcherList', this.promotion);
        if (this.promotion) {
          let promotion = JSON.parse(JSON.stringify(this.promotion));
          this.form.promotion_id = promotion.id;
          this.form.company_id = promotion.company_id;
        }
      },
      select_promotion(promotion = null) {
        this.loading = true;
        this.invalid = false;
        let company = null;
        if (!promotion && !this.is_admin) {
          return;
        }
        if (promotion) {
          company = this.company_list.filter( (c) => c.id == promotion.company_id);
          if (company.length) {
            company = company[0];
          } else {
            company = null;
          }
        }
        let promotion_location = {promotion: promotion, company: company};
        this.do_change_location_promotion(promotion_location);
        this.$emit('saved', promotion_location);
      },
      auto_select() {
        if (this.promotion_list.length > 0 && this.company_list.length > 0 && !this.promotion) {
          // Automatically set previous Promotion
          let last_promotion_id = localStorage.getItem('promotion_id');
          let promotion = false;
          if (last_promotion_id) {
            let match = this.promotion_list.filter( (p) => p.id == last_promotion_id);
            if (match.length > 0) {
              promotion = match[0];
              this.select_promotion(promotion);
            }
          }
          // No Promotion Matched, Auto Open Switcher
          if (!promotion) {
            this.$nextTick(function () {
              bus.$emit('location_switcher_open');
            });
          }
        }
      },
    },
    mounted() {
      console.log('LocationSwitcherList Mounted');
      this.init_form();
      if (this.promotion_list.length == 0) {
        this.do_fetch_promotions();
      }
      if (this.company_list.length == 0) {
        this.do_fetch_companies();
      }
    },
    watch: {
      promotion_list(list) {
        if (list.length > 0 && !this.promotion) {
          this.auto_select();
        }
      },
      company_list(list) {
        if (list.length > 0 && !this.promotion) {
          this.auto_select();
        }
      },
    },
  }
</script>
<template>
  <div>

    <bs-input-text col-class="mb-0" :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="filter_val">
      <template v-slot:prepend>
        <i class="fa fa-search"></i>
      </template>
    </bs-input-text>
    <div class="form-text">
      {{ $t('showing') }} {{ num_filtered_promotions }} {{ $t('of') }} {{ promotion_list.length }} {{ $t('promotion.promotions') }}
    </div>

    <div v-for="comp in filtered_company_list" :key="comp.id" class="mt-3 mb-3">
      <h4>{{comp.name}}</h4>
      <div class="list-group">
        <a v-for="prom in comp.promotions" :key="prom.id" href="javascript://" @click="select_promotion(prom)"
          class="list-group-item list-group-item-action" :class="{ active: ( promotion && prom.id == promotion.id ) }"
          >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{prom.name}}</h5>
            <small>
              <span>{{ prom.start_date | formatDate }}</span>
              <span> - {{ prom.end_date | formatDate }}</span>
            </small>
          </div>
          <small>{{ prom.location_name }} {{ prom.space_name }} - {{ prom.space_description }}</small>
        </a>
      </div>
    </div>

    <div v-if="is_admin">
      <a href="javascript://" @click="select_promotion(null)">{{ $t('promotion.deselect') }}</a>
    </div>

  </div>
</template>