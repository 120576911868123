<style lang="scss">

</style>
<script>
  import InventoryEditForm from '../components/InventoryEditForm.vue'
  import i18n from '../i18n.js'
  export default {
    name: 'inventory-edit-modal',
    components: {
      InventoryEditForm,
    },
    props: {
      product: null,
      inventory_list: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        modal: null,
        component_key: 0,
        error_message: '',
      }
    },
    computed: {
      inventory_title() {
        return (this.product && this.product.id ? i18n.t('product.edit') + ' ' + this.product.name : i18n.t('product.add_new'));
      },
    },
    methods: {
      open() {
        let $this = this;
        this.$nextTick(() => {
          $this.$refs.bs_modal.open();
        });
      },
      close() {
        this.$refs.bs_modal.close();
      },
      save() {
        this.$refs.inventory_form.trigger_save();
      },
      saved(product) {
        this.$emit('saved', product);
      },
      failed(err) {
        this.$emit('failed', err);
      },
      disable_buttons(disable = true) {
        this.$refs.bs_modal.disable_buttons(disable);
      },
      set_form_loading(form_loading = true) {
        this.$refs.bs_modal.set_form_loading(form_loading);
      },
      form_is_loading(form_loading = true) {
        this.$refs.inventory_form.form_is_loading(form_loading);
      },
    },
    watch: {

    },
    mounted() {
      console.log('InventoryEditModal Mounted');
    },
  }
</script>
<template>
  <div>
    <bs-modal :title="inventory_title" ref="bs_modal" @save="save" @form_loading="form_is_loading">
      <inventory-edit-form ref="inventory_form" :product="product" @saved="saved" @failed="failed" :buttons="false" :inventory_list="inventory_list" v-if="product" @buttons_disabled="disable_buttons" @form_loading="set_form_loading" />
    </bs-modal>
  </div>
</template>
