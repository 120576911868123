
<style lang="scss">

</style>
<script>
  import { mapGetters } from 'vuex'
  import LocationSwitcherWarning from '../components/LocationSwitcherWarning.vue';
  export default {
    name: 'sales-section',
    components: {
      LocationSwitcherWarning,
    },
    computed: {
      ...mapGetters([
        // 'get_user',
        'get_promotion',
        'get_promotion_list',
      ]),
      // active_route() {
      //   return this.$route.name;
      // },
      // user() {
      //   return this.get_user;
      // },
      promotion() {
        return this.get_promotion;
      },
      promotion_list() {
        return this.get_promotion_list;
      },
    },
    mounted() {
      console.log('SalesSection mounted.');
    },
  }

</script>
<template>
  <div class="section-wrapper">

    <router-view v-if="promotion"></router-view>

    <router-view v-if="promotion" class="sales-invoice-view" name="invoice"></router-view>

    <location-switcher-warning v-if="!promotion && promotion_list.length"/>

  </div>
</template>