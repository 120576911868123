<script>
  import { Modal } from 'bootstrap'
  export default {
    name: 'bs-modal',
    props: {
      backdrop: {
        default: true,
      },
      keyboard: {
        default: true,
      },
      focus: {
        default: true,
      },
      title: {
        type: String,
        default: 'BS Title'
      },
      css_class: {
        type: String,
        default: '',
      },
      save_text: {
        default: 'save',
      },
      close_text: {
        default: 'close',
      },
      save_class: {
        default: 'btn-primary'
      },
      close_class: {
        default: 'btn-secondary'
      },
      is_static: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        modal: null,
        buttons_disabled: false,
        form_loading: false,
      }
    },
    mounted() {
      // pass bootstrap modal options from props
      var options = Object.assign({}, this.$props);
      if (options.is_static) {
        options['backdrop'] = this.is_static ? 'static' : true;
        options['keyboard'] = this.is_static ? false : true;
      }
      let $this = this;
      var ele = this.$refs.modal_elm;
      this.modal = new Modal(ele, options);
      ele.addEventListener('hidden.bs.modal', function() {
        $this.$emit('close');
      });
    },
    methods: {
      open() {
        // console.log('BS Modal Open');
        this.disable_buttons(false);
        this.set_form_loading(true);
        this.modal.show();
        this.$nextTick(() => {
          this.set_form_loading(false);
        });
      },
      close() {
        this.modal.hide();
      },
      secondary() {
        this.$emit('secondary');
        this.close();
      },
      save() {
        this.$emit('save');
      },
      disable_buttons(disable = true) {
        this.$set(this, 'buttons_disabled', disable);
      },
      set_form_loading(form_loading = true) {
        this.$set(this, 'form_loading', form_loading);
        this.$emit('form_loading', form_loading);
      },
    },
  }

</script>
<template>
  <div class="modal fade" ref="modal_elm" :id="componentId(`modal`)" tabindex="-1" :aria-labelledby="componentId(`modal-label`)"
      aria-hidden="true">
    <div class="modal-dialog" :class="css_class">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="componentId(`modal-label`)">{{title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" :aria-label="$t('close')" @click="close()" v-if="close_text && !is_static"></button>
        </div>
        <div class="modal-body">
          <slot/>
        </div>
        <div class="modal-footer" v-if="save_text || close_text">
          <button :disabled="buttons_disabled" type="button" class="btn" :class="close_class" data-bs-dismiss="modal" @click="secondary()" v-if="close_text">{{$t(close_text)}}</button>
          <button :disabled="buttons_disabled" type="button" class="btn" :class="save_class" @click="save()" v-if="save_text">{{$t(save_text)}}<i class="fa fa-spinner fa-spin ms-2" v-if="form_loading"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
