<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'auth-reset',
    title: 'usr.reset_password',
    data(){
      return {
        loading: false,
        error_message: null,
        password_changed: false,
        token_expired: false,
        form: {
          token: "",
          email: "",
          password: "",
          confirm_password: "",
        },
        buttons_disabled: false,
        form_loading: false,
      }
    },
    computed: {
      ...mapGetters([
        'is_logged_in',
        'get_auth_status',
        'get_language',
      ]),
      auth_status() {
        return this.get_auth_status;
      },
      is_invalid() {
        return (this.auth_status == 'error' ? true : false);
      },
      route_email() {
        return this.$route.params.email;
      },
      route_token() {
        return this.$route.params.token;
      },
    },
    methods: {
      ...mapActions([
        'do_reset_password',
        'do_auth_verify',
      ]),
      ...mapMutations([
        'clear_auth_error',
      ]),
      reset_password() {
        console.log('reset_password');
        let request = {
          email: this.form.email,
          password: this.form.password,
          remember: this.form.remember,
        }
        this.$axios.defaults.headers.common['x-access-token'] = this.route_token;
        this.do_reset_password(request)
          .then((resp) => {
            if (resp.success) {
              this.password_changed = true;
            }
          }).catch((err) => {
            this.error_message = err.message;
          }).finally(() => {
            this.$axios.defaults.headers.common['x-access-token'] = null;
            if (this.$refs.reset_password_form) {
              this.$refs.reset_password_form.disable_buttons(false);
              this.$refs.reset_password_form.set_form_loading(false);
            }
          });
      },
      disable_buttons(disable = true) {
        this.$set(this, 'buttons_disabled', disable);
      },
      set_form_loading(form_loading = true) {
        this.$set(this, 'form_loading', form_loading);
      },
    },
    mounted() {
      console.log('AuthReset mounted.');

      // Verify Reset Token
      this.form.email = this.route_email;
      this.$axios.defaults.headers.common['x-access-token'] = this.route_token;
      this.do_auth_verify({check: true})
        .catch((res) => {
          if (!res.success && res.message == 'expired_token') {
            this.token_expired = true;
          }
        }).finally(() => {
          this.$axios.defaults.headers.common['x-access-token'] = null;
        });
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 auth-container mt-sm-5 mb-sm-5">
    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-8 col-xl-6 col-xxl-5">

        <div class="card">
          <div class="card-header">
            <h1>{{ $t('usr.reset_password') }}</h1>
          </div>
          <div class="card-body">

            <div v-if="!token_expired && !password_changed">
              <p>
                {{ $t('usr.reset_request_instructions') }} <strong>{{ this.route_email }}</strong>
              </p>
              <bs-form ref="reset_password_form" formClass="reset_password" :submit_button="false" :reset_button="false" @submit="reset_password" @buttons_disabled="disable_buttons" @form_loading="set_form_loading">
                <input type="hidden" name="user_username" v-model="form.email" autocomplete="username" />
                <div class="row row-cols">
                  <bs-input-text id="user_password" type="password" :label="$t('usr.password')" :placeholder="$t('usr.password')" v-model="form.password" rules="required|min:8" autocomplete="new-password">
                    <template v-slot:prepend>
                      <i class="fa fa-key"></i>
                    </template>
                  </bs-input-text>
                </div>
                <div class="row row-cols">
                  <bs-input-text id="user_confirm_password" type="password" :label="$t('usr.confirm_password')" :placeholder="$t('usr.confirm_password')" v-model="form.confirm_password" rules="required|min:8|password_match:user_password" autocomplete="new-password">
                    <template v-slot:prepend>
                      <i class="fa fa-key"></i>
                    </template>
                  </bs-input-text>
                </div>
                <div class="row row-cols align-items-center">
                  <div class="col-12 mb-3" v-show="is_invalid">
                    <div class="invalid-feedback d-block">
                      {{ $t( error_message ) }}
                    </div>
                  </div>
                </div>

                <template v-slot:buttons>
                  <button class="btn btn-primary" :disabled="buttons_disabled" type="submit">{{ $t('usr.change_password') }}<i class="fa fa-spinner fa-spin ms-2" v-if="form_loading"></i></button>
                  <router-link class="ms-4" :to="{ name: 'auth.login' }">{{ $t('usr.login') }}</router-link>
                </template>
              </bs-form>
            </div>

            <div class="row row-cols align-items-center" v-if="password_changed">
              <div class="col-12 mb-3">
                <div class="valid-feedback d-block">
                  {{ $t('usr.reset_password_success') }}
                  <p><router-link :to="{ name: 'auth.login' }">{{ $t('usr.login') }}</router-link></p>
                </div>
              </div>
            </div>

            <div class="row row-cols align-items-center" v-if="token_expired">
              <div class="col-12 mb-3">
                <div class="invalid-feedback d-block">
                  {{ $t( 'usr.reset_request_expired' ) }}
                </div>
              </div>
              <p><router-link :to="{ name: 'auth.login', params: {action: 'reset'} }">{{ $t('usr.request_password_reset') }}</router-link></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>