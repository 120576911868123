<style lang="scss">
  .exhibitor-sales-progress-container {
    &.summary-report {
      .cej-chart-container div {
        height: 250px;
      }
    }
    &.detail-report {
      .cej-chart-container div {
        height: 400px;
      }
      #sales-progress-table {
        .el-table {
          .cell {
            word-break: break-word;
          }
          th.is-sortable {
            .cell {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import CejChart from '../cejGeneric/CejChart.vue'
  import i18n from '../i18n';
  export default {
    name: 'exhibitor-sales-progress',
    components: {
      CejChart,
      'tbl-cell-product': {
        props: ['row'],
        template: `
          <div>
            <product-image :product="row" />
            <span>{{ row.name }}</span>
          </div>
        `,
      },
    },
    data() {
      return {
        loading: false,
        table_filter_val: '',
      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
        'get_exhibitor_sales_progress',
      ]),
      route_company_id() {
        return this.$route.params.company_id;
      },
      route_promotion_id() {
        return this.$route.params.promotion_id;
      },
      active_route() {
        return this.$router.currentRoute;
      },
      is_summary() {
        return (this.active_route.name == 'exhibitor.dashboard' ? true : false);
      },
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
      stats() {
        return this.get_exhibitor_sales_progress;
      },
      bar_chart_options() {
        return {
          interaction: {
            intersect: false,
            mode: 'index',
          },
          plugins: {
            title: {
              display: true,
              text: i18n.t('exhibitor.top_selling_products'),
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    // label += new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(context.parsed.y);
                    label += new Intl.NumberFormat('ja-JP').format(context.parsed.y);
                  }
                  return label;
                },
                footer: function(tooltip_items) {
                  let item = tooltip_items[0];
                  let idx = item.dataIndex;
                  let remaining = item.dataset.remaining[idx];
                  return i18n.t('sales.available_stock') + ': ' + new Intl.NumberFormat('ja-JP').format(remaining);
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: i18n.t('exhibitor.product'),
              },
            },
            y: {
              stacked: true,
              title: {
                display: true,
                text: i18n.t('sales.quantity'),
              },
              ticks: {
                precision: 0,
              },
            }
          },
        };
      },
      bar_chart_data() {
        let purchased = [];
        let reserved = [];
        let remaining = [];
        let product_labels = [];
        let stats = this.stats;
        let limit = (this.is_summary ? 6 : 20);
        for (let i = 0; i < stats.length && i < limit; i++) {
          let stat = stats[i];
          purchased.push(stat.quantity_purchased);
          reserved.push(stat.quantity_reserved);
          remaining.push(stat.stock_remaining);
          product_labels.push(stat.name);
        }

        return {
          labels: product_labels,
          datasets: [
            {
              label: i18n.t('sales.purchased'),
              data: purchased,
              backgroundColor: '#439d3d',
              remaining: remaining,
            },
            {
              label: i18n.t('sales.reserved'),
              data: reserved,
              backgroundColor: '#6080ad',
              remaining: remaining,
            },
            // {
            //   label: i18n.t('sales.remaining_stock'),
            //   data: remaining,
            //   backgroundColor: '#95a2ba',
            // }
          ],
        };
      },
      chart_col_classes() {
        return this.is_summary ? 'col-12 col-sm-9 col-xl-10' : 'col-12';
      },
      button_col_classes() {
        return this.is_summary ? 'col-12 col-sm-3 col-xl-2' : 'col-12';
      },
      table_config() {
        return {
          options: {
            stripe: true,
            defaultSort: {
              prop: 'name',
              order: 'ascending'
            },
          },
          fields: [
            { label: i18n.t('product.name'), prop: 'name', sortable: true, searchable: true, min_width: '200px', component: 'tbl-cell-product' },
            { label: i18n.t('product.description'), prop: 'description', sortable: true, searchable: true, min_width: '200px' },
            { label: i18n.t('product.price'), prop: 'unit_price', sortable: true, searchable: true, formatter: this.$options.filters.formatCurrency },
            { label: i18n.t('sales.purchased'), prop: 'quantity_purchased', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
            { label: i18n.t('exhibitor.revenue'), prop: 'total_purchased', sortable: true, searchable: false, formatter: this.$options.filters.formatCurrency },
            { label: i18n.t('sales.reserved'), prop: 'quantity_reserved', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
            { label: i18n.t('exhibitor.reserved_revenue'), prop: 'total_reserved', sortable: true, searchable: false, formatter: this.$options.filters.formatCurrency }, 
            { label: i18n.t('sales.total_stock'), prop: 'stock_quantity', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
            { label: i18n.t('sales.remaining_stock'), prop: 'stock_remaining', sortable: true, searchable: false, formatter: this.$options.filters.formatNumber },
          ],
          filters: [
            {
              prop: ['name', 'description', 'unit_price'],
              value: this.table_filter_val,
            }
          ],
        }
      },
      csv_fields() {
        let fields = ['product_id'];
        for (let i = 0; i < this.table_config.fields.length; i++) {
          let field = this.table_config.fields[i];
          fields.push(field.prop);
        }
        return fields;
      },
    },
    methods: {
      ...mapActions([
        'do_fetch_exhibitor_sales_progress',
      ]),
      fetch_stats() {
        let $this = this;
        this.loading = true;
        this.do_fetch_exhibitor_sales_progress()
          .then( () => {
            $this.$set($this, 'loading', false);
          }).catch(err => {
            console.error('fetch_stats', err);
            $this.$set($this, 'loading', false);
          });
      },
    },
    mounted() {
      console.log('ExhibitorSalesProgress mounted.');
      this.fetch_stats();
    },
    beforeDestroy() {
      console.log('ExhibitorSalesProgress Unmounted');
    },
    watch: {
      promotion() {
        this.fetch_stats();
      },
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 exhibitor-sales-progress-container" :class="{loading: loading, 'summary-report': is_summary, 'detail-report': !is_summary }">

    <h3>{{ $t('exhibitor.sales_progress') }}</h3>

    <div class="row mt-3 mb-3 text-center" v-if="!stats.length">
      <p>{{ $t('exhibitor.no_sales_data') }}</p>
    </div>

    <div class="row mt-3 mb-3" v-if="stats.length">

      <div class="exhibitor-chart-col" :class="chart_col_classes">
        <cej-chart type="bar" :data="bar_chart_data" :options="bar_chart_options" />
      </div>

      <div :class="button_col_classes">
        <div class="row align-items-center">
          <div class="col-6 col-sm-12 text-center" v-if="is_summary">
            <router-link type="button" class="btn btn-outline-primary m-1" :to="{ name: 'exhibitor.sales_progress', params: {company_id: route_company_id, promotion_id: route_promotion_id} }">{{ $t('exhibitor.details') }} <i class="fa fa-th-list"></i></router-link>
          </div>
          <div class="col-6 col-sm-12 text-center">
            <div class="mx-auto">
              <download-csv
                class="btn btn-outline-primary m-1"
                :fields="csv_fields"
                :data="stats"
                name="sales_progress.csv">
                {{ $t('csv_export') }} <i class="fa fa-file-csv"></i>
              </download-csv>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-3 mb-3" v-if="!is_summary && stats">
      <bs-input-text :placeholder="$t('search')" :aria-label="$t('search')" aria-describedby="tbl-search-filter" v-model="table_filter_val">
        <template v-slot:prepend>
          <i class="fa fa-search"></i>
        </template>
      </bs-input-text>

      <data-tables
        ref="sales-progress-table"
        id="sales-progress-table"
        :table-props="table_config.options"
        :fields="table_config.fields"
        :data="stats"
        :page-size="100"
        :pagination-props='{ background: false, pageSizes: [10, 25, 50, 100] }'
        :filters="table_config.filters"
      >
        <el-table-column v-for="field in table_config.fields"
          :label="field.label"
          :prop="field.prop"
          :key="field.prop"
          :sortable="field.sortable ? 'custom' : false"
          :sort-by="field.sort_by ? field.sort_by : field.prop"
          :align="field.align"
          :min-width="field.min_width ? field.min_width : null"
          :formatter="field.formatter"
        >
          <template slot-scope="scope">
            <component v-bind:is="field.component" v-if="field.component" :row="scope.row"/>
            <span v-else-if="field.formatter">{{ field.formatter(scope.row[field.prop]) }}</span>
            <span v-else>{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </data-tables>
    </div>

  </div>
</template>
