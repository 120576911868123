<style lang="scss">

</style>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import ExhibitorSalesAnalysis from '../components/ExhibitorSalesAnalysis.vue';
  export default {
    name: 'exhibitor-sales-analysis-results',
    title: 'exhibitor.sales_analysis',
    components: {
      ExhibitorSalesAnalysis
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'get_user',
        'get_company',
        'get_promotion',
      ]),
      user() {
        return this.get_user;
      },
      company() {
        return this.get_company;
      },
      promotion() {
        return this.get_promotion;
      },
    },
    methods: {
      ...mapActions([

      ]),
      ...mapMutations([

      ]),
    },
    mounted() {
      console.log('ExhibitorSalesAnalysisResults mounted.');
      
    },
    beforeDestroy() {
      console.log('ExhibitorSalesAnalysisResults Unmounted');
    },
    watch: {

    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-1">
    <div class="row justify-content-center">

      <div class="container-fluid exhibitor-analysis-container" v-if="promotion">

        <div>
          <exhibitor-sales-analysis/>
        </div>


      </div>


    </div>
  </div>
</template>
