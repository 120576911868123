<style lang="scss">
  .product-image {
    &.max-100x100 {
      max-width: 100px;
      max-height: 100px;
    }
  }
</style>
<script>
  export default {
    name: 'product-image',
    components: {
    },
    props: {
      product: null,
      size: {
        type: String,
        default: 'max-100x100',
      },
    },
    computed: {
    },
    mounted() {
    },
  }
</script>
<template>
  <img :src="product.image_url" class="product-image" :class="size" />
</template>
