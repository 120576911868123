<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'auth-logout',
    title: 'usr.logout',
    data(){
      return {
        loading: false,
      }
    },
    computed: {
      ...mapGetters([
        'is_logged_in',
        'get_auth_status',
        'get_language',
      ]),
      auth_status() {
        return this.get_auth_status;
      },
    },
    methods: {
      ...mapActions([
        'do_auth_verify',
        'do_logout',
      ]),
      ...mapMutations([
        'clear_auth_error',
      ]),
      logout() {
        let $this = this;
        this.do_logout();
        setTimeout(() => {
          $this.$router.push({ name: 'auth.login'});
        }, 2000);

      },
    },
    mounted() {
      console.log('AuthLogout mounted.');
      this.logout();
    },
  }
</script>
<template>
  <div class="container-fluid p-0 py-2 auth-container mt-sm-5 mb-sm-5">
    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-8 col-xl-6 col-xxl-5">

        <div class="card">
          <div class="card-header">
            <h1>{{ $t('usr.logging_out') }}</h1>
          </div>
          <div class="card-body">

            <div>
              <p>
                {{ $t('usr.logging_out_of_session') }}
              </p>
            </div>

            <div class="row row-cols align-items-center">
              <div class="col-12 mb-3">
                <div class="d-block">
                  <p><router-link class="btn btn-primary" :to="{ name: 'auth.login' }">{{ $t('usr.login') }}</router-link></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>